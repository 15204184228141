import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { translate } from '../../common/translations/translate';
import * as React from 'react';
import { FormattedDate } from '../../common';
import { PanelTitle } from '../../common/components/panel/panel-title.component';
import { INotification } from '../types/notification';
import { NotificationState } from '../notification.constants';

interface IProps {
  notification: INotification;
}

export const WorkOrderItems: React.FunctionComponent<IProps> = ({ notification }) =>
  notification.workOrderItems?.length ? (
    <>
      <PanelTitle>{translate('Notifications.Workorder.Title')}</PanelTitle>
      {notification.state === NotificationState.done && (
        <dl style={{ marginBottom: '0.5em' }}>
          <dt>{translate('Notifications.Workorder.TourNumberOfSigns')}</dt>
          <dd>{notification.tourNumberOfSigns}</dd>
          <dt>{translate('Notifications.Workorder.TourComment')}</dt>
          <dd>{notification.tourComment}</dd>
        </dl>
      )}
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            {[
              { label: translate('Notifications.Workorder.Workorder'), key: '1' },
              { label: translate('Notifications.Workorder.Team'), key: '2' },
              { label: translate('Notifications.Workorder.Planned'), key: '3' },
              { label: translate('Notifications.Workorder.Executed'), key: '3' },
              { label: translate('Notifications.Workorder.Description'), key: '3' },
            ].map((column) => (
              <TableCell key={column.key}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {notification.workOrderItems!.map((x) => {
            return (
              <TableRow key={x.id}>
                <TableCell>{translate('Notifications.Workorder.Pickup')}</TableCell>
                <TableCell>{x.workOrder && x.workOrder.team.name}</TableCell>
                <TableCell>{x.workOrder && <FormattedDate date={x.workOrder.date} />}</TableCell>
                <TableCell>
                  {x.completedAt ? (
                    <FormattedDate date={x.completedAt} />
                  ) : (
                    translate('Notifications.Workorder.Incomplete')
                  )}
                </TableCell>
                <TableCell>
                  <FormattedDate date={x.description} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  ) : null;
