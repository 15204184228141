import { createReducer } from '@reduxjs/toolkit';
import { IFileMetadata } from '../../../types';
import { IById } from '../../../common/utils/normalized.util';
import { StorageActions } from '../../actions';

type TEntity = IFileMetadata;
type TState = IById<TEntity>;

export default createReducer<TState>({}, (builder) => {
  builder.addCase(StorageActions.upload.type, (state, { payload }: ReturnType<typeof StorageActions.upload>) => {
    if (payload.metadata) {
      state[payload.metadata.id] = payload.metadata;
    }
  });
  builder.addCase(
    StorageActions.uploadError.type,
    (state, { payload }: ReturnType<typeof StorageActions.uploadError>) => {
      if (payload) {
        state[payload.id] = payload;
      }
    },
  );
  builder.addCase(
    StorageActions.uploadSuccess.type,
    (state, { payload }: ReturnType<typeof StorageActions.uploadSuccess>) => {
      if (payload) {
        state[payload.id] = payload;
      }
    },
  );
});
