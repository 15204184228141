import { Grid } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { translate } from '../../../../common/translations/translate';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { HtmlTooltip } from '../../../../common/components/html-tooltip/html-tooltip.component';
import { CollapsablePanel } from '../../../../components';
import { getRequestsDetails } from '../../../../store/selectors';
import { IPublicDomainIntakeExtended } from '../../../../types';
import { LocationLicensePlatesRequesterComponent } from './location-license-plates-requester.component';
import { LocationLicensePlatesWerkhavenComponent } from './location-license-plates-werkhaven.component';
import { RequesterTooltipComponent } from './requester-tooltip.component';

interface IProps {
  location?: IPublicDomainIntakeExtended;
  visible?: boolean;
}

/** For requests placed by Werkhaven */
export const LocationLicensePlatesContainer: FunctionComponent<IProps> = ({ location, visible = true }) => {
  const request = useSelector(getRequestsDetails(`${location?.permitRequestId}`));

  if (visible && location && request && request?.needsPlacement) {
    // For requests placed by Werkhaven
    return (
      <CollapsablePanel title={translate('Requests.Detail.LicensePlates.Title')}>
        <LocationLicensePlatesWerkhavenComponent location={location} />
      </CollapsablePanel>
    );
  }

  // For requests placed by the requester itself
  return visible && location && request && !request.needsPlacement ? (
    <CollapsablePanel
      title={
        <Grid container alignItems={'center'}>
          <Grid item>
            {translate('Requests.Detail.LicensePlates.Title')}
            <HtmlTooltip title={<RequesterTooltipComponent />}>
              <InfoOutlined fontSize="small" style={{ marginBottom: -4, marginLeft: 7 }} />
            </HtmlTooltip>
          </Grid>
        </Grid>
      }
    >
      <LocationLicensePlatesRequesterComponent location={location} />
    </CollapsablePanel>
  ) : null;
};
