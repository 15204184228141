import { I18n, TranslateOptions } from 'i18n-js';
import nl from '../translations/nl.json';
import { JsonObject } from '../../types';

export const i18n = new I18n({
  nl,
});

i18n.locale = 'nl';

export const translate = (jsonKey: JsonObject<typeof nl>, options?: TranslateOptions) =>
  i18n.translate(jsonKey, options);
export const translateIgnoreTS = (jsonKey: string, options?: any) => i18n.translate(jsonKey, options);
