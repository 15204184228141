import React, { FunctionComponent, useState } from 'react';
import { CancelOutlined } from '@material-ui/icons';
import { translate } from '../../../../common/translations/translate';
import { useSelector } from 'react-redux';
import ModalDialog from '../../../../common/components/modal-dialog/modal-dialog.component';
import { IRequestAclActions, IRequestCancelButtonComponent, RequestStates } from '../../../../types';
import { getRequestAssignedUser, selectRequestDetailById } from '../../../../store/selectors';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { Auth } from '../../../../components';

export const CancelButton: FunctionComponent<IRequestCancelButtonComponent> = ({ onCancelRequest, requestId }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { assignedToMe } = useSelector(getRequestAssignedUser(requestId));
  const { state } = useSelector(selectRequestDetailById)[requestId];

  const hideModal = () => setModalVisible(false);
  const showModal = () => setModalVisible(true);
  const onConfirm = () => {
    hideModal();
    onCancelRequest();
  };

  const isButtonVisible =
    assignedToMe && !!state && !!state.transitions && state.transitions.includes(RequestStates.canceled);

  return (
    <Auth acl={IRequestAclActions.cancelAssignedRequest}>
      <Visible visible={isButtonVisible}>
        <div>
          <Button.White
            data-testid="cancellationButton"
            iconButton
            onClick={showModal}
            style={{ marginLeft: '10px' }}
            tooltip={translate('Requests.Header.cancellation.confirmTitle')}
          >
            <CancelOutlined style={{ fontSize: '17px' }} color="primary" />
          </Button.White>
        </div>
        <ModalDialog
          okButtonText={translate('ok')}
          onClose={hideModal}
          onConfirm={onConfirm}
          title={translate('Requests.Header.cancellation.confirmTitle')}
          visible={modalVisible}
        >
          <span>{translate('Requests.Header.cancellation.confirmDescription')}</span>
        </ModalDialog>
      </Visible>
    </Auth>
  );
};
