import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline as AddIcon } from '@material-ui/icons';
import { translate } from '../../../../common/translations/translate';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { FunctionComponent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { Autocomplete } from '../../../../common/components/form-fields/autocomplete.component';
import { AsignDatepicker } from '../../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { Unavailable } from '../../../../common/components/formatters/unavailable';
import { getIsAuthorized } from '../../../../common/store/user/user.selectors';
import { validateLicensePlate } from '../../../../common/utils/licensePlate.util';
import { getAllRequestCountries, getLicensePlatesByDateForLocation } from '../../../../store/selectors';
import { CarFreeZoneType, DateFormat, IPermittedPlate, IRequestAclActions } from '../../../../types';
import { LicensePlatesList } from './license-plates-list.component';

interface IProps {
  addingLicensePlatesAllowed?: boolean;
  carFreeZoneType?: CarFreeZoneType;
  isAnprRegularisation: boolean;
  permittedPlates: IPermittedPlate[];
  requestId: string;
  locationId: number;
  onPermittedPlatesChange: (pp: IPermittedPlate[]) => void;
  dateFrom: Moment;
  dateUntil: Moment;
}

const useStyles = makeStyles({
  button: {
    textAlign: 'right',
    width: '100%',
  },
  datepicker: {
    marginBottom: '5px',
    width: '340px;',
  },
  licensePlateAndCountry: {
    marginBottom: '15px',
  },
  licensePlate: {
    width: '340px;',
  },
  parkedPlates: {
    marginRight: '15px',
    marginBottom: '15px',
  },
  validFrom: {
    fontWeight: 600,
    marginBottom: '15px',
  },
  fullWidth: {
    width: '100%',
  },
  marginBottom: {
    marginBottom: '15px',
  },
});

/** For requests placed by Werkhaven */
export const CarFreeZoneLicensePlatesEditor: FunctionComponent<IProps> = ({
  addingLicensePlatesAllowed = true,
  carFreeZoneType,
  dateFrom,
  dateUntil,
  isAnprRegularisation,
  locationId,
  onPermittedPlatesChange,
  permittedPlates,
  requestId,
}) => {
  const C = useStyles();
  const countries = useSelector(getAllRequestCountries);
  const platesByDates = useSelector(getLicensePlatesByDateForLocation(requestId, locationId));

  const showOnDelete = useSelector(getIsAuthorized([IRequestAclActions.addLicensePlatesToRequest]));

  const [newPermittedPlateText, setNewPermittedPlateText] = useState<string>('');
  const [startDate, setStartDate] = useState<Moment>();
  const [countryCode, setCountryCode] = useState<string>('BE');

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPermittedPlateText(event.target.value);
  };

  const toLabel = useCallback((plate: IPermittedPlate) => `${plate.licensePlate} (${plate.countryCode})`, []);

  const sendMessageHandler = useCallback(() => {
    const newPermittedPlate: IPermittedPlate = {
      countryCode,
      licensePlate: validateLicensePlate(newPermittedPlateText),
      startDate: startDate
        ? startDate.format('YYYY-MM-DD')
        : moment.max(dateFrom, moment().subtract(9, 'days')).format('YYYY-MM-DD'),
    };
    const newPermittedPlatesList = [...permittedPlates, newPermittedPlate];
    onPermittedPlatesChange(newPermittedPlatesList);

    setNewPermittedPlateText('');
    setCountryCode('BE');
    setStartDate(undefined);
  }, [
    permittedPlates,
    countryCode,
    newPermittedPlateText,
    startDate,
    dateFrom,
    onPermittedPlatesChange,
    setNewPermittedPlateText,
    setCountryCode,
    setStartDate,
  ]);

  const deleteLicensePlateHandler = useCallback(
    (parkedPlateToDel: string, date?: string) => () => {
      const newParkedPlatesList = permittedPlates.filter((pp) => {
        if (date && date !== pp.startDate) {
          return true;
        }
        return toLabel(pp) !== parkedPlateToDel;
      });
      onPermittedPlatesChange(newParkedPlatesList);
    },
    [onPermittedPlatesChange, toLabel, permittedPlates],
  );

  const onSetCountryCode = useCallback((value?: string) => {
    value && setCountryCode(value);
  }, []);

  const canSend = useCallback(
    () =>
      !!newPermittedPlateText &&
      !!countryCode &&
      !permittedPlates.map((plate) => toLabel(plate)).includes(newPermittedPlateText) &&
      (!isAnprRegularisation || !!startDate),
    [isAnprRegularisation, newPermittedPlateText, permittedPlates, startDate, toLabel, countryCode],
  );

  return (
    <div data-testid="CarFreeZoneLicensePlatesEditor">
      {!permittedPlates.length && (
        <Unavailable text={translate('Requests.Detail.CarFreeZoneLicensePlates.NoneAvailable')} />
      )}
      {carFreeZoneType === CarFreeZoneType.anpr ? (
        platesByDates.map((platesByDate) => (
          <>
            <p className={C.validFrom}>
              {`${translate('Requests.Detail.CarFreeZoneLicensePlates.ValidFrom')} ${moment(
                platesByDate.startDate,
              ).format(DateFormat.date)}`}
            </p>
            <LicensePlatesList
              deleteLicensePlateHandler={deleteLicensePlateHandler}
              showOnDelete={showOnDelete}
              licensePlatesWithDates={platesByDate.plates.map((plate) => ({
                label: toLabel(plate),
                date: plate.startDate,
              }))}
            />
          </>
        ))
      ) : (
        <LicensePlatesList
          deleteLicensePlateHandler={deleteLicensePlateHandler}
          licensePlates={permittedPlates.map((plate) => toLabel(plate))}
          showOnDelete={showOnDelete}
        />
      )}
      {isAnprRegularisation && addingLicensePlatesAllowed && (
        <>
          <div data-testid={'CarFreeZoneDatePicker'}>
            <AsignDatepicker
              label={translate('Requests.Detail.CarFreeZoneLicensePlates.StartDate')}
              fullWidth
              onChange={setStartDate}
              className={C.datepicker}
              value={startDate}
              minDate={dateFrom}
              maxDate={dateUntil}
            />
          </div>
          <div className={C.marginBottom}>
            <Unavailable text={'Datum vanaf wanneer de nummerplaat toegang dient te hebben.'} />
          </div>
        </>
      )}
      {addingLicensePlatesAllowed && (
        <>
          <Grid container item xs={12} spacing={2} justify="space-between" className={C.licensePlateAndCountry}>
            <Grid item xs={8}>
              <TextField
                className={C.licensePlate}
                value={newPermittedPlateText}
                label={translate('Requests.Detail.CarFreeZoneLicensePlates.Label')}
                placeholder={translate('Requests.Detail.CarFreeZoneLicensePlates.Placeholder')}
                fullWidth
                margin="none"
                onChange={handleMessageChange}
              />
            </Grid>
            <Grid item xs>
              <Autocomplete<string>
                value={countryCode}
                title={translate(`Requests.Detail.CarFreeZoneLicensePlates.CountryOfRegistration`)}
                placeholder={translate(`Requests.Detail.CarFreeZoneLicensePlates.CountryOfRegistration`)}
                menuItems={countries}
                onChange={onSetCountryCode}
              />
            </Grid>
          </Grid>
          <Grid item sm={12} lg className={C.button}>
            <Button.Green onClick={sendMessageHandler} disabled={!canSend()} startIcon={<AddIcon fontSize={'small'} />}>
              {translate(`Requests.Detail.LicensePlates.AddLicensePlate`)}
            </Button.Green>
          </Grid>
        </>
      )}
    </div>
  );
};
