import { Grid } from '@material-ui/core';
import { translate } from '../../../common/translations/translate';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Header } from '../../../common/components/layout/Header.component';
import { LoaderBlur } from '../../../common/components/layout/LoaderBlur.component';
import { appUrls } from '../../../common/config/url.constants';
import { RequestActions, RequestLocationsActions } from '../../../store/actions';
import {
  getRequestCostOverview,
  getRequestDetailsForId,
  getRequestLocationsForm,
  selectRequestConflictsById,
  selectRequestSaving,
} from '../../../store/selectors';
import { IGeometry, IntakeTypes, IRequestLocationForm, RequestStates } from '../../../types';
import { MapPane } from './MapPane.component';
import { SidePane } from './SidePane.container';
import { CarFreeZonesActions } from '../../../common/store/car-free-zones/carFreeZones.actions';
import { UndoSnackbar } from './UndoSnackbar.component';
import { getAllCarFreeZonesWithGisId } from '../../../common/store/car-free-zones/carFreeZones.selectors';

export const RequestEditLocationsPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const request = useSelector(getRequestDetailsForId(id));
  const canEditLocations = !!request?.acl?.can_edit_locations;

  const locations = useSelector(getRequestLocationsForm(id));
  const conflicts = useSelector(selectRequestConflictsById)[id];
  const costOverview = useSelector(getRequestCostOverview(id));
  const [newLocations, setNewLocations] = useState<IRequestLocationForm[]>([]);
  const [updatedLocations, setUpdatedLocations] = useState<IRequestLocationForm[]>([]);
  const [removedLocations, setRemovedLocations] = useState<number[]>([]);
  const [expanded, setExpanded] = useState<boolean | number>();
  const saving = useSelector(selectRequestSaving);
  const [lastRemovedLocation, setLastRemovedLocation] = useState<IRequestLocationForm>();
  const [locationValidation, setLocationValidation] = useState<Map<string, boolean>>(new Map<string, boolean>());
  const [validated, setValidated] = useState<boolean>(true);
  const carFreeZones = useSelector(getAllCarFreeZonesWithGisId);

  useEffect(() => {
    if (id) {
      dispatch(RequestActions.fetch(parseInt(id, 10)));
      dispatch(RequestActions.fetchConflicts(id));
      dispatch(CarFreeZonesActions.fetchAll());
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!!request && !locations.length && !newLocations.length && !saving) {
      _onAddLocation();
    } else if (!!locations.length && !expanded) {
      setExpanded(locations[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations.length]);

  useEffect(() => {
    if (!saving) {
      setNewLocations([]);
      setUpdatedLocations([]);
      setRemovedLocations([]);
    }
  }, [saving, id]);

  const getLocations = useCallback(
    () =>
      (locations || [])
        .concat(newLocations)
        // Filter all removed locations
        .filter(({ id }) => !removedLocations.includes(id))
        // Map all updated locations
        .map((loc) => updatedLocations.find(({ id }) => id === loc.id) || loc),
    [locations, newLocations, removedLocations, updatedLocations],
  );

  const getSelectedLocation = useCallback(
    () => getLocations().find(({ id }) => id === expanded),
    [expanded, getLocations],
  );

  const _onAddLocation = useCallback(() => {
    const id = Math.random();
    setNewLocations(
      newLocations.concat({ id, isNew: true, type: IntakeTypes.parkingbanintake } as IRequestLocationForm),
    );
    setExpanded(id);
  }, [newLocations]);

  const _onExpandLocation = (id: number) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? id : false);
  };

  const _onRemoveLocation = useCallback(
    (removeId: number) => {
      const removedLocation =
        newLocations.find(({ id }) => id === removeId) ||
        updatedLocations.find(({ id }) => id === removeId) ||
        locations.find(({ id }) => id === removeId);
      if (removedLocation) setLastRemovedLocation(removedLocation);
      setRemovedLocations(removedLocations.concat(removeId));
      setNewLocations(newLocations.filter(({ id }) => id !== removeId));
      setUpdatedLocations(updatedLocations.filter(({ id }) => id !== removeId));
    },
    [locations, newLocations, removedLocations, updatedLocations],
  );

  const _onUndoRemoveLocation = useCallback(() => {
    if (lastRemovedLocation) {
      setRemovedLocations(removedLocations.filter((id) => id !== lastRemovedLocation.id));
      if (lastRemovedLocation.isNew) {
        setNewLocations(newLocations.concat(lastRemovedLocation));
      } else {
        setUpdatedLocations(updatedLocations.concat(lastRemovedLocation));
      }
      setExpanded(lastRemovedLocation.id);
      setLastRemovedLocation(undefined);
    }
  }, [lastRemovedLocation, newLocations, removedLocations, updatedLocations]);

  const _onSaveLocation = (location: Partial<IRequestLocationForm>) => {
    const originalLocation = getSelectedLocation();
    if (originalLocation) {
      const locationToSave: IRequestLocationForm = { ...originalLocation, ...location };
      setUpdatedLocations(updatedLocations.filter((loc) => loc.id !== location.id).concat(locationToSave));
    }
  };

  const allLocationsValidated = useCallback((): boolean => {
    return !Array.from(locationValidation.values()).includes(false);
  }, [locationValidation]);

  const _onValidationResult = useCallback(
    (location: Partial<IRequestLocationForm>, flag: boolean) => {
      locationValidation.set('' + location.id, flag);
      setLocationValidation(locationValidation);
      setValidated(allLocationsValidated());
    },
    [allLocationsValidated, locationValidation, setValidated],
  );

  const _onLocationTypeChanged = useCallback(
    (location: Partial<IRequestLocationForm>) => {
      const selectedLocation = getSelectedLocation();
      if (selectedLocation) {
        selectedLocation.gisId = location.gisId;
        selectedLocation.geometry = location.geometry;
        selectedLocation.type = location.type!;
        selectedLocation.geometryType = location.geometryType;
      }
    },
    [getSelectedLocation],
  );

  const _onSetGeometry = (geometry: IGeometry) => {
    getSelectedLocation() &&
      dispatch(RequestLocationsActions.reverseGeocode({ geometry, locationId: getSelectedLocation()!.id }));
  };

  const hideExpandedLocation = () => {
    setExpanded(undefined);
  };

  const _onSaveLocations = useCallback(() => {
    dispatch(RequestActions.patchPublicDomainIntakes({ id, publicDomainIntakes: getLocations() }));
    if (getSelectedLocation()?.isNew) {
      hideExpandedLocation();
    }
  }, [dispatch, getLocations, getSelectedLocation, id]);

  const _onSubmit = useCallback(() => {
    dispatch(RequestActions.submit(id));
  }, [dispatch, id]);

  const hasUpdatedLocations = useCallback(
    () => !!newLocations.length || !!removedLocations.length || !!updatedLocations.length,
    [newLocations.length, removedLocations.length, updatedLocations.length],
  );

  const allLocationsValid = useCallback(
    () =>
      getLocations()
        .map((loc) => updatedLocations.find((updatedLoc) => updatedLoc.id === loc.id) || loc)
        .every(
          ({ geometry, zipCode, street, streetNumberUnknown, streetNumberFrom, streetNumberTo }) =>
            geometry && zipCode && street && (streetNumberUnknown || (streetNumberFrom && streetNumberTo)),
        ),
    [getLocations, updatedLocations],
  );

  const saveAllowed = useCallback(
    () => hasUpdatedLocations() && allLocationsValid() && allLocationsValidated(),
    [allLocationsValid, hasUpdatedLocations, allLocationsValidated],
  );

  const submitAllowed = useCallback(
    () => !!getLocations().length && !hasUpdatedLocations() && allLocationsValid(),
    [allLocationsValid, getLocations, hasUpdatedLocations],
  );

  const backButtonUrl = appUrls.requests.edit(id);

  const onBackToRequest = useCallback(() => {
    history.push(appUrls.requests.detail(id));
  }, [history, id]);

  return (
    <LoaderBlur loading={saving}>
      <Grid container>
        <Grid
          item
          xs={9}
          style={{
            position: 'fixed',
            width: '100%',
            display: 'flex',
            height: '100vh',
            flexDirection: 'column',
          }}
        >
          <Header
            backButtonUrl={backButtonUrl}
            extraHeader={
              <Button.White onClick={onBackToRequest}>{translate('Requests.Detail.BackToRequest')}</Button.White>
            }
          >
            {`${translate('Requests.Detail.Title')} ${request?.referenceId || ''}`}
          </Header>
          {!!carFreeZones?.length && (
            <MapPane
              allLocations={getLocations()}
              canEditLocations={canEditLocations}
              conflicts={conflicts}
              location={getSelectedLocation()}
              onSaveLocation={_onSaveLocation}
              onSelectOverview={hideExpandedLocation}
              onSetGeometry={_onSetGeometry}
            />
          )}
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            marginLeft: '75%',
          }}
        >
          <SidePane
            canEditLocations={canEditLocations}
            costOverview={costOverview}
            expandedLocationId={expanded}
            locations={getLocations()}
            onAddLocation={_onAddLocation}
            onExpandLocation={_onExpandLocation}
            onRemoveLocation={_onRemoveLocation}
            onSaveLocation={_onSaveLocation}
            onSaveLocations={_onSaveLocations}
            onSubmit={_onSubmit}
            saveAllowed={validated && saveAllowed()}
            submitAllowed={submitAllowed()}
            submitVisible={request?.state?.state === RequestStates.draft}
            onValidationResult={_onValidationResult}
            onLocationTypeChanged={_onLocationTypeChanged}
          />
        </Grid>
        <UndoSnackbar onUndo={_onUndoRemoveLocation} locationId={lastRemovedLocation?.id} />
      </Grid>
    </LoaderBlur>
  );
};
