import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '../../../common';
import { translate } from '../../../common/translations/translate';
import { Header } from '../../../common/components/layout/Header.component';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { Paper } from '@material-ui/core';
import { getFirstVisibleTab } from '../../../common/store/user/user.selectors';

export const UnauthorizedPage: FC = () => {
  const firstVisibleTab = useSelector(getFirstVisibleTab);

  useEffect(() => {
    const id = setTimeout(() => {
      if (firstVisibleTab?.route) {
        window.location.href = firstVisibleTab.route;
      }
    }, 1000);
    return () => clearTimeout(id);
  }, [firstVisibleTab?.route]);

  return (
    <>
      <Header>{translate('Acl.Unauthorized.Title')}</Header>
      <Container>
        <Paper square data-testid="unauthorizedPage">
          <PanelContent title={translate('Acl.Unauthorized.SubTitle')}>
            {translate('Acl.Unauthorized.Description')}
          </PanelContent>
        </Paper>
      </Container>
    </>
  );
};
