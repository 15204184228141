import { Checkbox } from '@material-ui/core';
import { translate } from '../../../common/translations/translate';
import React, { FC, useState } from 'react';
import { ConfirmDialog } from '../../../common/components/modal-dialog/confirm-dialog.component';
import { ISign } from '../../types/sign';

interface IProps {
  sign: ISign;
  onClose: () => void;
  onConfirm: (withModule: boolean) => void;
  visible: boolean;
}

export const DeleteSignDialog: FC<IProps> = ({ onClose, onConfirm, visible, sign }) => {
  const [deleteModule, setDeleteModule] = useState(false);
  const _onConfirm = () => {
    onConfirm(deleteModule);
  };

  const _handleClick = () => {
    setDeleteModule(!deleteModule);
  };

  return (
    <ConfirmDialog
      onClose={onClose}
      onConfirm={_onConfirm}
      title={translate('DeleteSign')}
      okButtonText={translate('DeleteSign')}
      visible={visible}
    >
      {translate('DeleteSignConfirmation')}
      {sign.isSmart && (
        <>
          <Checkbox
            data-testid={'DeleteModuleToo'}
            value={deleteModule}
            checked={deleteModule}
            onClick={_handleClick}
          />
          {translate('DeleteModuleToo')}
        </>
      )}
    </ConfirmDialog>
  );
};
