import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from '../../../../common/translations/translate';
import { default as React, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { backendUrl } from '../../../../common';
import { Unavailable } from '../../../../common/components/formatters/unavailable';
import { CollapsablePanel } from '../../../../components';
import moment from 'moment';
import { LinkedTableRow } from '../../../../common/components/table/linked-table-row.component';
import { DateFormat } from '../../../../types/date.types';
import { getRequestPermitHistory } from '../../../../store/selectors';

const useStyles = makeStyles({
  tableRow: {
    '& TableCell': {
      borderBottom: 'none',
    },
  },
});

export const PermitHistoryComponent: FunctionComponent = () => {
  const C = useStyles();

  const permitHistory = useSelector(getRequestPermitHistory);

  return (
    <CollapsablePanel title={translate('Requests.Detail.PermitHistory.Title')}>
      {permitHistory.length ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translate('Requests.Detail.PermitHistory.Timestamp')}</TableCell>
              <TableCell>{translate('Requests.Detail.PermitHistory.Filename')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permitHistory.map((historyRecord) => (
              <LinkedTableRow
                className={C.tableRow}
                href={`${backendUrl}${historyRecord.filePath}`}
                target="_blank"
                key={Math.random()}
                disabled={historyRecord.disabled}
              >
                <TableCell>
                  {moment(historyRecord.timeChanged, 'HH:mm - DD.MM.YYYY').format(DateFormat.dateTime)}
                </TableCell>
                <TableCell>{historyRecord.filename}</TableCell>
              </LinkedTableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Unavailable text={translate('Requests.Detail.PermitHistory.Unavailable')} />
      )}
    </CollapsablePanel>
  );
};
