import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from '../../../common/translations/translate';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormikAutocompleteFetchField,
  FormikAutocompleteField,
  FormikCheckboxField,
  FormikSelect,
  FormikTextField,
} from '../../../common/components/formikFormFields';
import { Visible } from '../../../common/components/layout/Visible.component';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { getCity, getZipcodesAsFormikOptions } from '../../../common/utils/zipCodes.util';
import { RequestLocationsActions } from '../../../store/actions';
import {
  getAllRequestReasons,
  getEditableFieldsIncludes,
  getRequestReason,
  getRequestSuggestions,
  selectUser,
} from '../../../store/selectors';
import {
  IFetchSuggestions,
  RequestCreateField,
  IRequestFormData,
  RequestReason,
  RequestReasonType,
} from '../../../types';

const useStyles = makeStyles({
  root: { marginBottom: '2em' },
  container: { width: 550 },
  countryField: { width: 300 },
});

interface IProps {
  values: IRequestFormData;
  setFieldValue: (field: string, value: any) => void;
}

export const RequestFormReasonComponent: FunctionComponent<IProps> = ({ values, setFieldValue }) => {
  const C = useStyles();
  const dispatch = useDispatch();

  const currentUser = useSelector(selectUser);
  const reasons = useSelector(getAllRequestReasons);
  const currentReason = useSelector(getRequestReason(values.reason));
  const externalReferenceDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.externalReference));
  const canEditNeedsPlacement = useSelector(getEditableFieldsIncludes(RequestCreateField.needsPlacement));
  const suggestions = useSelector(getRequestSuggestions);
  const createRequestWithoutLimitations = currentUser?.acl.createRequestWithoutLimitations;

  const onRefreshSuggestions = useCallback(
    (address: IFetchSuggestions) => {
      dispatch(RequestLocationsActions.fetchSuggestions(address));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!!currentReason) {
      if (canEditNeedsPlacement) {
        setFieldValue(RequestCreateField.needsPlacement, currentReason.needsPlacement);
      }

      if (currentReason?.type !== RequestReasonType.events) {
        setFieldValue(RequestCreateField.eventName, undefined);
      }
    }
  }, [currentReason, values.reason, setFieldValue, canEditNeedsPlacement]);

  useEffect(() => {
    if (!createRequestWithoutLimitations && values.reason !== RequestReason.utility_request) {
      setFieldValue(RequestCreateField.reason, RequestReason.utility_request);
    }
  }, [values.reason, setFieldValue, createRequestWithoutLimitations]);

  const getZipCodeOptions = useCallback(getZipcodesAsFormikOptions, []);

  const isRequestAddressVisible = !!currentReason?.acl.showOnFrontOffice;

  useEffect(() => {
    if (!isRequestAddressVisible) {
      // Unset adress values for hidden fields
      setFieldValue(RequestCreateField.requestCity, undefined);
      setFieldValue(RequestCreateField.requestZipCode, undefined);
      setFieldValue(RequestCreateField.requestStreet, '');
      setFieldValue(RequestCreateField.requestStreetNumber, '');
    }
  }, [isRequestAddressVisible, setFieldValue]);

  const onChangeZipCode = useCallback(() => {
    if (values.requestZipCode) {
      setFieldValue(RequestCreateField.requestCity, getCity(values.requestZipCode));
      setFieldValue(RequestCreateField.requestStreet, '');
      setFieldValue(RequestCreateField.requestStreetNumber, '');
    }
  }, [values.requestZipCode, setFieldValue]);

  return (
    <Box className={C.root}>
      <PanelTitle>{translate('Requests.Create.ReasonTitle')}</PanelTitle>
      <Grid container className={C.container} spacing={4} justify={'flex-start'}>
        <Grid item xs={12}>
          <FormikAutocompleteField
            disabled={
              !useSelector(getEditableFieldsIncludes(RequestCreateField.reason)) || !createRequestWithoutLimitations
            }
            name={RequestCreateField.reason}
            label={translate('Requests.Create.Form.reason')}
            options={reasons}
            value={`${values.reason}`}
          />
        </Grid>
        <Visible visible={currentReason?.type === RequestReasonType.events}>
          <Grid item xs={12}>
            <FormikTextField
              name={RequestCreateField.eventName}
              label={translate('Requests.Create.EventName')}
              required={false}
            />
          </Grid>
        </Visible>
        {createRequestWithoutLimitations && (
          <Grid item xs={12}>
            <FormikCheckboxField
              disabled={!canEditNeedsPlacement}
              name={RequestCreateField.needsPlacement}
              label={translate('Requests.Create.Form.needsPlacement')}
            />
          </Grid>
        )}

        {(values.reason === RequestReason.construction_zone || values.reason === RequestReason.utility_request) && (
          <>
            <Grid item xs={6}>
              <FormikTextField
                disabled={externalReferenceDisabled}
                name={RequestCreateField.externalReference}
                label={
                  values.reason === RequestReason.utility_request
                    ? translate('Requests.Create.Form.utilityRequestNumber')
                    : translate('Requests.Create.Form.bosgNumber')
                }
              />
            </Grid>
            <Grid item xs={6}>
              {' '}
            </Grid>
          </>
        )}
        <Visible visible={isRequestAddressVisible}>
          <Grid item xs={4}>
            <FormikSelect
              label={`${translate('Requests.Create.Form.zipCode')} - ${translate('Requests.Create.Form.city')}`}
              name={RequestCreateField.requestZipCode}
              options={getZipCodeOptions()}
              onChange={onChangeZipCode}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikAutocompleteFetchField
              setFieldValue={setFieldValue}
              label={translate('Requests.Create.Form.street')}
              name={RequestCreateField.requestStreet}
              onChangeText={(street: string) =>
                onRefreshSuggestions({ street, zipCode: parseInt(`${values.requestZipCode}`) })
              }
              options={suggestions}
              value={values.requestStreet || ''}
              disabled={!values.requestZipCode}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              disabled={!values.requestZipCode}
              name={RequestCreateField.requestStreetNumber}
              label={translate('Requests.Create.Form.streetNumber')}
              required={false}
            />
          </Grid>
        </Visible>

        <Grid item xs={12}>
          <FormikTextField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.comment))}
            name={RequestCreateField.comment}
            label={translate('Requests.Create.Form.comment')}
            multiline
          />
        </Grid>
      </Grid>
    </Box>
  );
};
