import { Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline as AddIcon, CloseSharp as Close } from '@material-ui/icons';
import { translate } from '../../../../common/translations/translate';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { ChangeEvent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { AsignDatepicker } from '../../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { SingleSelectV2Component } from '../../../../common/components/form-fields/single-select-v2.component';
import { Unavailable } from '../../../../common/components/formatters/unavailable';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { PanelTitle } from '../../../../common/components/panel/panel-title.component';
import {
  getWorkOrderItemCreatableTypesMenu,
  selectWorkOrderItemTypes,
} from '../../../../common/store/work-order-item/work-order-items.selectors';
import { Auth } from '../../../../components';
import { useAuthorization } from '../../../../hooks';
import { WorkorderItemStates, WorkOrderItemTypes } from '../../../../planning/planning.constants';
import { useGeneralStyles } from '../../../../style/generalStyles';
import {
  DateFormat,
  IRequestLocationDetail,
  IWorkOrderItem,
  IWorkOrderItemAclActions,
  IWorkOrderItemPost,
} from '../../../../types';

interface IProps {
  detail: IRequestLocationDetail | null;
  onDeleteWorkOrder: (id: number) => void;
  onPostWorkOrder: (payload: IWorkOrderItemPost) => void;
  parkingBanIntakeId: number;
}

const useStyles = makeStyles({
  inputField: {
    width: '100%',
    marginTop: '15px',
  },
  button: {
    textAlign: 'right',
    marginTop: '15px',
  },
});

export const PlacementComponent: React.FunctionComponent<IProps> = ({
  detail,
  onDeleteWorkOrder,
  onPostWorkOrder,
  parkingBanIntakeId,
}) => {
  const C = useStyles();
  const G = useGeneralStyles();

  const workOrderItemTypes = useSelector(selectWorkOrderItemTypes);
  const workOrderItemCreatableTypes = useSelector(getWorkOrderItemCreatableTypesMenu);
  const { isAuthorized: hasEditAccess } = useAuthorization(IWorkOrderItemAclActions.editWorkOrderItem);

  const [newWorkOrderType, setNewWorkOrderType] = useState<WorkOrderItemTypes>();
  const [description, setDescription] = useState<string>();
  const [dueDate, setDueDate] = useState<Moment>();

  const onChangeDescription = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  }, []);

  const onChangeWorkOrderItemType = useCallback((event: ChangeEvent<any>) => {
    setNewWorkOrderType(event.target.value);
  }, []);

  const getWorkOrderName = useCallback(
    (workOrderItem: IWorkOrderItem) => {
      const type = workOrderItemTypes.find((item) => item.type === workOrderItem.type);
      return type ? type.name : '';
    },
    [workOrderItemTypes],
  );

  const onSend = useCallback(() => {
    newWorkOrderType &&
      onPostWorkOrder({
        description: description || undefined,
        dueDate: dueDate ? dueDate.format('YYYY-MM-DD') : undefined,
        parkingBanIntake: parkingBanIntakeId,
        state: 'open',
        type: newWorkOrderType,
      });
    setDescription(undefined);
    setNewWorkOrderType(undefined);
    setDueDate(undefined);
  }, [description, dueDate, newWorkOrderType, onPostWorkOrder, parkingBanIntakeId]);

  const onDelete = useCallback((id: number) => () => onDeleteWorkOrder(id), [onDeleteWorkOrder]);

  const showPlacement = !!detail?.workOrderItems?.length;

  return (
    <Grid className={G.fullWidth}>
      <Visible
        visible={showPlacement}
        defaultComponent={<Unavailable text={translate('Requests.Detail.Placement.Unavailable')} />}
      >
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell>{translate('Requests.Detail.Placement.Workorder')}</TableCell>
              <TableCell>{translate('Requests.Detail.Placement.Tour')}</TableCell>
              <TableCell>{translate('Requests.Detail.Placement.Planned')}</TableCell>
              <TableCell>{translate('Requests.Detail.Placement.Executed')}</TableCell>
              <TableCell>{translate('Requests.Detail.Placement.AddedBy')}</TableCell>
              <TableCell>{translate('Requests.Detail.Placement.Description')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <Visible visible={!!detail?.workOrderItems}>
              {detail?.workOrderItems?.map((woi) => (
                <TableRow key={woi.id}>
                  <TableCell>{getWorkOrderName(woi)}</TableCell>
                  <TableCell>{woi.workOrder ? woi.workOrder.team.name : ''}</TableCell>
                  <TableCell>{woi.workOrder ? moment(woi.workOrder.date).format(DateFormat.date) : ''}</TableCell>
                  <TableCell>
                    {woi.state === WorkorderItemStates.done
                      ? moment(woi.completedAt).format(DateFormat.dateTime)
                      : translate('Requests.Detail.Placement.NotYetCompleted')}
                  </TableCell>
                  <TableCell>
                    {woi.createdBy ? woi.createdBy.fullName : translate('Requests.Detail.Placement.System')}
                  </TableCell>
                  <TableCell>{woi.description}</TableCell>
                  <TableCell>
                    <Visible visible={hasEditAccess && woi.state === WorkorderItemStates.open}>
                      <Tooltip title={translate('Requests.Detail.Placement.RemoveWorkOrder')}>
                        <Close fontSize={'small'} className={G.clickable} onClick={onDelete(woi.id)} />
                      </Tooltip>
                    </Visible>
                  </TableCell>
                </TableRow>
              ))}
            </Visible>
          </TableBody>
        </Table>
      </Visible>
      <Auth acl={IWorkOrderItemAclActions.editWorkOrderItem}>
        <Visible visible={hasEditAccess}>
          <PanelTitle>{translate('Requests.Detail.Placement.NewWorkOrderItem')}</PanelTitle>
          <Grid container>
            <Grid item xs={12}>
              <SingleSelectV2Component
                placeholder={translate('Requests.Detail.Placement.WorkOrderType')}
                onChange={onChangeWorkOrderItemType}
                value={newWorkOrderType}
                menuItems={workOrderItemCreatableTypes}
              />
            </Grid>
            {newWorkOrderType === WorkOrderItemTypes.pick_up && (
              <Grid item xs={12}>
                <AsignDatepicker
                  value={dueDate}
                  fullWidth
                  className={C.inputField}
                  label={translate('Requests.Detail.Placement.PickupDate')}
                  onChange={setDueDate}
                  minDate={moment()}
                />
              </Grid>
            )}
            <Grid item xs={12} container>
              <Grid container justify="flex-start">
                <Grid item xs={12} className={C.inputField}>
                  <TextField
                    value={description || ''}
                    onChange={onChangeDescription}
                    fullWidth
                    label={translate('Requests.Detail.Placement.DescriptionOfWorkOrderItem')}
                  />
                </Grid>
                <Grid item xs={12} className={C.button}>
                  <Button.Green
                    color="secondary"
                    onClick={onSend}
                    disabled={!newWorkOrderType}
                    startIcon={<AddIcon fontSize={'small'} />}
                  >
                    {translate('Requests.Detail.Placement.AddPlacementButton')}
                  </Button.Green>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Visible>
      </Auth>
    </Grid>
  );
};
