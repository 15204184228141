import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { translate } from '../../../common/translations/translate';

interface IProps {
  locationId?: number;
  onUndo: (locationId: number) => void;
}

export const UndoSnackbar: FunctionComponent<IProps> = ({ locationId, onUndo }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    locationId && setOpen(true);
  }, [locationId]);

  const _onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const _onCloseBySnackbar = useCallback(
    (event: any, reason: string) => {
      // Only do a close when a timeout is done. Ignore the clickaway as this returns in a bug
      // that when deleting 2 locations within the autohide duration it will close the snackbar
      if (reason === 'timeout') {
        _onClose();
      }
    },
    [_onClose],
  );

  const _onUndo = useCallback(() => {
    locationId && onUndo(locationId);
    _onClose();
  }, [_onClose, locationId, onUndo]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={_onCloseBySnackbar}
      message={translate('Requests.Create.Undo.Description')}
      action={
        <>
          <Button.Gray onClick={_onUndo} dataTestId="UndoSnackbarbutton">
            {translate('Requests.Create.Undo.Button')}
          </Button.Gray>
          <IconButton size="small" aria-label="close" color="inherit" onClick={_onClose}>
            <Close fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};
