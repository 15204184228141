import { CircularProgress, Grid, makeStyles, TextField } from '@material-ui/core';
import { AttachFileSharp, Close, SendOutlined } from '@material-ui/icons';
import { translate } from '../../../common/translations/translate';
import * as React from 'react';
import { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../root.state';
import { IRequestMessagesList, IUploadedFile } from '../../../types';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import {
  deleteMessageAttachment,
  saveRequestMessage,
  resolveRequestMessages,
  uploadMessageAttachment,
} from '../../../store/actions';

export interface IMessagesProps {
  requestId: string;
  messagesList: IRequestMessagesList;
  updateSizeHandler?: () => void;
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: { marginLeft: 20 },
  buttonIcon: { marginRight: 5, width: 20 },
});

export const Form: FunctionComponent<IMessagesProps> = (props: IMessagesProps) => {
  const styles = useStyles();
  const acceptFileTypes =
    'image/*, .heic, ' +
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document, ' +
    'application/msword,  application/pdf';

  if (props.updateSizeHandler) {
    props.updateSizeHandler();
  }

  const [newMessageText, setNewMessageText] = useState<string>('');

  const messageAttachments = useSelector((store: IRootState) => store.requests.requestMessageAttachments);
  const uploadFileRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const attachmentsValid = () => {
    return Object.values(messageAttachments).filter((attachment) => !attachment.id).length === 0;
  };

  const canSend = () => {
    return newMessageText && attachmentsValid();
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessageText(event.target.value);
  };

  const sendMessageHandler = () => {
    if (canSend()) {
      dispatch(
        saveRequestMessage({
          attachments: messageAttachments || [],
          content: newMessageText,
          requestId: props.requestId,
        }),
      );
      setNewMessageText('');
    }
  };

  const resolveMessagesHandler = () => {
    dispatch(resolveRequestMessages(props.requestId));
  };

  const handleUploadClick = () => {
    // @ts-ignore
    uploadFileRef.current.click();
  };

  const fileUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      const len = event.target.files.length;
      for (let i = 0; i < len; i++) {
        const file = event.target.files[i] as File;
        dispatch(uploadMessageAttachment(file));
      }
    }
  };

  const deleteAttachmentHandler = (file: IUploadedFile) => {
    return () => {
      dispatch(deleteMessageAttachment(file));
    };
  };

  Object.values(messageAttachments)
    .filter((f) => f.uploadError)
    .forEach((f) => {
      // Use simple browser alert because this component embedded into old angular app
      alert(f.uploadError);
      dispatch(deleteMessageAttachment(f));
    });

  return (
    <div className="messages-form">
      <Grid container alignItems={'flex-end'}>
        <Grid item xs={11}>
          <TextField
            id="message_input"
            name="message"
            label={translate('Messages.Form.Label')}
            value={newMessageText}
            placeholder={translate('Messages.Form.Placeholder')}
            fullWidth
            margin="none"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleMessageChange}
          />
        </Grid>
        <Grid item xs={1}>
          <AttachFileSharp className="messages-form-attachment" onClick={handleUploadClick} />
          <input
            // AS-5495 Problem when adding an attachment twice
            key={Object.values(messageAttachments).length}
            type="file"
            name="file"
            onChange={fileUploadHandler}
            multiple
            ref={uploadFileRef}
            style={{ display: 'none' }}
            accept={acceptFileTypes}
          />
        </Grid>
        <Grid item xs={12}>
          {Object.values(messageAttachments).map((file) => {
            return (
              // TODO: Use correct href in link
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="messages-attachments-file messages-attachments-file-new" key={file.key}>
                {file.name}
                {file.loading ? (
                  <CircularProgress className="messages-attachments-progress" />
                ) : (
                  <Close
                    fontSize={'small'}
                    color={'error'}
                    className="messages-attachments-delete"
                    onClick={deleteAttachmentHandler(file)}
                  />
                )}
              </a>
            );
          })}
        </Grid>
        <div className={styles.buttonContainer}>
          {props.messagesList.acl.messages_can_resolve && (
            <div className={`messages-form-resolve-button  ${styles.button}`}>
              <Button.Green onClick={resolveMessagesHandler}>{translate('Messages.Form.ResolveButton')}</Button.Green>
            </div>
          )}
          <div className={`messages-form-button ${styles.button}`}>
            <Button.Green disabled={!canSend()} onClick={sendMessageHandler}>
              <SendOutlined className={styles.buttonIcon} />
              {translate('Messages.Form.SendButton')}
            </Button.Green>
          </div>
        </div>
      </Grid>
    </div>
  );
};
