import { translate } from '../../../../common/translations/translate';
import React, { FunctionComponent, useCallback } from 'react';
import { IPublicDomainIntakeExtended } from '../../../../types';
import { SingleSelectV2Component } from '../../../../common/components/form-fields/single-select-v2.component';
import { LocationButtons } from '../location-buttons.component';
import { PanelContent } from '../../../../common/components/panel/panel-content.component';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  locations: IPublicDomainIntakeExtended[];
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  selectedLocation?: IPublicDomainIntakeExtended;
}

const useStyles = makeStyles({
  container: { display: 'flex' },
  select: { flex: 1 },
  buttonContainer: {
    marginLeft: 20,
    minWidth: 200,
  },
});

export const SelectLocation: FunctionComponent<IProps> = ({ locations, onChange, selectedLocation }) => {
  const styles = useStyles();

  const menuItems = useCallback(
    () => [
      { value: '', label: translate('Requests.Detail.Locations.ShowAllLocations') },
      ...locations.map((location) => ({ value: location.id, label: location.streetAndNumbers })),
    ],
    [locations],
  );

  return (
    <PanelContent title={translate('Requests.Detail.Locations.Title')}>
      <div className={styles.container}>
        <div className={styles.select}>
          <SingleSelectV2Component
            disabled={locations.length === 1}
            value={selectedLocation?.id}
            onChange={onChange}
            menuItems={menuItems()}
          />
        </div>
        <div className={styles.buttonContainer}>
          <LocationButtons location={selectedLocation} />
        </div>
      </div>
    </PanelContent>
  );
};
