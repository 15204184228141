import { createReducer } from '@reduxjs/toolkit';
import { IById } from '../../../../common/utils/normalized.util';
import { IFeature } from '../../../../types';
import { RequestActions } from '../../../actions';

type TEntity = IFeature[];
type TState = IById<TEntity>;

export default createReducer<TState>({}, (builder) => {
  builder.addCase(
    RequestActions.setConflicts.type,
    (state, { payload: { id, conflicts } }: ReturnType<typeof RequestActions.setConflicts>) => {
      if (id) {
        state[id] = conflicts;
      }
    },
  );
});
