import { ExpansionPanelDetails, ExpansionPanelSummary, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { atomWithHash } from 'jotai-location';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Visible } from '../../../common/components/layout/Visible.component';
import { ATOM_HASHES } from '../../../common/config/atom.constants';
import { translate } from '../../../common/translations/translate';
import { formatDateTimeToDisplayDate } from '../../../common/utils/date.util';
import { EditButton } from '../../../request/components/details/edit-button.component';
import { useGeneralStyles } from '../../../style/generalStyles';
import { IRequestRescheduling } from '../../../types/SgwReschedulings.types';
import { JotaiExpansionPanel } from '../../atoms';
import { reschedulingEditModeAtom } from '../../organisms';
import { SgwReschedulingContent } from './SgwReschedulingContent.component';
import { SgwReschedulingForm } from './SgwReschedulingForm.component';

export interface IProps {
  rescheduling: IRequestRescheduling;
  index: number;
}

const useStyles = createUseStyles({
  title: {
    width: '100%',
    alignItems: 'center',
  },
  accordion: {
    boxShadow: 'none',
  },
});

export const SgwReschedulingAccordion: FC<IProps> = ({ rescheduling, index }) => {
  const isExpanded = atomWithHash<boolean>(ATOM_HASHES.reschedulings.open(rescheduling.id), false);
  const G = useGeneralStyles();
  const C = useStyles();
  const [editMode, setEditmode] = useAtom(reschedulingEditModeAtom);

  return (
    <JotaiExpansionPanel isExpanded={isExpanded} className={C.accordion}>
      <ExpansionPanelSummary>
        <div className={classNames(G.flexRowSpaceBetween, C.title)}>
          {`${translate('sgw.requests.detail.requestRescheduling.accordion.title')} ${index + 1}`}
          <div className={G.flexColumn}>
            <span>{`${translate(
              'sgw.requests.detail.requestRescheduling.accordion.requestedAt',
            )} ${formatDateTimeToDisplayDate(rescheduling.createdAt)}`}</span>
            <Visible visible={!!rescheduling.consideredAt}>
              <span>{`${translate(
                'sgw.requests.detail.requestRescheduling.accordion.consideredAt',
              )} ${formatDateTimeToDisplayDate(rescheduling.consideredAt!)}`}</span>
            </Visible>
            <Visible visible={!!rescheduling.appliedAt}>
              <span>{`${translate(
                'sgw.requests.detail.requestRescheduling.accordion.appliedAt',
              )} ${formatDateTimeToDisplayDate(rescheduling.appliedAt!)}`}</span>
            </Visible>

            <Visible visible={!!rescheduling.rejectedAt}>
              <span>{`${translate(
                'sgw.requests.detail.requestRescheduling.accordion.rejectedAt',
              )} ${formatDateTimeToDisplayDate(rescheduling.rejectedAt!)}`}</span>
            </Visible>
          </div>
        </div>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className={G.flexColumn}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4}>
            <b>{translate('sgw.requests.detail.requestRescheduling.accordion.phase')}</b>
          </Grid>
          <Grid item xs={3}>
            {translate('sgw.requests.detail.requestRescheduling.accordion.newStartDate')}
          </Grid>
          <Grid item xs={3}>
            {translate('sgw.requests.detail.requestRescheduling.accordion.newEndDate')}
          </Grid>
          <Visible visible={rescheduling?.acl?.updateReschedulingRequest}>
            <Grid item xs={2}>
              <EditButton
                title={translate('sgw.requests.detail.requestRescheduling.edit')}
                onClick={() => setEditmode((prev) => !prev)}
              />
            </Grid>
          </Visible>
        </Grid>
        <Visible
          visible={editMode && rescheduling?.acl?.updateReschedulingRequest}
          defaultComponent={<SgwReschedulingContent rescheduling={rescheduling} />}
        >
          <SgwReschedulingForm rescheduling={rescheduling} />
        </Visible>
      </ExpansionPanelDetails>
    </JotaiExpansionPanel>
  );
};
