import { LatLng, LatLngBounds } from 'leaflet';
import React, { FunctionComponent, useCallback } from 'react';
import { BaseMap, COLORS } from '../../../../common';
import { GeometryComponent } from '../../../../common/components/map/Geometry.component';
import { GeometryConflict } from '../../../../common/components/map/GeometryConflict.component';
import { IFeature, IntakeTypes, IPublicDomainIntakeExtended } from '../../../../types';
import { MapLocationControles } from './MapLocationControles.component';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { SelectableCarFreeZoneLayer } from '../../../../common/components/map/SelectableCarFreeZoneLayer.component';
import { useSelector } from 'react-redux';
import { isAppFeatureEnabled } from '../../../../store/selectors';

interface IProps {
  bounds?: LatLngBounds;
  center?: LatLng;
  conflicts: IFeature[];
  locations: IPublicDomainIntakeExtended[];
  onClick?: (id: number) => void;
  resetPosition: () => void;
  selectedLocation?: IPublicDomainIntakeExtended;
  disabled?: boolean;
}

const pathOptions = {
  primary: {
    color: COLORS.PRIMARY,
  },
  grey: {
    color: COLORS.GREY_LIGHT,
  },
};

export const Map: FunctionComponent<IProps> = ({
  bounds,
  conflicts,
  locations,
  onClick,
  selectedLocation,
  resetPosition,
  disabled,
}) => {
  const isArcGisEnabled = useSelector(isAppFeatureEnabled.integrateDigipolisArcGis);

  const _onClick = (id: number) => () => {
    onClick?.(id);
  };

  const getGisIds = useCallback(() => {
    if (!selectedLocation) {
      return locations
        .filter(({ type }) => type.type === IntakeTypes.carfreezoneintake)
        .map(({ carFreeZone, gisId }) => carFreeZone?.gisId || gisId)
        .filter((gisId) => !!gisId) as string[];
    }
    if (
      selectedLocation?.type?.type === IntakeTypes.carfreezoneintake &&
      (selectedLocation?.gisId || selectedLocation?.carFreeZone?.gisId)
    ) {
      return [selectedLocation?.gisId || selectedLocation?.carFreeZone?.gisId || ''];
    }
    return [];
  }, [locations, selectedLocation]);

  return (
    <BaseMap
      height="40vh"
      boundsOptions={{ padding: [30, 30], maxZoom: 19 }}
      showLayersControl
      bounds={bounds}
      maxZoom={selectedLocation?.type.type === IntakeTypes.carfreezoneintake ? 17 : 19}
      disabled={disabled}
    >
      <Visible visible={!disabled}>
        <MapLocationControles resetPosition={resetPosition} />
      </Visible>
      {locations
        .filter(({ id }) => selectedLocation?.id !== id)
        .map(({ geometry, positions, id, type }) => (
          <Visible key={id} visible={!!geometry}>
            <GeometryComponent
              key={id}
              geometry={geometry!}
              positions={positions}
              onClick={_onClick(id)}
              pathOptions={!!selectedLocation ? pathOptions.grey : pathOptions.primary}
              showMarkers={type.type !== IntakeTypes.carfreezoneintake}
            />
          </Visible>
        ))}
      <Visible visible={!!selectedLocation}>
        <GeometryComponent
          key={selectedLocation?.id}
          geometry={selectedLocation?.geometry}
          positions={selectedLocation?.positions}
          onClick={_onClick(selectedLocation?.id || 0)}
          pathOptions={pathOptions.primary}
          showMarkers={selectedLocation?.type.type !== IntakeTypes.carfreezoneintake}
        />
      </Visible>
      {conflicts?.map((conf) => <GeometryConflict key={conf.properties.key} conflict={conf} />)}
      <Visible visible={isArcGisEnabled}>
        <SelectableCarFreeZoneLayer selectedGisIds={getGisIds()} />
      </Visible>
    </BaseMap>
  );
};
