import { createReducer } from '@reduxjs/toolkit';
import { extractIdsFromArray } from '../../../common/utils/normalized.util';
import { RequestActions } from '../../actions';

type TState = string[];

export default createReducer<TState>([], (builder) => {
  builder.addCase(RequestActions.setDrafts.type, (state, { payload }: ReturnType<typeof RequestActions.setDrafts>) =>
    extractIdsFromArray(payload),
  );
  builder.addCase(RequestActions.remove.type, (state, { payload }: ReturnType<typeof RequestActions.remove>) =>
    state.filter((id) => id !== payload),
  );
});
