import { baseApi } from './index';
import {
  EDITABLE_STATES,
  IManualPlacementMutationParams,
  IManualPlacementMutationResponse,
  IManualPlacementQueryParams,
  IManualPlacementResponse,
  IManualPlacementTransformedResponse,
} from '../../../types';
import { extendPublicDomainIntake } from '../../../common/utils/location.util';

export const manualPlacementApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getManualPlacement: builder.query<IManualPlacementTransformedResponse | undefined, IManualPlacementQueryParams>({
      query: ({ id, key }) => ({
        url: `/manual_placement/requests/${id}/${key}`,
        method: 'GET',
      }),
      providesTags: ['ManualPlacement'],
      transformResponse: (response: IManualPlacementResponse) => {
        if (!response || !response.data) return undefined;
        const { metadata, permitRequest } = response.data;
        return {
          ...permitRequest,
          locations: permitRequest.publicDomainIntakes.map((intake) => extendPublicDomainIntake(intake)),
          metadata,
          editable: permitRequest?.state.state && EDITABLE_STATES.includes(permitRequest?.state.state),
        };
      },
    }),
    updateManualPlacement: builder.mutation<IManualPlacementMutationResponse, IManualPlacementMutationParams>({
      query: ({ requestId, publicDomainIntakeId, key, data }) => ({
        url: `/manual_placement/requests/${requestId}/${key}`,
        method: 'PATCH',
        body: {
          parkingBanIntakes: [{ id: publicDomainIntakeId, ...data }],
        },
      }),
      invalidatesTags: ['ManualPlacement'],
      transformResponse: (response: IManualPlacementMutationResponse) => {
        if (response.data === null && response.msgs?.length) {
          throw new Error(response.msgs[0].message);
        }
        return response;
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetManualPlacementQuery, useUpdateManualPlacementMutation } = manualPlacementApi;
