import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import { translate } from '../../../../common/translations/translate';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { Unavailable } from '../../../../common/components/formatters/unavailable';
import { Header as GenericHeader } from '../../../../common/components/layout/Header.component';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { appUrls } from '../../../../common/config/url.constants';
import { AssignButton } from '../../../../components';
import { useAuthorization, useRequestMetadata } from '../../../../hooks';
import { RequestActions } from '../../../../store/actions';
import { getCarFreeZoneIntakes, getRequestAssignedUser, getRequestsDetails } from '../../../../store/selectors';
import { useGeneralStyles } from '../../../../style/generalStyles';
import { IRequestAclActions, IRequestDetailHeaderComponent } from '../../../../types';
import { InfoChip } from '../../icons/info-chip.component';
import { StatusIcon } from '../../icons/status-icon.component';
import { UrgentIcon } from '../../icons/urgent-icon';
import { CancelButton } from './CancelButton.component';
import { CopyButton } from './CopyButton.component';
import { ResetPincodeButton } from './ResetPincodeButton.component';
import { Review } from './review.component';

const useStyles = makeStyles({
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    height: 100,
    justifyContent: 'space-around',
    paddingLeft: '70px',
    paddingRight: '70px',
  },
  header: {
    marginLeft: '0px',
    marginRight: '20px',
  },
  readOnly: {
    margin: '5px',
  },
  rightHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    marginLeft: '0px',
    marginRight: '20px',
  },
  editButton: {
    marginLeft: '10px',
  },
  editIcon: {
    width: '17px',
  },
  editLocationIcon: {
    width: '20px',
  },
});

export const Header: FunctionComponent<IRequestDetailHeaderComponent> = ({ requestId, ...props }) => {
  const C = useStyles();
  const G = useGeneralStyles();

  const dispatch = useDispatch();
  const requestDetails = useSelector(getRequestsDetails(requestId));
  const metadata = useRequestMetadata();
  const carFreeZoneIntakes = useSelector(getCarFreeZoneIntakes(requestId));
  const { assignedToMe, assignedUser } = useSelector(getRequestAssignedUser(requestId));

  const history = useHistory();
  const { isAuthorized: canEditAssignedRequest } = useAuthorization(IRequestAclActions.editAssignedRequest);

  const canAssignToOneself = requestDetails?.acl.can_assign_request_to_oneself;
  const canUnassignFromOneself = requestDetails?.acl.can_unassign_request_from_oneself;
  const canEditLocations = !!requestDetails?.acl?.can_edit_locations;

  const onEdit = useCallback(() => {
    history.push(appUrls.requests.edit(requestId));
  }, [history, requestId]);

  const onResetPincode = useCallback(() => {
    dispatch(RequestActions.resetPincode(requestId));
  }, [dispatch, requestId]);

  const onEditLocation = useCallback(() => {
    history.push(appUrls.requests.editLocations(requestId));
  }, [history, requestId]);

  const onAssignRequest = (isMe?: boolean, checkFirst?: boolean) => {
    if (isMe) {
      checkFirst ? dispatch(RequestActions.attemptAssign(requestId)) : dispatch(RequestActions.assign(requestId));
    } else {
      assignedToMe ? dispatch(RequestActions.unassign(requestId)) : dispatch(RequestActions.assign(requestId));
      dispatch(RequestActions.setAlreadyAssigned(false));
    }
  };

  const onClose = () => {
    dispatch(RequestActions.setAlreadyAssigned(false));
  };

  return (
    <Visible visible={!!requestDetails && !!metadata}>
      <GenericHeader
        backButtonUrl={appUrls.requests.base}
        extraHeader={
          <Visible visible={!requestDetails?.sgwRequestId}>
            <div className={C.rightHeader}>
              <AssignButton
                assignedUser={assignedUser}
                onAssignRequest={onAssignRequest}
                canAssign={!assignedToMe && !!canAssignToOneself}
                canUnassign={assignedToMe && !!canUnassignFromOneself}
                onClose={onClose}
              />
              <CancelButton onCancelRequest={props.onCancelRequest} requestId={requestId} />
              <CopyButton onCopyRequest={props.onCopyRequest} requestId={requestId} />
              <Visible visible={assignedToMe && canEditAssignedRequest}>
                <Button.White
                  className={C.editButton}
                  iconButton
                  onClick={onEdit}
                  tooltip={translate('Requests.Header.Edit')}
                >
                  <EditIcon className={C.editIcon} color="primary" />
                </Button.White>
              </Visible>
              <Visible visible={assignedToMe && canEditAssignedRequest && canEditLocations}>
                <Button.White
                  className={C.editButton}
                  iconButton
                  onClick={onEditLocation}
                  tooltip={translate('Requests.Header.EditLocation')}
                >
                  <EditLocationIcon className={C.editLocationIcon} color="primary" />
                </Button.White>
              </Visible>
              <ResetPincodeButton onResetPincode={onResetPincode} requestId={requestId} />
              <Review
                onApproveRequest={props.onApproveRequest}
                onRejectRequest={props.onRejectRequest}
                onReviewRequest={props.onReviewRequest}
                requestId={requestId}
              />
            </div>
          </Visible>
        }
        subText={
          <Visible visible={!carFreeZoneIntakes.allCarFreeZonesProvided}>
            <Unavailable text={translate('Requests.Header.Approval.CarFreeZoneReason')} />
          </Visible>
        }
      >
        <Grid container className={C.header}>
          <Grid item xs={12}>
            <Visible visible={!!requestDetails?.emergencyProcedure}>
              <UrgentIcon className={G.marginRight} />
            </Visible>
            <span className={C.title}>{`${translate('Requests.Detail.Title')} ${requestDetails?.referenceId}`}</span>
            <Visible visible={!!metadata}>
              <StatusIcon statusState={requestDetails!.state.state} metadataStates={metadata?.states} />
            </Visible>
            <Visible visible={!!requestDetails?.isUtilityRequest}>
              <InfoChip text={translate('Requests.Detail.UtilityRequest')} />
            </Visible>
            <Visible visible={!!requestDetails?.sgwRequestId}>
              <InfoChip text={translate('Requests.Detail.SgwRequest')} />
            </Visible>
          </Grid>
        </Grid>
      </GenericHeader>
    </Visible>
  );
};
