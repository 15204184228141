import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from '../../../common/translations/translate';
import moment from 'moment';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Visible } from '../../../common/components/layout/Visible.component';
import { PaymentActions } from '../../../common/store/payments/payments.actions';
import { formatCurrency } from '../../../common/utils/string.util';
import { RequestActions } from '../../../store/actions';
import { getRequestExtensions, getRequestsDetails, getShowNewPaymentWarning } from '../../../store/selectors';
import { useGeneralStyles } from '../../../style/generalStyles';
import { CostState, DateFormat, IPaymentPatchState, IRequestDetailComponent, RequestStates } from '../../../types';
import { PaymentsComponent } from './payments.component';
import { ExtensionActions } from './request-extension-actions.component';

const useStyles = makeStyles({
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  newPayment: {
    marginTop: '30px',
  },
});

export const Payments: FunctionComponent<IRequestDetailComponent> = ({ requestId, visible = true }) => {
  const C = useStyles();
  const G = useGeneralStyles();

  const request = useSelector(getRequestsDetails(requestId));
  const requestExtensions = useSelector(getRequestExtensions);
  const showNewPaymentWarning = useSelector(getShowNewPaymentWarning(requestId));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RequestActions.fetchExtensions(`${requestId}`));
  }, [dispatch, requestId]);

  const onPatchPaymentState = useCallback(
    (payload: IPaymentPatchState) => {
      dispatch(PaymentActions.patchState(payload));
    },
    [dispatch],
  );

  const onApprove = useCallback(
    (extensionId: string) => (reason: string | null) => {
      request &&
        dispatch(
          RequestActions.patchExtensions({
            id: request.id,
            extensionId: extensionId,
            reasonForApproval: reason,
            state: RequestStates.approved,
          }),
        );
    },
    [request, dispatch],
  );

  const onReject = useCallback(
    (extensionId: string) => (reason: string | null) => {
      request &&
        dispatch(
          RequestActions.patchExtensions({
            id: request.id,
            extensionId: extensionId,
            reasonForRejection: reason,
            state: RequestStates.rejected,
          }),
        );
    },
    [request, dispatch],
  );

  const onApprovePayment = useCallback(() => {
    request?.cost &&
      dispatch(
        PaymentActions.patchCostState({
          id: request.cost.id,
          state: CostState.payable,
          requestId: request.id,
        }),
      );
  }, [request, dispatch]);

  const onsendRetributionMail = useCallback(() => {
    request && dispatch(RequestActions.sendRetributionMail(request.id));
  }, [request, dispatch]);

  return (
    <Visible visible={visible}>
      {request && (
        <PaymentsComponent
          title={
            <>
              <div className={C.title}>
                {translate('Requests.Detail.Payments.Title', {
                  dateUntil: moment(request.originalDateUntil || request.dateUntil).format(DateFormat.date),
                })}
                <Visible visible={showNewPaymentWarning}>
                  <Button.Green onClick={onApprovePayment}>
                    {translate('Requests.Detail.Payments.Approve')}
                  </Button.Green>
                </Visible>
              </div>
              <div className={C.newPayment}>
                <Visible visible={showNewPaymentWarning}>
                  <Card variant="outlined" className={G.warningPrimary}>
                    {showNewPaymentWarning &&
                      translate('Requests.Detail.Payments.NewPaymentAvailable', {
                        cost: formatCurrency(request.cost!.totalCost - request.totalToBePaid),
                      })}
                  </Card>
                </Visible>
              </div>
            </>
          }
          onPatchPaymentState={onPatchPaymentState}
          payments={request.payments || []}
          totalCost={request.cost?.totalCost}
          totalPaid={request.totalPaid}
          requestId={request.id}
          showEmailRetribution={request.showEmailRetribution}
          billingMailedAt={request.billingMailedAt}
          onsendRetributionMail={onsendRetributionMail}
        />
      )}
      {requestExtensions &&
        requestExtensions.map((requestExtension) => (
          <PaymentsComponent
            title={
              <div className={C.title}>
                {translate('Requests.Detail.Payments.Extensions', {
                  dateUntil: moment(requestExtension.dateUntil).format(DateFormat.date),
                  paymentStatus: requestExtension.state.name,
                })}
                <ExtensionActions
                  acl={requestExtension.acl}
                  onReject={onReject(requestExtension.id)}
                  onApprove={onApprove(requestExtension.id)}
                />
              </div>
            }
            reasonForApproval={requestExtension.reasonForApproval}
            reasonForRejection={requestExtension.reasonForRejection}
            onPatchPaymentState={onPatchPaymentState}
            payments={requestExtension.payments || []}
            totalCost={requestExtension.cost?.totalCost}
            totalPaid={requestExtension.totalPaid}
            requestId={request ? request.id : undefined}
          />
        ))}
    </Visible>
  );
};
