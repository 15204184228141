import { translate, translateIgnoreTS } from '../../common/translations/translate';
import * as React from 'react';
import { Popup } from 'react-leaflet';
import AsignButton from '../../common/components/buttons/asign-button';
import { Loader } from '../../common/components/layout/loader.component';
import { ISignMapMarker, ISignMapPopup } from '../types/signs-map';
import { appUrls } from '../../common/config/url.constants';

interface IProps {
  sign: ISignMapMarker;
  signPopup: ISignMapPopup | null;
  createWorOrderCallback: (sign: ISignMapMarker) => void;
  hideSignOnMapViewCallback: (sign: ISignMapMarker) => void;
}

const PopupItem: React.FunctionComponent<{ label: string; text: any; hideEmpty?: boolean }> = (props) => {
  if (!props.text && props.hideEmpty) {
    return null;
  }
  return (
    <>
      <strong>{props.label}:</strong>&nbsp;{props.text}
      <br />
    </>
  );
};

export const SignsMapMarkerPopupComponent: React.FunctionComponent<IProps> = (props: IProps) => {
  const createWorkOrder = () => {
    props.createWorOrderCallback(props.sign);
  };

  const hideSignOnMapView = () => {
    props.hideSignOnMapViewCallback(props.sign);
  };

  const renderPopupInfo = (signPopup: ISignMapPopup): JSX.Element => {
    const requestLink =
      signPopup && signPopup.request ? (
        <a target="_blank" rel="noreferrer" href={appUrls.requests.detail(signPopup.request.id, true)}>
          {signPopup.request!.refId}
        </a>
      ) : (
        ''
      );

    return (
      <>
        <PopupItem label={translate('Type')} text={translateIgnoreTS('SignMap.Types.' + signPopup.type)} />
        <PopupItem label={translate('Status')} text={translateIgnoreTS('SignStates.' + signPopup.state)} />
        <PopupItem label={translate('Location')} text={signPopup.address} hideEmpty={true} />
        <PopupItem label={translate('RequestID')} text={requestLink} hideEmpty={true} />
        <PopupItem label={translate('LastInfo')} text={signPopup.lastInfo} hideEmpty={true} />
        <PopupItem label={translate('LastTour')} text={signPopup.lastTour} hideEmpty={true} />
        <PopupItem label={translate('RetrieveAttempts')} text={signPopup.retrieveAttempts} hideEmpty={true} />
        {signPopup.acl.canCreateRetrieveOrder && (
          <>
            <br />
            <AsignButton color="primary" onClick={createWorkOrder}>
              {translate('GenerateRetrieveWorkOrderItem')}
            </AsignButton>
          </>
        )}
        {signPopup.acl.canHideOnMapView && (
          <>
            <br />
            <br />
            <AsignButton color="primary" onClick={hideSignOnMapView}>
              {translate('hideSignOnMapView')}
            </AsignButton>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Popup>
        <h3 className={'text-center'}>
          {translate('SignMap.PopupTitle')}&nbsp;
          <a target="_blank" rel="noreferrer" href={appUrls.signs.detail(props.sign.hash, true)}>
            {props.sign.hash}
          </a>
        </h3>
        {props.signPopup ? renderPopupInfo(props.signPopup) : <Loader />}
      </Popup>
    </>
  );
};
