import React, { ChangeEvent, FunctionComponent, MouseEvent, PropsWithChildren, useState } from 'react';
import { IFileSelect, IWithClassName, IWithStyle } from '../../../types';
import { Button } from '../buttons/asign-button-extensions';
import { acceptFileTypes, acceptFileTypesRegex, maxFileSizeMb } from '../../config/app.constants';
import ModalDialog from '../modal-dialog/modal-dialog.component';
import { translate } from '../../translations/translate';

export const FileSelectBase: FunctionComponent<IFileSelect> = ({ accept = acceptFileTypes, onFileSelect }) => {
  const [fileSizeErrorVisible, setFileSizeErrorVisible] = useState<boolean>(false);
  const [fileInValid, setFileInValid] = React.useState<boolean>(false);
  const openFileSizeError = () => setFileSizeErrorVisible(true);
  const closeFileSizeError = () => setFileSizeErrorVisible(false);

  const _onFileSelect = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (files && files.length > 0) {
      Array.from(files).forEach((file) => {
        if (file.size > maxFileSizeMb * 1024 * 1024) {
          openFileSizeError();
          return;
        }
        const metadata = {
          id: `${file.name}-${Math.random()}`,
          loading: true,
          name: file.name,
        };
        const isValid = file && acceptFileTypesRegex.test(file.name);
        setFileInValid(!isValid);
        if (isValid) onFileSelect && onFileSelect({ file, metadata });
      });
    }
  };

  const _onClick = ({ target }: MouseEvent<HTMLInputElement>) => {
    // This will make sure the value is reset before selecting a file. There was an issue that you can select the same file after each other.
    if (target) {
      // @ts-ignore
      target.value = null;
    }
  };

  return (
    <>
      <input accept={accept} aria-label="file-input" hidden onChange={_onFileSelect} onClick={_onClick} type="file" />
      <ModalDialog
        buttons={
          <Button.Blue onClick={closeFileSizeError}>
            <span>{translate('FileSelect.FileSizeError.Button')}</span>
          </Button.Blue>
        }
        onClose={closeFileSizeError}
        title={translate('FileSelect.FileSizeError.Title')}
        visible={fileSizeErrorVisible}
      >
        <span>{translate('FileSelect.FileSizeError.Content', { maxSize: maxFileSizeMb })}</span>
      </ModalDialog>
      <ModalDialog
        buttons={
          <Button.Blue onClick={() => setFileInValid(false)}>
            <span>{translate('FileSelect.FileTypeError.Button')}</span>
          </Button.Blue>
        }
        onClose={() => setFileInValid(false)}
        title={translate('FileSelect.FileTypeError.Title')}
        visible={fileInValid}
      >
        <span>{translate('FileSelect.FileTypeError.Content')}</span>
      </ModalDialog>
    </>
  );
};

export const FileSelect = {
  BlueButton: (({ children, className, disabled, style, ...props }) => (
    <Button.Blue
      disabled={disabled}
      className={className}
      component="label"
      dataTestId="FileInputButtonBlue"
      style={style}
    >
      <FileSelectBase {...props} />
      {children}
    </Button.Blue>
  )) as FunctionComponent<PropsWithChildren<IFileSelect & IWithClassName & IWithStyle>>,
};
