import * as React from 'react';
import { Container, FormattedDateTime, IPaging, ISorting, PanelAction } from '../../common';
import { bulkProcessEvent, EventsActions, fetchEventsFilterData, initEventOverview } from '../store/events.actions';
import { initialEventsState } from '..';
import { IEventsFilter } from '../types/events-filter';
import { Auth } from '../../components';
import { ISignsAclActions } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsList, getEventTypes, selectLoading, selectTable } from '../store/events.selectors';
import { useCallback, useEffect, useState } from 'react';
import { GenericTablePanel } from '../../common/components/table/GenericTablePanel.component';
import { IEvent } from '../types/event';
import { IModule } from '../types/module';
import { ISign } from '../types/sign';
import { translate } from '../../common/translations/translate';
import { ConfirmDialog } from '../../common/components/modal-dialog/confirm-dialog.component';
import { useToggle } from '../../hooks/useToggle.hook';
import { Edit } from '@material-ui/icons';
import { Filters } from '../components/events';
import { appUrls } from '../../common/config/url.constants';

export const EventsPage = () => {
  const dispatch = useDispatch();
  const { sorting, paging, filters } = useSelector(selectTable);
  const loading = useSelector(selectLoading);
  const events = useSelector(getEventsList);
  const types = useSelector(getEventTypes);

  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);
  const [isBulkProcessEventPopup, setBulkProcessEventPopup] = useState<boolean>(false);
  const closeBulkProcessEventPopup = useCallback(() => setBulkProcessEventPopup(false), []);
  const [isEditMode, toggleEditMode] = useToggle();

  useEffect(() => {
    dispatch(fetchEventsFilterData());
    dispatch(initEventOverview());
  }, [dispatch]);

  const onFilterChange = (filters: IEventsFilter): void => {
    dispatch(
      EventsActions.list.fetch({
        filters,
        paging: initialEventsState.list.table.paging,
      }),
    );
  };

  const onPagingChange = (paging: IPaging): void => {
    dispatch(
      EventsActions.list.fetch({
        paging,
      }),
    );
  };

  const onSortingChange = (sorting: ISorting): void => {
    dispatch(
      EventsActions.list.fetch({
        sorting,
      }),
    );
  };

  return (
    <Auth acl={ISignsAclActions.viewSignEvents} showUnauthorizedPage>
      <Container>
        <GenericTablePanel<IEvent>
          title={translate('OverviewDeviceEvents')}
          customHeader={
            <Auth acl={ISignsAclActions.editSignEventsBatch}>
              <PanelAction icon={<Edit />} onClick={toggleEditMode} />
            </Auth>
          }
          filterNode={<Filters eventsFilter={filters} eventsFilterChange={onFilterChange} types={types} />}
          columnKey={'id'}
          columns={[
            {
              label: translate('ModuleID'),
              sortKey: 'moduleId',
              name: 'sigfoxDevice',
              renderer: ({ id }: IModule) => (
                <a href={appUrls.signs.modules.detail(id, true)} target="_blank" rel="noreferrer">
                  {id}
                </a>
              ),
              sortable: true,
            },
            {
              label: translate('CurrentState'),
              sortKey: 'moduleState',
              name: 'sigfoxDevice',
              renderer: ({ state }: IModule) => state.name,
              sortable: true,
            },
            {
              label: translate('SignID'),
              sortKey: 'signId',
              name: 'sign',
              renderer: (sign?: ISign) =>
                sign ? (
                  <a href={appUrls.signs.detail(sign.hash, true)} target="_blank" rel="noreferrer">
                    {sign.hash}
                  </a>
                ) : (
                  '-'
                ),
              sortable: true,
            },
            {
              label: translate('CurrentState'),
              sortKey: 'signState',
              name: 'sign',
              renderer: (sign?: ISign) => sign?.state.name || '-',
              sortable: true,
            },
            {
              label: translate('Type'),
              name: 'type',
              renderer: (type) => type.name,
              sortable: true,
            },
            {
              label: translate('Description'),
              name: 'description',
              renderer: (description) => (description ? translate(description) : ''),
              sortable: true,
            },
            {
              label: translate('Detected'),
              name: 'detected',
              renderer: (detected?: string) => <FormattedDateTime dateTime={detected} />,
              sortable: true,
            },
            {
              label: translate('Processed'),
              name: 'processed',
              renderer: (processed?: boolean) => translate(processed ? 'yes' : 'no'),
              sortable: true,
            },
          ]}
          labelUnavailable={translate('Events.unavailable')}
          onChangeSorting={onSortingChange}
          onChangePaging={onPagingChange}
          paging={paging}
          sorting={sorting}
          loading={loading}
          records={events}
          bulkActions={
            isEditMode
              ? [
                  {
                    label: translate('bulkActions.processSelectedEvents'),
                    action: (events) => {
                      setSelectedEvents(events.map(({ id }) => id));
                      setBulkProcessEventPopup(true);
                    },
                  },
                ]
              : undefined
          }
        />
      </Container>
      <ConfirmDialog
        visible={isBulkProcessEventPopup}
        onClose={closeBulkProcessEventPopup}
        onConfirm={() => {
          setBulkProcessEventPopup(false);
          dispatch(bulkProcessEvent(selectedEvents));
          setSelectedEvents([]);
        }}
        title={translate('Events.Dialogs.BulkEventProcessTitle')}
      >
        {translate('Events.Dialogs.BulkEventProcessMessage', { count: selectedEvents.length })}
      </ConfirmDialog>
    </Auth>
  );
};
