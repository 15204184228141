import { Grid } from '@material-ui/core';
import { useAtom } from 'jotai';
import React, { ChangeEvent, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import ModalDialog from '../../../common/components/modal-dialog/modal-dialog.component';
import { translate } from '../../../common/translations/translate';
import { SgwRequestActions, SnackBarActions } from '../../../store/actions';
import { getAdvisingPartiesList } from '../../../store/selectors/sgwAdvisingParties.selectors';
import { getConflictGroupOptions } from '../../../store/selectors/sgwRequest.selectors';
import { ConflictCategory } from '../../../types';
import { atomWithHash } from 'jotai-location';

interface IProps {
  requestId: string;
}

export const editAtom = atomWithHash<boolean>('edit', false);

export const ManualRequestAdvice: FC<IProps> = ({ requestId }) => {
  const dispatch = useDispatch();
  const advisingParties = useSelector(getAdvisingPartiesList);
  const [advisingParty, setAdvisingParty] = useState<string>();
  const [conflictCategory, setConflictCategory] = useState<string>();
  const [editMode, setEditMode] = useAtom(editAtom);
  const conflictGroupOptions = useSelector(getConflictGroupOptions());

  const onConfirm = () => {
    if (advisingParty && conflictCategory) {
      dispatch(
        SgwRequestActions.conflicts.save({
          requestId: parseInt(requestId),
          conflictGroup: {
            sgwAdvisingPartyId: parseInt(advisingParty),
            conflictCategory: conflictCategory as ConflictCategory,
          },
        }),
      );
      onClose();
    } else {
      dispatch(SnackBarActions.setFailure(translate('sgw.requests.detail.manualRequestAdvice.inComplete')));
    }
  };
  const onClose = () => {
    setEditMode((prevState) => !prevState.valueOf());
    resetForm();
  };

  const onChangeAdvisingParty = (e: ChangeEvent<{ value: string }>) => {
    setAdvisingParty(e.target.value);
  };
  const onChangeConflictCategory = (e: ChangeEvent<{ value: string }>) => {
    setConflictCategory(e.target.value);
  };
  const resetForm = () => {
    setConflictCategory('');
    setAdvisingParty('');
  };

  return (
    <ModalDialog
      maxWidth="md"
      onClose={onClose}
      title={translate('sgw.requests.detail.manualRequestAdvice.modalTitle')}
      visible={editMode}
      okButtonText={translate('sgw.requests.detail.manualRequestAdvice.confirmButton')}
      onConfirm={onConfirm}
    >
      <Grid container spacing={2}>
        <LabelValue label={translate('sgw.requests.detail.manualRequestAdvice.selecConflictCategory')}>
          <SingleSelectV2Component
            placeholder={translate('sgw.requests.detail.manualRequestAdvice.placeHolder')}
            onChange={onChangeConflictCategory}
            value={conflictCategory}
            menuItems={conflictGroupOptions}
          />
        </LabelValue>
        <LabelValue label={translate('sgw.requests.detail.manualRequestAdvice.selectAdvisingParty')}>
          <SingleSelectV2Component
            placeholder={translate('sgw.requests.detail.manualRequestAdvice.placeHolder')}
            onChange={onChangeAdvisingParty}
            value={advisingParty}
            menuItems={advisingParties.map((advisingParty) => ({
              value: `${advisingParty.id}`,
              label: advisingParty.name,
            }))}
          />
        </LabelValue>
      </Grid>
    </ModalDialog>
  );
};
