import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  StyledComponentProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { ExpandMore, Warning } from '@material-ui/icons';
import { translate } from '../../common/translations/translate';
import * as React from 'react';
import { COLORS, FormattedAddress, FormattedDateTime, IPaging, Paging, Panel, WithStyles } from '../../common';
import { SIGFOX_MESSAGE_TYPE } from '../signs.constants';
import { ISignData } from '../types/sign-data';
import { ISignLocation } from '../types/sign-location';
import { appUrls } from '../../common/config/url.constants';

interface IProps extends StyledComponentProps {
  signActiveLocation: ISignLocation | null;
  signActiveLocationChange: (signLocation: ISignLocation) => void;
  signActiveLocationData: ISignData[];
  signLocations: ISignLocation[];
  signLocationsPaging: IPaging;
  signLocationsPagingChange: (paging: IPaging) => void;
}

const styles = () => ({
  expansionPanel: {
    '&::before': {
      display: 'none',
    },
    borderRadius: 0,
    borderTop: `solid 1px ${COLORS.BACKGROUND}`,
    boxShadow: 'none',
  },
  paging: {
    borderTop: `solid 1px ${COLORS.BACKGROUND}`,
    padding: 15,
  },
});

@WithStyles(styles)
export class SignLocationHistory extends React.Component<IProps> {
  public render(): React.ReactNode {
    const activeSignLocationId = this.props.signActiveLocation ? this.props.signActiveLocation.id : 0;
    return this.props.signLocations.length ? (
      <Panel title={translate('LinkedLocationHistory')}>
        <div style={{ margin: -15 }}>
          {this.props.signLocations.map((location: ISignLocation) =>
            this.renderLocation(activeSignLocationId, location),
          )}
          {this.props.signLocationsPaging.totalPages > 1 && (
            <div className={this.props.classes!.paging}>
              <Paging pageChange={this.props.signLocationsPagingChange} {...this.props.signLocationsPaging} />
            </div>
          )}
        </div>
      </Panel>
    ) : null;
  }

  private renderLocation(activeSignLocationId: number, location: ISignLocation): React.ReactNode {
    return (
      <ExpansionPanel
        key={location.id + activeSignLocationId}
        expanded={activeSignLocationId === location.id}
        className={this.props.classes!.expansionPanel}
      >
        {this.renderLocationHeader(location)}
        {this.renderLocationDetails(location)}
      </ExpansionPanel>
    );
  }

  private renderLocationHeader(location: ISignLocation): React.ReactNode {
    return (
      <ExpansionPanelSummary expandIcon={<ExpandMore />} onClick={this.createOnClickLocation(location)}>
        <Grid container={true} alignItems="center" justify="space-between">
          <Grid item={true}>
            <FormattedAddress address={location.parkingBanIntake} inline={true} />
          </Grid>
          {location.damaged && (
            <Grid item={true}>
              <Tooltip title={translate('SignMarkedAsDamagedAtLocation')}>
                <Warning style={{ fill: COLORS.DANGER }} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </ExpansionPanelSummary>
    );
  }

  private renderLocationDetails(location: ISignLocation): React.ReactNode {
    return (
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <dl>
            <dt>{translate('RequestID')}</dt>
            <dd>
              <a href={appUrls.requests.detail(location.parkingBanIntake.permitRequestId, true)} target="_parent">
                {location.parkingBanIntake.permitRequestId}
              </a>
            </dd>
            <dt>{translate('PlacedAt')}</dt>
            <dd>
              <FormattedDateTime dateTime={location.placedAt} />
            </dd>
            {location.pickedUpAt && (
              <>
                <dt>{translate('PickedUpAt')}</dt>
                <dd>
                  <FormattedDateTime dateTime={location.pickedUpAt} />
                </dd>
              </>
            )}
          </dl>
          {this.renderLocationData()}
        </div>
      </ExpansionPanelDetails>
    );
  }

  private renderLocationData(): React.ReactNode {
    return (
      this.props.signActiveLocationData &&
      this.props.signActiveLocationData.length > 0 && (
        <div style={{ marginTop: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <strong>{translate('DeviceData')}</strong>
          </div>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>{translate('SignProfile')}</TableCell>
                <TableCell>{translate('GPSSignal')}</TableCell>
                <TableCell>{translate('ApplicationVersion')}</TableCell>
                <TableCell>{translate('Timestamp')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.signActiveLocationData.map((data: ISignData) => (
                <TableRow key={data.createdAt}>
                  <TableCell>{data.profile.name}</TableCell>
                  <TableCell>{this.getNoGpsValue(data)}</TableCell>
                  <TableCell>{data.applicationVersion}</TableCell>
                  <TableCell>
                    <FormattedDateTime dateTime={data.createdAt} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )
    );
  }

  private getNoGpsValue(data: ISignData) {
    if (data.type === SIGFOX_MESSAGE_TYPE.STATUS_LOCATION) {
      return data.locationData.noGPS ? translate('NoConnection') : translate('ok');
    }

    return '-';
  }

  private createOnClickLocation = (location: ISignLocation) => (): void => {
    this.props.signActiveLocationChange(location);
  };
}
