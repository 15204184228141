import { createReducer } from '@reduxjs/toolkit';
import { payloadReducer } from '../../..';
import { ICarFreeZone } from '../../../../types';
import { CarFreeZonesActions } from '../carFreeZones.actions';

type TState = ICarFreeZone[];

export default createReducer<TState>([], (builder) => {
  builder.addCase(CarFreeZonesActions.setAll.type, payloadReducer<TState>());
});
