import { Button, makeStyles } from '@material-ui/core';
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@material-ui/icons';
import * as React from 'react';
import { IPaging } from '../..';
import { FC, useCallback } from 'react';
import { Visible } from '../layout/Visible.component';
import classNames from 'classnames';
import { translate } from '../../translations/translate';

interface IProps extends IPaging {
  pageChange?(paging: IPaging): void;
}

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    marginLeft: '20px',
    marginRight: '10px',
    textAlign: 'center',
  },
  button: { minWidth: 40, padding: 5 },
  activeButton: { fontWeight: 'bold' },
});

export const Paging: FC<IProps> = ({ totalPages, totalRecords, pageChange, pageSize, page }) => {
  const C = useStyles();

  const generatePages = useCallback((startPosition: number, endPosition: number) => {
    const localPages: number[] = [];
    for (let page = startPosition; page <= endPosition; page++) {
      localPages.push(page);
    }
    return localPages;
  }, []);

  const createOnPageClick = useCallback(
    (page: number) => () => {
      if (page > 0 && page <= totalPages) {
        pageChange?.({
          page,
          pageSize,
          totalPages,
          totalRecords,
        });
      }
    },
    [pageSize, totalPages, totalRecords, pageChange],
  );

  const getPages = useCallback(() => {
    if (totalPages > 10) {
      if (page < 6) {
        return [...generatePages(1, 7), -1, ...generatePages(totalPages - 1, totalPages)];
      } else if (page > totalPages - 6) {
        return [...generatePages(1, 2), -1, ...generatePages(totalPages - 6, totalPages)];
      } else {
        return [
          ...generatePages(1, 2),
          -1,
          ...generatePages(page - 2, page + 2),
          -2,
          ...generatePages(totalPages - 1, totalPages),
        ];
      }
    } else {
      return generatePages(1, totalPages);
    }
  }, [totalPages, generatePages, page]);

  return (
    <Visible visible={!!totalPages}>
      <div className={C.root} data-testid="paging">
        <div />
        <div>
          <Button onClick={createOnPageClick(1)} className={C.button}>
            <FirstPage />
          </Button>
          <Button onClick={createOnPageClick(page - 1)} className={C.button}>
            <ChevronLeft />
          </Button>
          {getPages().map((p) => (
            <Button
              key={p}
              onClick={createOnPageClick(p)}
              className={classNames(C.button, page === p ? C.activeButton : undefined)}
              color={page === p ? 'primary' : 'default'}
            >
              {p > 0 ? p : '...'}
            </Button>
          ))}
          <Button onClick={createOnPageClick(page + 1)} className={C.button}>
            <ChevronRight />
          </Button>
          <Button onClick={createOnPageClick(totalPages)} className={C.button}>
            <LastPage />
          </Button>
        </div>
        <div>{translate(totalRecords === 1 ? 'Result' : 'Result_plural', { count: totalRecords })}</div>
      </div>
    </Visible>
  );
};
