import { translate } from '../../common/translations/translate';
import * as L from 'leaflet';
import { GeoJSON } from 'react-leaflet';
import * as React from 'react';
import { BaseMap, Connect } from '../../common';
import { initCpa } from '../store/cpa.actions';
import { IParkingBanFeature } from '../types/parking-ban-feature';
import { HEADER_HEIGHT } from '../../components/organisms/MainMenu/MainMenu.styles';

interface IProps {
  initCpa?: typeof initCpa;
  parkingBans?: IParkingBanFeature[];
  parkingBansLoading?: boolean;
}

@Connect(
  ({ cpa }) => ({
    parkingBans: cpa.parkingBans,
    parkingBansLoading: cpa.parkingBansLoading,
  }),
  {
    initCpa,
  },
)
export class CpaPage extends React.Component<IProps> {
  public componentDidMount(): void {
    this.props.initCpa!();
  }

  public render(): React.ReactNode {
    return <BaseMap height={'calc(100vh - ' + HEADER_HEIGHT + 'px)'}>{this.renderParkingBans()}</BaseMap>;
  }

  private renderParkingBans(): React.ReactNode {
    return (
      !this.props.parkingBansLoading && (
        <GeoJSON
          // @ts-ignore
          data={{ type: 'FeatureCollection', features: this.props.parkingBans || [] }}
          options={{
            onEachFeature: this.onEachFeature,
          }}
        />
      )
    );
  }

  private onEachFeature = (feature: IParkingBanFeature, layer: L.Layer): void => {
    let message = '<div>';
    message += `<h2>${feature.properties.address}</h2>`;
    message += `<strong>${translate('Period')}:</strong>`;
    message += `<div>${feature.properties.dateFrom} - ${feature.properties.dateUntil}</div>`;
    if (feature.properties.entireDay) {
      message += `<div>${translate('RequestLastsAllDay')}</div>`;
    } else {
      message += `<div>${feature.properties.timeFrom} - ${feature.properties.timeUntil}</div>`;
    }
    if (feature.properties.onlyOnWeekdays) {
      message += `<div>${translate('RequestOnlyOnWeekdays')}</div>`;
    }
    message += `<strong>${translate('Reference')}:</strong>`;
    message += `<div>${feature.properties.referenceId}</div>`;
    message += `<strong>${translate('Reason')}:</strong>`;
    message += `<div>${this.getReason(feature)}</div>`;
    message += '</div>';
    layer.bindPopup(message);
  };

  private getReason = (parkingBan: IParkingBanFeature): string => {
    switch (parkingBan.properties.reason.reason) {
      case 'city_event':
      case 'event':
        return translate('RequestReason.Event');
      case 'city_filming':
      case 'construction_zone':
        return parkingBan.properties.reason.name;
      case 'utility_request':
        return parkingBan.properties.reason.name + '-' + parkingBan.properties.reason.externalReason;
      default:
        return translate('RequestReason.Private');
    }
  };
}
