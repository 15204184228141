import { translate } from '../../../../common/translations/translate';
import { IService, VehicleSource } from '../../../../types';

export const serviceFormManagement = {
  getInitialValues: (service: Partial<IService>) =>
    ({
      ...service,
      name: service?.name || '',
      vehicleSource: service?.vehicleSource || '',
      aptrAreaCode: service?.aptrAreaCode || '',
    }) as IService,
  requestValidateValues: async (values: IService, existingNames: string[], initialName?: string) => {
    const errors: Partial<IService> = {};
    const requiredFields: (keyof IService)[] = ['name'];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        // @ts-ignore
        errors[field] = translate('services.form.required');
      }
    });

    if (!values.vehicleSource) {
      // @ts-ignore
      errors.vehicleSource = translate('services.form.required');
    }

    if (values.vehicleSource === VehicleSource.aptr) {
      if (!values.aptrAreaCode) {
        errors.aptrAreaCode = translate('services.form.required');
      }

      if (!values.aptrC3UsageCode && !values.aptrF3UsageCode && !values.aptrVpUsageCode) {
        // @ts-ignore
        errors['aptrUsageCode'] = translate('services.form.Required');
      }
    }

    if (existingNames.includes(values.name.toLowerCase()) && values.name.toLowerCase() !== initialName?.toLowerCase()) {
      errors.name = translate('services.form.nameExists');
    }
    return errors;
  },
};
