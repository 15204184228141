import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { translate } from '../../../common/translations/translate';
import * as React from 'react';

export const RetrieveButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button variant="contained" color="secondary" onClick={onClick}>
      <Add />
      {translate('GenerateRetrieveWorkOrderItem')}
    </Button>
  );
};
