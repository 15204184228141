import { createReducer } from '@reduxjs/toolkit';
import { payloadReducer } from '../../../../common';
import { IRequestsPermitHistory } from '../../../../types';
import { RequestActions } from '../../../actions';

type TState = IRequestsPermitHistory[];

const initialState: TState = [];

export default createReducer<TState>(initialState, (builder) => {
  builder.addCase(RequestActions.setPermitHistory.type, payloadReducer<TState>(initialState));
});
