import { createReducer } from '@reduxjs/toolkit';
import { extractIdsFromArray } from '../../../../utils/normalized.util';
import { ServicesActions } from '../../Services.actions';

type TState = string[];

export default createReducer<TState>([], (builder) => {
  builder.addCase(
    ServicesActions.setContactList.type,
    (_, { payload }: ReturnType<typeof ServicesActions.setContactList>) => {
      return extractIdsFromArray(payload);
    },
  );
});
