import { translate } from '../../common/translations/translate';
import * as L from 'leaflet';

export function LeafletDrawTranslation() {
  // @ts-ignore
  const drawLocal: any = L.drawLocal;
  drawLocal.draw.handlers.circle.tooltip.start = translate('ClickAndDragToDrawCircle');
  drawLocal.draw.handlers.rectangle.tooltip.start = translate('ClickAndDragToDrawRectangle');
  drawLocal.draw.handlers.simpleshape.tooltip.end = translate('FinishDrawing');
  drawLocal.draw.toolbar.actions.text = translate('Cancel');
}
