import { makeStyles } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import MailIcon from '@material-ui/icons/Mail';
import RefreshIcon from '@material-ui/icons/Refresh';
import { translate } from '../../../common/translations/translate';
import * as React from 'react';
import { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Unavailable } from '../../../common/components/formatters/unavailable';
import { ConfirmDialog } from '../../../common/components/modal-dialog/confirm-dialog.component';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { Auth } from '../../../components/atoms';
import { RequestActions } from '../../../store/actions';
import { IPermit, IRequestAclActions } from '../../../types';

interface IProps {
  permit?: IPermit;
  requestId: number;
  canSendEmail: boolean;
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 20,
  },
  withMarginTop: {
    marginTop: 5,
  },
});

export const PermitButtons: FunctionComponent<IProps> = ({ requestId, permit, canSendEmail }) => {
  const C = useStyles();
  const [confirmSendMail, setConfirmSendMail] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onConfirm = useCallback(() => {
    dispatch(RequestActions.sendApprovedMail(requestId));
    setConfirmSendMail(false);
  }, [dispatch, requestId]);

  const onClose = useCallback(() => {
    setConfirmSendMail(false);
  }, []);

  const onPermitRegenerate = useCallback(() => {
    dispatch(RequestActions.regeneratePermit(requestId));
  }, [dispatch, requestId]);

  return permit && !permit.error ? (
    <>
      <PanelContent title={translate('Requests.Detail.Permit.Title')}>
        {permit.pending ? (
          <>
            <div>
              <Unavailable text={translate('Requests.Detail.Permit.Regenerate.Description')} />
            </div>
            <div className={`${C.buttonContainer} ${C.withMarginTop}`}>
              <Auth acl={IRequestAclActions.editAssignedRequest}>
                <Button.Green disabled={!permit.longPending} onClick={onPermitRegenerate}>
                  <RefreshIcon />
                  <span style={{ marginLeft: '5px' }}>{translate('Requests.Detail.Permit.Regenerate.Button')}</span>
                </Button.Green>
              </Auth>
            </div>
          </>
        ) : (
          <div className={C.buttonContainer}>
            <Button.Blue
              className={C.button}
              data-testid="emailButton"
              onClick={() => setConfirmSendMail(true)}
              visible={canSendEmail}
            >
              <MailIcon />
              <span style={{ marginLeft: '5px' }}>{translate('Requests.Detail.Permit.Email.Button')}</span>
            </Button.Blue>
            <Button.Blue
              className={C.button}
              data-testid="downloadButton"
              onClick={() => window.open(permit.downloadUrl, '_blank')}
            >
              <CloudDownload />
              <span style={{ marginLeft: '5px' }}>{translate('Requests.Detail.Permit.Download.Button')}</span>
            </Button.Blue>
          </div>
        )}
      </PanelContent>
      <ConfirmDialog
        visible={confirmSendMail}
        onClose={onClose}
        onConfirm={onConfirm}
        title={translate('Requests.Detail.Permit.Email.Title')}
        okButtonText={translate('Requests.Detail.Permit.Email.Confirm')}
      >
        {translate('Requests.Detail.Permit.Email.Description')}
      </ConfirmDialog>
    </>
  ) : null;
};
