import { Button } from '@material-ui/core';
import { translate } from '../../../common/translations/translate';
import * as React from 'react';

export const SubmitButton: React.FC = () => {
  return (
    <Button variant="contained" color="primary" type="submit">
      {translate('SaveChanges')}
    </Button>
  );
};
