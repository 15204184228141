import React, { FC, useEffect, useState } from 'react';
import { translate } from '../../../common/translations/translate';
import { useDispatch, useSelector } from 'react-redux';
import { atomWithHash } from 'jotai-location';
import { useAtom } from 'jotai';
import { ATOM_HASHES } from '../../../common/config/atom.constants';
import { SgwPermitConditionsActions, SgwRequestActions } from '../../../store/actions';
import { IRouterWithId, ISgwRequestDetailAclActions, SgwPermitConditionCategory } from '../../../types';
import { PermitConditionsDialog, SelectedPermitConditionCategory } from '../../molecules';
import { useParams } from 'react-router';
import { getSgwRequestPermitConditionIds } from '../../../store/selectors/sgwRequest.selectors';
import { getValuesOfEnum } from '../../../utils';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { Paper } from '@material-ui/core';
import { PanelTitle } from '../../atoms';
import { useGeneralStyles } from '../../../style/generalStyles';
import { Visible } from '../../../common/components/layout/Visible.component';
import { Unavailable } from '../../../common/components/formatters/unavailable';
import { createUseStyles } from 'react-jss';
import { useAuthorization, useRequestAuthorization } from '../../../hooks';
import classNames from 'classnames';
import { selectSgwPermitConditionsTable } from '../../../store/selectors/sgwPermitConditions.selectors';

const editModeAtom = atomWithHash(ATOM_HASHES.permitConditions.edit, false);
const allPermitConditionCategories = getValuesOfEnum<SgwPermitConditionCategory>(SgwPermitConditionCategory);

const useStyles = createUseStyles({
  permitConditions: {
    maxHeight: '500px',
    overflowY: 'scroll',
  },
});

export const RequestPermitConditions: FC = () => {
  const dispatch = useDispatch();
  const G = useGeneralStyles();
  const C = useStyles();
  const { id } = useParams<IRouterWithId>();
  const [editMode, setEditMode] = useAtom(editModeAtom);
  const ids = useSelector(getSgwRequestPermitConditionIds);
  const { paging } = useSelector(selectSgwPermitConditionsTable);

  const [selectedIds, setSelectedIds] = useState<number[]>(ids);
  const { isAuthorized: canEdit } = useRequestAuthorization(ISgwRequestDetailAclActions.editSgwPermitConditions);
  const { isAuthorized: viewAttachment } = useAuthorization(ISgwRequestDetailAclActions.viewSgwAttachmentPanel);

  useEffect(() => {
    setSelectedIds(ids);
  }, [ids]);

  useEffect(() => {
    dispatch(SgwPermitConditionsActions.list.fetch({ paging: { ...paging, pageSize: 1000 } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setEditMode]);

  const onConfirm = () => {
    dispatch(SgwRequestActions.patch({ id: Number(id), permitConditionIds: selectedIds }));
    setEditMode(false);
  };

  const onClose = () => {
    setSelectedIds(ids);
    setEditMode(false);
  };

  return (
    <>
      <Paper square className={classNames(viewAttachment && G.marginTop20)}>
        <PanelContent
          title={
            <PanelTitle onEdit={canEdit ? () => setEditMode(true) : undefined}>
              {translate('sgw.requests.detail.permitConditions.title')}
            </PanelTitle>
          }
        >
          <div className={C.permitConditions}>
            <Visible
              visible={!!ids.length}
              defaultComponent={<Unavailable text={translate('sgw.requests.detail.permitConditions.noneAvailable')} />}
            >
              {allPermitConditionCategories.map((category) => (
                <SelectedPermitConditionCategory key={category} category={category} />
              ))}
            </Visible>
          </div>
        </PanelContent>
      </Paper>
      <PermitConditionsDialog
        editMode={editMode && canEdit}
        categories={allPermitConditionCategories}
        onClose={onClose}
        onConfirm={onConfirm}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />
    </>
  );
};
