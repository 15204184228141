import { Grid } from '@material-ui/core';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { translate } from '../../../common/translations/translate';
import { formatDateTimeToDisplayDate } from '../../../common/utils/date.util';
import { LabelList } from '../../atoms/LabelList/LabelList.component';
import React, { FC } from 'react';
import { ISgwPhase } from '../../../types';
import { useSelector } from 'react-redux';
import { getNatureOfWorksById, getDistinctTrafficMeasuresForPhase } from '../../../store/selectors';
import { usePhaseDetailStyles } from '../../../style/phaseDetails.styles';
import { useGeneralStyles } from '../../../style/generalStyles';
import { TitleValue } from '../../atoms';
import { Visible } from '../../../common/components/layout/Visible.component';
import { appUrls } from '../../../common/config/url.constants';

export const PhaseDetailsContent: FC<ISgwPhase> = ({
  phaseName,
  dateFrom,
  dateUntil,
  timeFrom,
  timeUntil,
  workingHoursFrom,
  workingHoursUntil,
  workingHoursNotStandard,
  addresses,
  locationDescription,
  trafficMeasureClarification,
  sgwNatureOfWorks,
  sgwGeoDrawings,
  id,
  isRecurringPhaseTemplate,
  permitRequestId,
}) => {
  const C = usePhaseDetailStyles();
  const G = useGeneralStyles();
  const natureOfWorks = useSelector(getNatureOfWorksById(sgwNatureOfWorks));
  const distinctTrafficMeasures = useSelector(getDistinctTrafficMeasuresForPhase(id));

  return (
    <Grid container className={C.content}>
      <Grid container item xs={6} spacing={2} className={C.leftPanel}>
        <LabelValue visible={!!id} label={translate('sgw.requests.detail.phases.generalInfo.id')}>
          {id}
        </LabelValue>
        <LabelValue visible={!!phaseName} label={translate('sgw.requests.detail.phases.generalInfo.phaseName')}>
          {phaseName}
        </LabelValue>
        <Visible visible={!isRecurringPhaseTemplate}>
          <LabelValue label={translate('sgw.requests.detail.phases.generalInfo.phaseStart')}>
            {formatDateTimeToDisplayDate(dateFrom, timeFrom)}
          </LabelValue>
          <LabelValue label={translate('sgw.requests.detail.phases.generalInfo.phaseEnd')}>
            {formatDateTimeToDisplayDate(dateUntil, timeUntil)}
          </LabelValue>
          <LabelValue
            label={translate('sgw.requests.detail.phases.generalInfo.workingHours')}
          >{`${workingHoursFrom} - ${workingHoursUntil}`}</LabelValue>
          <LabelValue
            visible={!!workingHoursNotStandard}
            label={translate('sgw.requests.detail.phases.generalInfo.workingHoursNotStandard')}
          >
            {translate('yes')}
          </LabelValue>
          <LabelValue
            visible={!!permitRequestId}
            label={translate('sgw.requests.detail.phases.generalInfo.asignDossier')}
          >
            <a href={`${appUrls.requests.base}/${permitRequestId}`}>{permitRequestId}</a>
          </LabelValue>
        </Visible>

        <LabelList
          className={C.table}
          label={translate('sgw.requests.detail.phases.generalInfo.streetNames')}
          rows={addresses?.map(({ streetName, streetNumberFrom, streetNumberTo }) => [
            streetName || '',
            streetNumberFrom || '',
            streetNumberTo || '',
          ])}
        />

        <TitleValue
          title={translate('sgw.requests.detail.phases.generalInfo.locationDescription')}
          className={C.titleValue}
        >
          {locationDescription}
        </TitleValue>
      </Grid>
      <div className={G.flexColumn}>
        <TitleValue title={translate('sgw.requests.detail.phases.generalInfo.natureOfWorks')} className={C.titleValues}>
          {natureOfWorks.map((item, index) => (
            <div key={`${item}-${index}`}>{item}</div>
          ))}
        </TitleValue>
        <TitleValue
          title={translate('sgw.requests.detail.phases.generalInfo.trafficMeasures')}
          className={C.titleValues}
        >
          <ul>{distinctTrafficMeasures?.map((trafficMeasure) => <li>{trafficMeasure?.name}</li>)}</ul>
        </TitleValue>

        <TitleValue
          title={translate('sgw.requests.detail.phases.generalInfo.trafficMeasureClarification')}
          className={C.titleValues}
        >
          {trafficMeasureClarification}
        </TitleValue>
      </div>
    </Grid>
  );
};
