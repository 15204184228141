import { Reducer } from 'redux';
import { IById, INormalizedState, IWithId, normalizeArray, toNormalizedState } from '../../utils/normalized.util';
import { TPayloadAction } from './generic.actions';
import { createReducer, Draft, PayloadAction } from '@reduxjs/toolkit';

export type TReducer<TState> = Reducer<TState, TPayloadAction<TState>>;
export type TNormalizedReducer<TState> = Reducer<INormalizedState<TState>, TPayloadAction<TState[]>>;

export function generatePrimitiveReducer<TState>(type: string, initialState: TState): TReducer<TState> {
  return (state: TState = initialState, action: TPayloadAction<TState>): TState =>
    action.type === type ? action.payload : state;
}

export function generateObjectReducer<TState>(type: string, initialState: TState): TReducer<TState> {
  return (state: TState = initialState, action: TPayloadAction<TState>): TState => {
    if (action.type === type) {
      return action.payload ? { ...(action.payload as any) } : null;
    }
    return state;
  };
}

export function generateArrayReducer<TState>(type: string, initialState: TState[]): TReducer<TState[]> {
  return (state: TState[] = initialState, action: TPayloadAction<TState[]>): TState[] =>
    action.type === type ? [...action.payload] : state;
}

export function generateNormalizedObjectReducer<TState>(
  type: string,
  initialState: INormalizedState<TState>,
): TNormalizedReducer<TState> {
  return (
    state: INormalizedState<TState> = initialState,
    action: TPayloadAction<TState[]>,
  ): INormalizedState<TState> => {
    if (action.type === type) {
      return action.payload ? toNormalizedState<TState>(action.payload as Array<IWithId<TState>>) : state;
    }
    return state;
  };
}

/**
 * This payload reducer will save the payload in redux store. If initialState is given it will save initialState in redux store
 * if payload is falsy
 * @param initialState
 */
export const payloadReducer =
  <T>(initialState?: T) =>
  (state: T, { payload }: PayloadAction<T>): T =>
    !payload && initialState ? initialState : payload;

/**
 * This payload reducer will save the payload in redux store by its id.
 */
export const payloadReducerById =
  <T>() =>
  (state: IById<T>, { payload }: PayloadAction<IWithId<T>>): IById<T> =>
    payload?.id ? { ...state, [payload.id]: payload } : state;

/**
 * This payload reducer will save the payload array in normalized state in the redux store.
 */
export const payloadReducerArrayById =
  <T>() =>
  (state: IById<T>, { payload }: PayloadAction<IWithId<T>[]>): IById<T> => ({
    ...state,
    ...normalizeArray<T>(payload),
  });

/**
 * This will improve speed to add just payload reducers
 * @param actionTypes The action types that can be used for this reducer
 * @param initialState
 */
export const generatePayloadReducer = <T>(actionTypes: string[] = [], initialState: T) =>
  createReducer<T>(initialState, (builder) => {
    actionTypes.forEach((type) => builder.addCase(type, payloadReducer<Draft<T>>()));
  });

/**
 * This will improve speed to add just payload reducers
 * @param actionTypesForObjects The action types that can be used for this reducer for setting 1 object
 * @param actionTypesForArrays The action types that can be used for this reducer for setting 1 array of objects
 * @param initialState
 */
export const generatePayloadReducerById = <T>(
  actionTypesForObjects: string[] = [],
  actionTypesForArrays: string[] = [],
  initialState: IById<T> = {},
) =>
  createReducer<IById<T>>(initialState, (builder) => {
    // builder.addCase()
    actionTypesForObjects.forEach((type) => builder.addCase(type, payloadReducerById<Draft<T>>()));
    actionTypesForArrays.forEach((type) => builder.addCase(type, payloadReducerArrayById<Draft<T>>()));
  });

/**
 * This will improve speed to add boolean reducers like saving and loading ones
 * @param actionTypes The action types that can be used for this reducer
 * @param initialState
 */
export const generateBooleanReducer = (actionTypes: string[] = [], initialState: boolean = false) =>
  generatePayloadReducer<boolean>(actionTypes, initialState);
