import { IFormikOption } from '../../types';
import { translateIgnoreTS } from '../../common/translations/translate';
import { antwerpZipCodes } from '../config/app.constants';

export const getZipcodesAsFormikOptions = (): IFormikOption[] =>
  antwerpZipCodes.map((value) => ({
    label: `${value} - ${getCity(value)}`,
    value,
  }));

export const getCity = (zipCode: number): string => translateIgnoreTS(`Cities.${zipCode}`);
