import { Button } from '@material-ui/core';
import { translate } from '../../../common/translations/translate';
import * as React from 'react';

export const DeleteButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      {translate('DeleteSign')}
    </Button>
  );
};
