import { makeStyles } from '@material-ui/core/styles';
import { ZoomOutMapOutlined } from '@material-ui/icons';
import { translate } from '../../translations/translate';
import React, { FunctionComponent, useCallback } from 'react';
import { COLORS } from '../..';
import { IBaseMapOverviewLayer } from '../../../types';
import { getBoundsOfGeometries } from '../../utils/geojson.util';
import { useBaseMap } from './BaseMap.context';
import { BaseMapControl } from './BaseMapControl.component';
import { GeometryComponent } from './Geometry.component';

const useStyles = makeStyles({
  control: {
    right: 70,
    top: 10,
  },
});

export const BaseMapOverviewLayer: FunctionComponent<IBaseMapOverviewLayer> = ({
  bounds,
  geometries = [],
  onClick,
  showLayer = true,
}) => {
  const C = useStyles();
  const { flyToBounds } = useBaseMap();

  const _onClick = useCallback(() => {
    onClick?.();
    flyToBounds(bounds || getBoundsOfGeometries(geometries));
  }, [bounds, flyToBounds, geometries, onClick]);

  return (
    <>
      <BaseMapControl
        className={C.control}
        data-testid="BaseMapOverviewLayerControl"
        onClick={_onClick}
        tooltip={translate('Requests.Create.ShowAllLocations')}
      >
        <ZoomOutMapOutlined fontSize={'large'} />
      </BaseMapControl>
      {geometries.map((geometry) => (
        <GeometryComponent geometry={geometry} pathOptions={{ color: COLORS.GREY_LIGHT }} />
      ))}
    </>
  );
};
