import { Chip, Grid, Typography } from '@material-ui/core';
import { translate } from '../../common/translations/translate';
import { LatLng } from 'leaflet';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { BaseMapWithMarker, Connect, Container, Panel, WithRouter } from '../../common';
import { Header } from '../../common/components/layout/Header.component';
import { Loader } from '../../common/components/layout/loader.component';
import { Viod } from '../../common/components/viod/viod.component';
import { appUrls } from '../../common/config/url.constants';
import { IUser } from '../../common/types/user';
import NotificationActionsComponent from '../components/notification-actions.component';
import { NotificationAttachments } from '../components/notification-attachments-component';
import { NotificationGeneral } from '../components/notification-general.component';
import { NotificationHistory } from '../components/NotificationHistory.component';
import { getNotificationStates } from '../notification.constants';
import { INotification, INotificationHistoryItem } from '../types/notification';
import { Auth } from '../../components';
import { INotificationAclActions } from '../../types';
import { NotificationActions } from '../../store/actions/notifications.actions';

interface IProps
  extends Partial<
    RouteComponentProps<{
      id: string;
    }>
  > {
  fetchNotification?: typeof NotificationActions.fetch;
  patchNotification?: typeof NotificationActions.patch;
  notification?: INotification;
  notificationHistory?: INotificationHistoryItem[];
  notificationsLoading: boolean;
  user: IUser | null;
}

@WithRouter
@Connect(
  ({ notifications, user }) => ({
    notification: notifications.notification,
    notificationsLoading: notifications.loading,
    user: user.user,
  }),
  {
    fetchNotification: NotificationActions.fetch,
    patchNotification: NotificationActions.patch,
  },
)
export default class NotificationDetailPage extends React.Component<IProps> {
  public componentDidMount(): void {
    const { id } = this.props.match!.params;
    this.props.fetchNotification!(id);
  }

  public render(): React.ReactNode {
    if (this.props.notificationsLoading) {
      return (
        <Panel>
          <Loader />
        </Panel>
      );
    }

    const attachments =
      this.props.notification && this.props.notification.attachments ? this.props.notification.attachments : [];

    return (
      <Auth acl={INotificationAclActions.viewNotification} showUnauthorizedPage>
        {!this.props.notification ? (
          <> {translate('Notifications.NotFound')} </>
        ) : (
          <>
            <Header
              backButtonUrl={appUrls.notifications.base}
              extraHeader={
                <Typography variant="h5" align="right">
                  <NotificationActionsComponent notification={this.props.notification} />
                </Typography>
              }
            >
              {translate('Notifications.Title')} {this.props.notification!.id}
              {this.renderState()}
            </Header>

            <Container>
              <Grid container={true} spacing={4}>
                <Grid item={true} xs={12} md={6}>
                  <NotificationGeneral
                    notification={this.props.notification}
                    patchNotification={this.props.patchNotification}
                  />

                  <NotificationHistory id={this.props.notification?.id} />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <Panel>
                    {this.props.notification && (
                      <>
                        <BaseMapWithMarker
                          coordinates={new LatLng(this.props.notification.latitude, this.props.notification.longitude)}
                        />
                        <br />
                        <Viod
                          latitude={this.props.notification.latitude!}
                          longitude={this.props.notification.longitude!}
                        />
                      </>
                    )}
                  </Panel>
                  {this.props.notification && (
                    <>
                      <NotificationAttachments attachments={attachments} notificationId={this.props.notification.id} />
                    </>
                  )}
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </Auth>
    );
  }

  private renderState = () => {
    const notification = this.props.notification;
    const state = getNotificationStates().find((x) => x.id === notification!.state);
    return (
      <>
        <Chip label={state!.name} style={{ marginLeft: 20, background: state!.bgcolor, color: '#FFF' }} />
      </>
    );
  };
}
