import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { translate } from '../../translations/translate';
import { FunctionComponent, PropsWithChildren, useCallback, useState } from 'react';
import * as React from 'react';
import { COLORS } from '../..';

interface IProps {
  searchStreet: (street: string) => void;
}

export const MapSearchControl: FunctionComponent<PropsWithChildren<IProps>> = ({
  searchStreet,
}: PropsWithChildren<IProps>) => {
  const [currentSearchValue, setCurrentSearchValue] = useState<string>('');

  const onSearchKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === 'Enter') {
        searchStreet(currentSearchValue);
      }
    },
    [searchStreet, currentSearchValue],
  );

  const onSearchIconPressed = () => {
    searchStreet(currentSearchValue);
  };

  const onChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchValue(e.target.value);
  }, []);

  return (
    <div
      style={{
        border: '2px solid rgba(0,0,0,0.2)',
        borderRadius: 5,
        overflow: 'hidden',
        position: 'absolute',
        right: 65,
        top: 10,
        zIndex: 800,
      }}
    >
      <TextField
        data-testid="SearchField"
        id="search"
        placeholder={translate('Search')}
        inputProps={{ 'data-testid': 'SearchInput' }} // https://stackoverflow.com/questions/69870930/inputProps-vs-InputProps-in-mui-textfield/69872110
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search data-testid={'SearchIcon'} style={{ cursor: 'pointer' }} onClick={onSearchIconPressed} />
            </InputAdornment>
          ),
          style: { background: COLORS.WHITE, padding: 8, lineHeight: '15px' },
        }}
        onKeyUp={onSearchKeyUp}
        onChange={onChangeInput}
      />
    </div>
  );
};
