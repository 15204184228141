import { translateIgnoreTS } from '../../common/translations/translate';
import moment from 'moment';
import { CONFIG } from '..';
import { IModuleBatch, IMovementBatch } from '../../signs/types/module-batch';
import { IPublicDomainIntake } from '../../types';

export const formatCurrency = (amount: number, currency = 'EUR') =>
  new Intl.NumberFormat('nl-BE', { style: 'currency', currency }).format(amount);

export const getViodUrl = (intake: IPublicDomainIntake): string => {
  if (intake.geometry) {
    let coordinates = [];
    switch (intake.geometry.type) {
      case 'Point':
        coordinates = intake.geometry.coordinates;
        break;
      case 'LineString':
        coordinates = intake.geometry.coordinates[0];
        break;
      case 'Polygon':
        coordinates = intake.geometry.coordinates[0][0];
        break;
    }

    if (coordinates.length) {
      return `${CONFIG.baseViodUrl}&runWorkflow=OpstartenVIODPunt&type=WGS84&argX=${coordinates[1]}&argY=${coordinates[0]}`;
    }
  }
  return CONFIG.baseViodUrl;
};

export const onlyKeepDateFormat = (value: string) => value.replace(/[a-z ]/gi, '');

export const translateBatchName = (batch: IModuleBatch): string => {
  const batchYear = moment(batch.dateFrom).format('Y');
  return `${translateIgnoreTS('Months.' + batch.name)} ${batchYear}`;
};

export const translateMovementBatchName = (batch: IMovementBatch): string => {
  let batchName = batch.name;
  if (batch.dateFrom) {
    const batchYear = moment(batch.dateFrom).format('Y');
    batchName = `${translateIgnoreTS('Months.' + batch.name)} ${batchYear}`;
  }
  return batchName;
};

export const getLastUrlSegment = (url: string) => url.substring(url.lastIndexOf('/'));

export const snakeToCamel = (str: string) =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
