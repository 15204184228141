import { createReducer } from '@reduxjs/toolkit';
import { IById } from '../../../../common/utils/normalized.util';
import { IRequest } from '../../../../types';
import { RequestActions } from '../../../actions';

type TEntity = IRequest;
type TState = IById<TEntity>;

export default createReducer<TState>({}, (builder) => {
  builder.addCase(RequestActions.set.type, (state, { payload }: ReturnType<typeof RequestActions.set>) => {
    if (payload) {
      state[payload.id] = payload;
    }
  });
  builder.addCase(
    RequestActions.patchPriority.type,
    (state, { payload }: ReturnType<typeof RequestActions.patchPriority>) => {
      if (state[payload.id]) {
        state[payload.id].priority = {
          ...state[payload.id].priority,
          priority: payload.priority,
        };
      }
    },
  );
});
