import * as React from 'react';
import { FunctionComponent } from 'react';
import { CollapsablePanel } from '../../../../components';
import { IPublicDomainIntakeExtended } from '../../../../types';
import { SignsComponent } from './signs.component';
import { translate } from '../../../../common/translations/translate';

interface IProps {
  location?: IPublicDomainIntakeExtended;
}

/** For requests placed by Werkhaven */
export const SignsContainer: FunctionComponent<IProps> = ({ location }) => {
  return location ? (
    <CollapsablePanel title={translate('Requests.Detail.Signs.Title')}>
      <SignsComponent location={location} />
    </CollapsablePanel>
  ) : null;
};
