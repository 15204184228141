import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Container, Panel } from '../../common';
import { Loader } from '../../common/components/layout/loader.component';
import { PanelTitle } from '../../common/components/panel/panel-title.component';
import { Auth } from '../../components';
import { ISignsAclActions } from '../../types';
import {
  selectSignsForecastLoading,
  selectSignsForecast,
  selectSignsWoiForecastLoading,
  selectSignsWoiForecast,
} from '../store/forecast.selectors';
import { fetchSignsForecast, SignsActions } from '../store/signs.actions';
import { translate } from '../../common/translations/translate';

interface IData {
  averagePlaced: string;
  placed: string;
  dropOff: string;
  pickUp: string;
  stored: string;
  woiNum: string;
}

const DataColor: IData = {
  averagePlaced: '#c397fc',
  placed: '#AFD8FF',
  dropOff: '#4385C3',
  pickUp: '#F3AB5B',
  stored: '#B1DC48',
  woiNum: '#4385C3',
};

interface ILegend {
  [key: string]: boolean;
}

export const ForecastPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const signsForecast = useSelector(selectSignsForecast);
  const signsForecastLoading = useSelector(selectSignsForecastLoading);
  const signsWoiForecast = useSelector(selectSignsWoiForecast);
  const signsWoiForecastLoading = useSelector(selectSignsWoiForecastLoading);

  const [signsForecastLegend, setSignsForecastLegend] = useState<ILegend>({
    averagePlaced: true,
    dropOff: true,
    pickUp: true,
    placed: true,
    stored: true,
  });

  const woiLegend: ILegend = {
    woiNum: true,
  };

  useEffect(() => {
    dispatch(fetchSignsForecast());
    dispatch(SignsActions.fetchSignsWoiForecast());
  }, [dispatch]);

  const getLabel = (label: keyof IData): string => {
    return translate(`Forecast.Lines.${label}`);
  };

  const onClickLegend = ({ id }: { id?: string }): void => {
    id &&
      setSignsForecastLegend((signsForecastLegend) => ({
        ...signsForecastLegend,
        [id]: !signsForecastLegend[id],
      }));
  };

  return (
    <Auth acl={ISignsAclActions.viewSign} showUnauthorizedPage>
      <Container>
        <Panel>
          <PanelTitle>{translate('Forecast.SignsTitle')}</PanelTitle>
          <Loader loading={signsForecastLoading}>
            <ResponsiveContainer width="100%" height={700}>
              <LineChart data={signsForecast!} margin={{ bottom: 60, right: 60, top: 40 }}>
                <Legend
                  verticalAlign="top"
                  height={36}
                  formatter={getLabel}
                  onClick={onClickLegend}
                  payload={Object.keys(signsForecastLegend).map((key) => ({
                    color: signsForecastLegend[key] ? DataColor[key as keyof IData] : '#DDD',
                    id: key,
                    type: 'line' as 'line',
                    value: key,
                  }))}
                />
                <XAxis dataKey="date" tick={{ textAnchor: 'end' }} tickLine={false} minTickGap={0} interval={0} />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                {Object.keys(signsForecastLegend).map(
                  (key: any) =>
                    signsForecastLegend[key] && (
                      <Line
                        key={key}
                        type="monotone"
                        dataKey={key}
                        stroke={DataColor[key as keyof IData]}
                        strokeWidth={2}
                        name={getLabel(key)}
                      />
                    ),
                )}
              </LineChart>
            </ResponsiveContainer>
          </Loader>

          <PanelTitle>{translate('Forecast.WorkOrdersTitle')}</PanelTitle>
          <Loader loading={signsWoiForecastLoading}>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={signsWoiForecast!} margin={{ bottom: 60, right: 60, top: 40 }}>
                <Legend
                  verticalAlign="top"
                  height={36}
                  formatter={getLabel}
                  onClick={onClickLegend}
                  payload={Object.keys(woiLegend).map((key) => ({
                    color: woiLegend[key] ? DataColor[key as keyof IData] : '#DDD',
                    id: key,
                    type: 'line' as 'line',
                    value: key,
                  }))}
                />
                <XAxis
                  dataKey="date"
                  tick={{ textAnchor: 'end' }}
                  tickLine={false}
                  minTickGap={0}
                  interval={0}
                  angle={-45}
                />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                {Object.keys(woiLegend).map(
                  (key: any) =>
                    woiLegend[key] && (
                      <Line
                        key={key}
                        type="monotone"
                        dataKey={key}
                        stroke={DataColor[key as keyof IData]}
                        strokeWidth={2}
                        name={getLabel(key)}
                      />
                    ),
                )}
              </LineChart>
            </ResponsiveContainer>
          </Loader>
        </Panel>
      </Container>
    </Auth>
  );
};
