import { SagaIterator } from 'redux-saga';
import { all, call, cancelled, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler } from '../..';
import { CarFreeZonesApi } from './carFreeZones.api';
import { CarFreeZonesActions } from './carFreeZones.actions';
import { CarFreeZonesFilterState, ICall, ISelect, SnackBarVariant } from '../../../types';
import {
  getExemptionListPagedApiParams,
  getGateListPagedApiParams,
  getPagedApiParams,
  getRequestListPagedApiParams,
} from './carFreeZones.selectors';
import { getPagingFromHeaders } from '../../utils/api.util';
import { RequestsApi } from '../../../store/api';
import { SnackBarActions } from '../../../store/actions';
import { translate } from '../../translations/translate';
import { carFreeZonesInitialFilter } from '../../../carFreeZone/CarFreeZone.constants';
import axios from 'axios';

function* onFetch({ payload }: ReturnType<typeof CarFreeZonesActions.fetch>): SagaIterator {
  const response: ICall<typeof CarFreeZonesApi.fetch> = yield call(CarFreeZonesApi.fetch, payload);
  yield put(CarFreeZonesActions.set(response!.data.data));
}

function* onFetchTemplates({ payload }: ReturnType<typeof CarFreeZonesActions.fetch>): SagaIterator {
  const response: ICall<typeof CarFreeZonesApi.fetchTemplates> = yield call(CarFreeZonesApi.fetchTemplates, payload);
  yield put(CarFreeZonesActions.setTemplates({ [payload]: response!.data.data }));
}

function* onLinkTemplates({ payload }: ReturnType<typeof CarFreeZonesActions.linkTemplate>): SagaIterator {
  const response: ICall<typeof CarFreeZonesApi.linkTemplates> = yield call(CarFreeZonesApi.linkTemplates, payload.id, [
    payload.templateId,
  ]);
  yield put(CarFreeZonesActions.setTemplates({ [payload.id]: response!.data.data }));
}

function* onUnlinkTemplates({ payload }: ReturnType<typeof CarFreeZonesActions.unlinkTemplate>): SagaIterator {
  const response: ICall<typeof CarFreeZonesApi.linkTemplates> = yield call(
    CarFreeZonesApi.unlinkTemplates,
    payload.id,
    [payload.templateId],
  );
  yield put(CarFreeZonesActions.setTemplates({ [payload.id]: response!.data.data }));
}

function* onFetchAll(): SagaIterator {
  const response: ICall<typeof CarFreeZonesApi.fetchAll> = yield call(CarFreeZonesApi.fetchAll);
  yield put(CarFreeZonesActions.setAll(response!.data.data));
}

function* onFetchList(): SagaIterator {
  // @ts-ignore
  const { state, ...params }: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams)!;
  if (state.length === 1) {
    // This means state is either active or inactive
    // @ts-ignore
    params.active = state[0] === CarFreeZonesFilterState.active;
  }

  const response: ICall<typeof CarFreeZonesApi.fetchList> = yield call(CarFreeZonesApi.fetchList, params!);
  yield put(CarFreeZonesActions.list.set(response!.data.data));
  yield put(CarFreeZonesActions.list.setParams({ paging: getPagingFromHeaders(response as any) }));
}

function* onFetchRequestList(): SagaIterator {
  const params: ISelect<typeof getRequestListPagedApiParams> = yield select(getRequestListPagedApiParams)!;
  const response: ICall<typeof RequestsApi.getRequestList> = yield call(RequestsApi.getRequestList, params!);
  yield put(CarFreeZonesActions.requestList.set(response!.data.data));
  yield put(CarFreeZonesActions.requestList.setParams({ paging: getPagingFromHeaders(response as any) }));
}

function* onSyncGates(): SagaIterator {
  yield put(CarFreeZonesActions.gates.isSyncing(true));
  try {
    yield call(CarFreeZonesApi.syncGates);
    yield put(CarFreeZonesActions.list.fetch({ filters: carFreeZonesInitialFilter }));
  } catch (e) {
    yield put(
      SnackBarActions.setFeedback({
        duration: 5000,
        feedback: translate('carFreeZones.krautli.list.syncFailed'),
        variant: SnackBarVariant.error,
      }),
    );
  } finally {
    yield put(CarFreeZonesActions.gates.isSyncing(false));
  }
}

function* onFetchGateList(): SagaIterator {
  // @ts-ignore
  const { carFreeZoneId, ...params }: ISelect<typeof getGateListPagedApiParams> =
    yield select(getGateListPagedApiParams)!;
  const response: ICall<typeof CarFreeZonesApi.fetchGates> = yield call(
    CarFreeZonesApi.fetchGates,
    carFreeZoneId,
    params!,
  );
  yield put(CarFreeZonesActions.gates.list.set(response!.data.data));
  yield put(CarFreeZonesActions.gates.list.setParams({ paging: getPagingFromHeaders(response as any) }));
}

function* onFetchExemptionList(): SagaIterator {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  try {
    // @ts-ignore
    const { ...params }: ISelect<typeof getExemptionListPagedApiParams> = yield select(getExemptionListPagedApiParams)!;
    const response: ICall<typeof CarFreeZonesApi.fetchExemptions> = yield call(
      CarFreeZonesApi.fetchExemptions,
      params!,
      source.token,
    );
    yield all([
      yield put(CarFreeZonesActions.exemptions.list.set(response!.data.data)),
      yield put(CarFreeZonesActions.exemptions.list.setParams({ paging: getPagingFromHeaders(response as any) })),
    ]);
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export function* carFreeZonesSaga(): SagaIterator {
  yield takeEvery(CarFreeZonesActions.fetch.type, genericErrorHandler(onFetch));
  yield takeEvery(CarFreeZonesActions.fetch.type, genericErrorHandler(onFetchTemplates));
  yield takeEvery(CarFreeZonesActions.linkTemplate.type, genericErrorHandler(onLinkTemplates));
  yield takeEvery(CarFreeZonesActions.unlinkTemplate.type, genericErrorHandler(onUnlinkTemplates));
  yield takeLatest(CarFreeZonesActions.fetchAll.type, genericErrorHandler(onFetchAll));
  yield takeLatest(CarFreeZonesActions.list.fetch.type, genericErrorHandler(onFetchList));
  yield takeLatest(CarFreeZonesActions.requestList.fetch.type, genericErrorHandler(onFetchRequestList));
  yield takeLatest(CarFreeZonesActions.gates.sync.type, genericErrorHandler(onSyncGates));
  yield takeLatest(CarFreeZonesActions.gates.list.fetch.type, genericErrorHandler(onFetchGateList));
  yield takeLatest(CarFreeZonesActions.exemptions.list.fetch.type, genericErrorHandler(onFetchExemptionList));
}
