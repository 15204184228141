import { translate } from '../../translations/translate';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { Switch } from 'react-router';
import { Snackbar } from '../feedback/SnackBar.component';
import { SnackBarVariant } from '../../../types';
import { useSelector } from 'react-redux';
import { getIsLoggedIn, selectError } from '../../../store/selectors';

interface IProps {
  children: ReactNode;
}
export const withErrorHandling =
  (WrappedComponent: React.ComponentType<any | string>) =>
  ({ children }: IProps) => {
    const [open, setOpen] = React.useState(false);
    const error = useSelector(selectError);
    const isLoggedIn = useSelector(getIsLoggedIn);

    useEffect(() => {
      setOpen(!!error && isLoggedIn);
    }, [error, isLoggedIn]);

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <WrappedComponent>{children}</WrappedComponent>
        <Snackbar onClose={handleClose} show={open} variant={SnackBarVariant.error}>
          {translate('SomethingWentWrong')}
        </Snackbar>
      </>
    );
  };

export const SwitchWithErrorHandling = withErrorHandling(({ children }) => <Switch>{children}</Switch>);
