import {
  Actions,
  generateBaseActionCreator,
  generatePayloadActionCreator,
  IMapLocation,
  TBaseActionCreator,
  TPayloadActionCreator,
} from '../../common';
import { ITeam, IMarkerPopup, IWorkOrder, IWorkOrderItem, IWorkOrderItemsFilter } from '../../types';

export const fetchTeams: TBaseActionCreator = generateBaseActionCreator('planning.fetchTeams');

export const setTeams: TPayloadActionCreator<ITeam[]> = generatePayloadActionCreator('planning.setTeams');

export const setWorkOrders: TPayloadActionCreator<IWorkOrder[]> =
  generatePayloadActionCreator('planning.setWorkOrders');

export const setWorkOrder: TPayloadActionCreator<IWorkOrder> = generatePayloadActionCreator('planning.setWorkOrder');

export const fetchMarkerPopup: TPayloadActionCreator<number> =
  generatePayloadActionCreator('planning.fetchMarkerPopup');

export const closeMarkerPopup: TBaseActionCreator = generateBaseActionCreator('planning.closeMarkerPopup');

export const setMarkerPopup: TPayloadActionCreator<IMarkerPopup | null> =
  generatePayloadActionCreator('planning.setMarkerPopup');

export const deleteWorkOrderItem: TPayloadActionCreator<IWorkOrderItem> =
  generatePayloadActionCreator('planning.deleteWorkOrderItem');

export const updateWorkOrder: TPayloadActionCreator<IWorkOrder> =
  generatePayloadActionCreator('planning.updateWorkOrder');

export const updateWorkOrderState: TPayloadActionCreator<IWorkOrder> = generatePayloadActionCreator(
  'planning.updateWorkOrderState',
);

export const setWorkOrderItems: TPayloadActionCreator<IWorkOrderItem[]> =
  generatePayloadActionCreator('planning.setWorkOrderItems');

export const setWorkOrderItemsFilter: TPayloadActionCreator<IWorkOrderItemsFilter> = generatePayloadActionCreator(
  'planning.setWorkOrderItemsFilter',
);

export const setWorkOrderItemsLoading: TPayloadActionCreator<boolean> = generatePayloadActionCreator(
  'planning.setWorkOrderItemsLoading',
);

export const setWorkOrdersLoading: TPayloadActionCreator<boolean> = generatePayloadActionCreator(
  'planning.setWorkOrdersLoading',
);

export const searchStreet: TPayloadActionCreator<string> = generatePayloadActionCreator('planning.searchStreet');

export const setMapLocation: TPayloadActionCreator<IMapLocation> =
  generatePayloadActionCreator('planning.setMapLocation');

class PlanningActionsClass extends Actions {
  assignItemsToWorkOrder = this.createPayloadAction<{ order: IWorkOrder; filter: IWorkOrderItemsFilter }>(
    'assignItemsToWorkOrder',
  );
  markWarningAsIncomplete = this.createPayloadAction<number>('markWarningAsIncomplete');
  fetchWorkOrders = this.createPayloadAction<{ filter: IWorkOrderItemsFilter; setLoading: boolean }>('fetchWorkOrders');
  fetchWorkOrderItems = this.createPayloadAction<IWorkOrderItemsFilter>('fetchWorkOrderItems');
}

export const PlanningActions = new PlanningActionsClass('planning');
