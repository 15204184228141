import { Grid, LinearProgress, Tooltip } from '@material-ui/core';
import { ArrowDownward, Build, CalendarToday, List } from '@material-ui/icons';
import { translate } from '../../common/translations/translate';
import * as React from 'react';
import { COLORS } from '../../common';
import { WorkOrderItemTypes } from '../planning.constants';
import { IWorkOrderItem } from '../../types';

interface IProps {
  workOrderItems: IWorkOrderItem[];
}

interface ITotals {
  total: number;
  totalNeedMaintenance: number;
  totalSignsToPlace: number;
  totalPlanned: number;
  totalToPlan: number;
}

export class PlanningStatus extends React.Component<IProps> {
  public render(): React.ReactNode {
    const totals = this.calculateTotals();
    return (
      <>
        <Grid
          container={true}
          justify="space-between"
          alignItems="center"
          style={{ margin: '20px 0', color: COLORS.GREY }}
        >
          {[
            {
              count: totals.totalNeedMaintenance,
              icon: <Build fontSize="small" />,
              key: 'maintenance',
              label: translate('maintenance'),
            },
            {
              count: totals.totalSignsToPlace,
              icon: <ArrowDownward fontSize="small" />,
              key: 'signsToPlace',
              label: translate('SignsToPlace'),
            },
            {
              count: totals.totalPlanned,
              icon: <CalendarToday fontSize="small" />,
              key: 'totalPlanned',
              label: translate('TotalPlanned'),
            },
            {
              count: totals.totalToPlan,
              icon: <List fontSize="small" />,
              key: 'totalToPlan',
              label: translate('TotalToPlan'),
            },
          ].map((item) => (
            <Grid key={item.key} item={true} style={{ flex: 1 }}>
              <Tooltip title={item.label}>
                <Grid container={true} spacing={1} justify="center" alignItems="center">
                  <Grid item={true}>{item.icon}</Grid>
                  <Grid item={true} style={{ paddingBottom: 5 }}>
                    <span id={item.key}>{item.count}</span>
                  </Grid>
                </Grid>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <div>
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={(totals.totalPlanned / totals.total) * 100}
            style={{ height: 8 }}
          />
        </div>
      </>
    );
  }

  private calculateTotals(): ITotals {
    const total = this.props.workOrderItems.length;
    const totalPlanned = this.props.workOrderItems.filter((woi) => woi.workOrder).length;
    const totalToPlan = total - totalPlanned;
    const totalSignsToPlace = this.props.workOrderItems
      .filter((woi) => woi.type === WorkOrderItemTypes.drop_off && woi.parkingBanIntake)
      .reduce(
        (count: number, woi: IWorkOrderItem) =>
          count + (woi.parkingBanIntake!.numberOfSigns || 0) + (woi.parkingBanIntake!.numberOfZoneSigns || 0),
        0,
      );

    const totalNeedMaintenance = this.props.workOrderItems
      .filter((woi) => {
        return woi.type === WorkOrderItemTypes.pick_up && woi.parkingBanIntake;
      })
      .reduce(
        (count: number, woi: IWorkOrderItem) => count + (woi.parkingBanIntake!.numberOfSignsNeedMaintenance || 0),
        0,
      );

    return {
      total,
      totalNeedMaintenance,
      totalPlanned,
      totalSignsToPlace,
      totalToPlan,
    };
  }
}
