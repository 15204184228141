import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler } from '../../common';
import { ICall } from '../../types';
import { AppFeatureAction } from '../actions';
import { AppFeaturesApi } from '../api';
import { ENVIRONMENT, IS_DEVELOPMENT, SENTRY_DSN, VERSION } from '../../common/config/environment';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/react';

function* onFetchAppFeatures(): SagaIterator {
  const response: ICall<typeof AppFeaturesApi.fetch> = yield call(AppFeaturesApi.fetch);

  if (response!.data.data.sentryLoggingBackoffice && !IS_DEVELOPMENT) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [browserTracingIntegration()],
      environment: ENVIRONMENT,
      release: VERSION,
      normalizeDepth: 10,
      tracesSampleRate: 1.0,
    });
  }

  yield put(AppFeatureAction.set(response!.data.data));
}

export function* appFeaturesSaga(): SagaIterator {
  yield takeLatest(AppFeatureAction.fetch.type, genericErrorHandler(onFetchAppFeatures));
}
