import * as React from 'react';
import { Panel, TileSelect } from '../../common';
import { SIGN_CONDITIONS, SIGN_FILTERS, SIGN_STATES } from '../signs.constants';
import { ISignCounters } from '../types/sign-counters';
import { TSignPredefinedFilter } from '../types/signs-filter';
import { translate } from '../../common/translations/translate';

interface IProps {
  signCounters: ISignCounters;
  filter?: TSignPredefinedFilter;
  filterChange: (value: TSignPredefinedFilter) => void;
}

export class SignsOverview extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { signCounters } = this.props;
    return (
      <Panel title={translate('OverviewSigns')}>
        <TileSelect
          id="overviewFilters"
          value={this.props.filter}
          valueChange={this.props.filterChange}
          values={[
            {
              subtitle: translate('Placed'),
              title: `${signCounters.placed}`,
              value: SIGN_STATES.PLACED,
            },
            {
              subtitle: translate('Moved'),
              title: `${signCounters.moved}`,
              value: SIGN_STATES.MOVED,
            },
            {
              subtitle: translate('Damaged'),
              title: `${signCounters.damaged}`,
              value: SIGN_CONDITIONS.DAMAGED,
            },
            {
              subtitle: translate('NotExtended'),
              title: `${signCounters.notExtended}`,
              value: SIGN_FILTERS.NOT_EXTENDED,
            },
            {
              subtitle: translate('NoGPS'),
              title: `${signCounters.noGPS}`,
              value: SIGN_FILTERS.NO_GPS,
            },
            {
              subtitle: translate('Stored'),
              title: `${signCounters.stored}`,
              value: SIGN_STATES.STORED,
            },
            {
              subtitle: translate('NoConnection'),
              title: `${signCounters.noConnection}`,
              value: SIGN_FILTERS.NO_CONNECTION,
            },
            {
              subtitle: translate('Missing'),
              title: `${signCounters.missing}`,
              value: SIGN_STATES.MISSING,
            },
            {
              subtitle: translate('maintenance'),
              title: `${signCounters.requireMaintenance}`,
              value: SIGN_FILTERS.REQUIRE_MAINTENANCE,
            },
            {
              subtitle: translate('LowBattery'),
              title: `${signCounters.lowBattery}`,
              value: SIGN_CONDITIONS.LOW_BATTERY,
            },
          ]}
        />
      </Panel>
    );
  }
}
