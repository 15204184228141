import { Tooltip } from '@material-ui/core';
import React, { CSSProperties, FunctionComponent, useCallback } from 'react';
import { Close } from '@material-ui/icons';
import { DateFormat, IFileThumbnail } from '../../../types';
import Image from 'material-ui-image';
import { AsignFab } from '../buttons/asign-fab';
import { Checkbox } from '../form-fields/checkbox.component';
import { translate } from '../../translations/translate';
import moment from 'moment';

export const Thumbnail: FunctionComponent<IFileThumbnail> = ({
  file: { createdAt, id, fileName, url },
  onClick,
  onRemove,
  onSelect,
  selected,
}) => {
  const _onRemove = useCallback(() => {
    fileName && onRemove && onRemove(fileName);
  }, [fileName, onRemove]);

  const _onSelect = useCallback(() => {
    id && onSelect && onSelect(`${id}`);
  }, [id, onSelect]);

  return (
    <div style={styles.root as CSSProperties} data-testid="Thumbnail">
      <div
        style={{ ...styles.imageContainer, cursor: onClick ? 'pointer' : 'auto' } as CSSProperties}
        data-testid="ThumbnailImage"
      >
        <Tooltip title={moment(createdAt).format(DateFormat.dateTime)}>
          <Image src={url || ''} cover imageStyle={styles.image} onClick={onClick} />
        </Tooltip>
      </div>

      {onSelect && <Checkbox checked={selected} onClick={_onSelect} style={styles.checkbox as CSSProperties} />}

      {onRemove && (
        <AsignFab
          dataTestId="ThumbnailRemoveButton"
          onClick={_onRemove}
          style={{ ...styles.button, ...styles.bottomRight }}
          tooltip={translate('FileBox.delete')}
        >
          <Close fontSize="small" color="error" />
        </AsignFab>
      )}
    </div>
  );
};

const styles = {
  root: {
    backgroundColor: '#e4e4e4',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: '32px',
    margin: '5px 5px 5px 0',
    position: 'relative',
    minHeight: '140px',
    height: '140px',
    minWidth: '140px',
    width: '140px',
  },
  image: {
    borderRadius: '8px',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    height: 30,
    minHeight: 30,
    position: 'absolute',
    width: 30,
  },
  checkbox: {
    backgroundColor: '#e0e0e0',
    borderRadius: 5,
    left: 0,
    marginLeft: 5,
    marginTop: 5,
    position: 'absolute',
    top: 0,
  },
  bottomRight: {
    bottom: 0,
    right: 0,
  },
  icon: {
    marginLeft: '10px',
    verticalAlign: 'middle',
  },
};
