import { createReducer } from '@reduxjs/toolkit';
import { payloadReducer } from '../../../common';
import { IRequestLocationDetail } from '../../../types';
import { WorkOrderItemsActions } from '../../../common';
import { RequestLocationsActions } from '../../actions';

type TState = IRequestLocationDetail | null;

export default createReducer<TState>(null, (builder) => {
  builder.addCase(RequestLocationsActions.set.type, payloadReducer<TState>());
  builder.addCase(
    WorkOrderItemsActions.append.type,
    (state, { payload }: ReturnType<typeof WorkOrderItemsActions.append>) => {
      if (state) {
        return {
          ...state,
          workOrderItems: [...state.workOrderItems, payload],
        };
      }
      return state;
    },
  );

  builder.addCase(
    WorkOrderItemsActions.delete.type,
    (state, { payload }: ReturnType<typeof WorkOrderItemsActions.delete>) => {
      if (state) {
        return {
          ...state,
          workOrderItems: state.workOrderItems.filter(({ id }) => id !== payload),
        };
      }
      return state;
    },
  );
});
