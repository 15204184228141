import React, { FC, useState } from 'react';
import { ExpansionPanelSummary, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ISgwPhaseWithRecurringPhases, ISgwRequestDetailAclActions } from '../../../types';
import { DateField } from '../../atoms/DateField/DateField.component';
import { translate } from '../../../common/translations/translate';
import { PhaseDetails } from '../../forms/PhaseDetailsForm/PhaseDetails.container';
import { JotaiExpansionPanel } from '../../atoms/JotaiExpansionPanel/JotaiExpansionPanel.component';
import { COLORS } from '../../../common';
import { Visible } from '../../../common/components/layout/Visible.component';
import { ModalButton } from '../../../common/components/buttons/modal-button';
import { useGeneralStyles } from '../../../style/generalStyles';
import classNames from 'classnames';
import { useSgwPhaseForm } from '../../../hooks/forms/useSgwPhaseForm.hook';
import { FormProvider } from 'react-hook-form';
import { ATOM_HASHES } from '../../../common/config/atom.constants';
import { atomWithHash } from 'jotai-location';
import { atom } from 'jotai';
import { RecurringPhasesForm } from '../../forms/RecurringPhasesForm/RecurringPhasesForm.component';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import { NEW_PHASE, NEW_RECURRING_PHASE_TEMPLATE } from '../../../common/config/sgwPhase.constants';
import { useRequestAuthorization } from '../../../hooks';

interface IProps {
  index: number;
  id: string;
  onDelete: (newPhase: boolean, recurringPhase: boolean, id?: string) => void;
  phase?: ISgwPhaseWithRecurringPhases;
  recurringPhaseTemplate?: boolean;
  newPhase?: boolean;
  editMode?: boolean;
}

const useStyles = makeStyles({
  accordion: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  image: {
    width: '54px',
    height: '54px',
    margin: '20px 40px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '6px',
    boxShadow: `0px 1px 3px 1px ${COLORS.GREY_LIGHT}`,
  },
  number: {
    fontSize: 'large',
    fontWeight: 'bold',
  },
  panel: {
    width: '100%',
    margin: '15px 0px 0px 0px !important',
    marginleft: '15px',
    marginRight: '15px',
    boxShadow: `0px 1px 3px 1px ${COLORS.GREY_LIGHT}`,
  },
  header: {
    display: 'flex',
    width: '60%',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
  },
  phaseName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '40ch',
  },
  accordionRecurringPhase: {
    justifyContent: 'flex-end',
  },
  panelRecurringPhase: {
    width: 'calc(100% - 91px)',
  },
});

export const PhaseAccordion: FC<IProps> = ({
  phase,
  index,
  id,
  onDelete,
  recurringPhaseTemplate,
  newPhase,
  editMode,
}) => {
  const C = useStyles();
  const G = useGeneralStyles();
  const isExpandedAtom = newPhase ? atom(newPhase) : atomWithHash<boolean>(ATOM_HASHES.phases.open(id), false);
  const editModeAtom = editMode ? atom(editMode) : atomWithHash<boolean>(ATOM_HASHES.phases.edit(id), false);
  const { formMethods } = useSgwPhaseForm(phase);
  const [recurringPhaseModal, setRecurringPhaseModal] = useState(false);
  const { watch } = formMethods;
  const canDelete =
    (moment().isBefore(phase?.dateFrom) && !phase?.recurringPhases?.length) ||
    !!recurringPhaseTemplate ||
    id === NEW_PHASE ||
    id === NEW_RECURRING_PHASE_TEMPLATE;
  const { isAuthorized: canEdit } = useRequestAuthorization(ISgwRequestDetailAclActions.editSgwPhaseDetails);

  return (
    <FormProvider {...formMethods}>
      <div className={classNames(C.accordion, phase?.parentPhaseId && C.accordionRecurringPhase)}>
        <Visible visible={!recurringPhaseTemplate && !phase?.parentPhaseId}>
          <Paper className={C.image}>
            <div>{translate('sgw.requests.detail.phases.title')}</div>
            <div className={C.number}>{index + 1}</div>
          </Paper>
        </Visible>

        <JotaiExpansionPanel
          className={classNames(C.panel, phase?.parentPhaseId && C.panelRecurringPhase)}
          isExpanded={isExpandedAtom}
        >
          <ExpansionPanelSummary>
            <div className={classNames(G.flexRowSpaceBetween, G.fullWidth)}>
              <div className={C.header}>
                <div className={C.phaseName}>
                  {phase?.phaseName ||
                    (recurringPhaseTemplate
                      ? translate('sgw.requests.detail.phases.newRecurringPhase')
                      : translate('sgw.requests.detail.phases.newPhase'))}
                </div>
                <Visible visible={!recurringPhaseTemplate}>
                  <DateField
                    title={translate('sgw.requests.detail.phases.from')}
                    date={phase?.dateFrom || watch('dateFrom')}
                    time={phase?.timeFrom || watch('timeFrom')}
                  />
                  <DateField
                    title={translate('sgw.requests.detail.phases.to')}
                    date={phase?.dateUntil || watch('dateUntil')}
                    time={phase?.timeUntil || watch('timeUntil')}
                  />
                </Visible>
              </div>
              <div>
                <Visible visible={canEdit}>
                  <Visible visible={!!recurringPhaseTemplate && id !== NEW_RECURRING_PHASE_TEMPLATE}>
                    <IconButton data-testid={'GeoDrawingDelete'}>
                      <Add
                        onClick={(e) => {
                          e.stopPropagation();
                          setRecurringPhaseModal(true);
                        }}
                      />
                    </IconButton>
                  </Visible>

                  <RecurringPhasesForm
                    onClose={() => setRecurringPhaseModal(false)}
                    id={id}
                    visible={recurringPhaseModal}
                  />
                  <ModalButton.DeletePhase
                    onConfirm={() => onDelete(!!newPhase, !!recurringPhaseTemplate, id)}
                    title={translate('sgw.requests.detail.phases.removePhase')}
                    buttonTitle={translate('sgw.requests.detail.phases.removePhase')}
                    visible={canDelete}
                    needsReason={false}
                    modalBody={translate('sgw.requests.detail.phases.removePhaseInfo')}
                  />
                </Visible>
              </div>
            </div>
          </ExpansionPanelSummary>
          <PhaseDetails
            phase={phase}
            editModeAtom={editModeAtom}
            recurringPhaseTemplate={recurringPhaseTemplate}
            newPhase={newPhase}
          />
        </JotaiExpansionPanel>
      </div>
    </FormProvider>
  );
};
