import { createReducer } from '@reduxjs/toolkit';
import { payloadReducer } from '../../../common';
import { IRequest } from '../../../types';
import { initialRequestsState } from '../../state/requests.state';
import { RequestActions } from '../../actions';

type TState = IRequest | null;

export default createReducer<TState>(initialRequestsState.request, (builder) => {
  builder.addCase(RequestActions.set.type, payloadReducer<TState>(initialRequestsState.request));
});
