import { createReducer } from '@reduxjs/toolkit';
import { StorageActions } from '../../actions';

type TState = string[];

export default createReducer<TState>([], (builder) => {
  builder.addCase(StorageActions.clear.type, () => []);
  builder.addCase(StorageActions.upload.type, (state, { payload }: ReturnType<typeof StorageActions.upload>) =>
    state.includes(payload.metadata.id) ? state : state.concat(payload.metadata.id),
  );
  builder.addCase(
    StorageActions.uploadError.type,
    (state, { payload: { id } }: ReturnType<typeof StorageActions.uploadError>) =>
      state.includes(id) ? state : state.concat(id),
  );
  builder.addCase(
    StorageActions.uploadSuccess.type,
    (state, { payload: { id } }: ReturnType<typeof StorageActions.uploadSuccess>) =>
      state.includes(id) ? state : state.concat(id),
  );
  builder.addCase(StorageActions.remove.type, (state, { payload: { id } }: ReturnType<typeof StorageActions.remove>) =>
    state.filter((stateId) => stateId !== id),
  );
});
