import { Box, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, FieldProps } from 'formik';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { FormikTextField } from '../../../common/components/formikFormFields';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { countryToFlag } from '../../../common/utils/countryToFlag.util';
import { IFormikField, RequestCreateField, IRequestFormData } from '../../../types';
import { getAllRequestCountries, getEditableFieldsIncludes } from '../../../store/selectors';

interface IProps {
  values: IRequestFormData;
}

interface ICityOption {
  value: string;
  label: string;
}

const useStyles = makeStyles({
  root: { marginBottom: '2em' },
  container: { width: 500 },
  countryField: { width: 300 },
});

const TextFieldWrapper: FunctionComponent<IFormikField> = ({ ...props }) => {
  return <FormikTextField {...props} label={translateIgnoreTS(`Requests.Create.Form.${props.name}`)} />;
};

export const RequestFormAddressComponent: FunctionComponent<IProps> = ({ values }) => {
  const C = useStyles();
  const countries = useSelector(getAllRequestCountries);
  const countriesDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.country));

  return (
    <Box className={C.root}>
      <PanelTitle>{translate('Requests.Create.AddressTitle')}</PanelTitle>
      <Grid container className={C.container} spacing={4} justify={'center'}>
        <Grid item xs={6}>
          <TextFieldWrapper
            name={RequestCreateField.street}
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.street))}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldWrapper
            name={RequestCreateField.streetNumber}
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.streetNumber))}
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldWrapper
            name={RequestCreateField.bus}
            required={false}
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.bus))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWrapper
            name={RequestCreateField.zipCode}
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.zipCode))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWrapper
            name={RequestCreateField.city}
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.city))}
          />
        </Grid>
        <Grid item xs={12}>
          {countries.length > 0 && (
            <Field
              component={(props: FieldProps) => (
                <Autocomplete
                  {...props.field}
                  disabled={countriesDisabled}
                  className={C.countryField}
                  options={countries as ICityOption[]}
                  value={countries.find((country) => country.value === props.form.values.country)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      // @ts-ignore
                      props.form.setFieldValue(RequestCreateField.country, newValue.value);
                    }
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => (
                    <>
                      <span>{countryToFlag(option.value)}</span>
                      {option.label} ({option.value})
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate(`Requests.Create.Form.country`)}
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        disabled: countriesDisabled,
                        autoComplete: '', // disable autocomplete and autofill
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              )}
              name={RequestCreateField.country}
              type="text"
              label="{label}"
              required={true}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
