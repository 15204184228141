import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery, takeLatest, throttle } from 'redux-saga/effects';
import { genericErrorHandler } from '../..';
import { serviceInitialFilter } from '../../../service/Service.constants';
import { SnackBarActions } from '../../../store/actions';
import { HttpStatusCodes, ICall, ISelect, SnackBarVariant } from '../../../types';
import { appUrls } from '../../config/url.constants';
import { THROTTLE } from '../../time-constants';
import { translate } from '../../translations/translate';
import { getPagingFromHeaders } from '../../utils/api.util';
import { ServicesActions } from './Services.actions';
import { ServicesApi } from './Services.api';
import {
  getContactPersonsPagedApiParams,
  getExistingNames,
  getPagedApiParams,
  getPagedWhitelistApiParams,
  getWhitelistEntryListPagedApiParams,
  selectContactPersonsTable,
} from './Services.selectors';

function* onFetch({ payload }: ReturnType<typeof ServicesActions.fetch>): SagaIterator {
  const response: ICall<typeof ServicesApi.fetch> = yield call(ServicesApi.fetch, payload);
  yield put(ServicesActions.set(response!.data.data));
}

function* onFetchList(): SagaIterator {
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams)!;
  const response: ICall<typeof ServicesApi.fetchList> = yield call(ServicesApi.fetchList, params!);
  yield put(ServicesActions.list.set(response!.data.data));
  yield put(ServicesActions.list.setParams({ paging: getPagingFromHeaders(response as any) }));
  yield put(ServicesActions.aptr.fetchLatestSync());
}

function* onFetchWhitelistEntryList(): SagaIterator {
  const params: ISelect<typeof getWhitelistEntryListPagedApiParams> = yield select(
    getWhitelistEntryListPagedApiParams,
  )!;
  const response: ICall<typeof ServicesApi.fetchWhitelistEntryList> = yield call(
    ServicesApi.fetchWhitelistEntryList,
    params!,
  );
  yield put(ServicesActions.whitelistEntriesList.set(response!.data.data));
  yield put(ServicesActions.whitelistEntriesList.setParams({ paging: getPagingFromHeaders(response as any) }));
}

function* onFetchWhitelistsList(): SagaIterator {
  try {
    yield put(ServicesActions.saving(true));
    const params: ISelect<typeof getPagedWhitelistApiParams> = yield select(getPagedWhitelistApiParams)!;
    const response: ICall<typeof ServicesApi.fetchWhitelists> = yield call(ServicesApi.fetchWhitelists, params!);
    yield put(ServicesActions.whitelistsList.set(response!.data.data));
    yield put(ServicesActions.whitelistsList.setParams({ paging: getPagingFromHeaders(response as any) }));
  } finally {
    yield put(ServicesActions.saving(false));
  }
}

function* onCreate({ payload }: ReturnType<typeof ServicesActions.create>): SagaIterator {
  try {
    yield put(ServicesActions.saving(true));
    const response: ICall<typeof ServicesApi.create> = yield call(ServicesApi.create, payload.service);
    yield put(ServicesActions.set(response!.data.data));
    yield all(payload.contacts.map((contact) => call(ServicesApi.addContact, response!.data.data, contact)));
    yield put(push(appUrls.services.detail(`${response!.data.data.id}`)));
  } finally {
    yield put(ServicesActions.saving(false));
  }
}

function* onAddContact({ payload }: ReturnType<typeof ServicesActions.addContact>): SagaIterator {
  try {
    yield put(ServicesActions.saving(true));
    const table: ISelect<typeof selectContactPersonsTable> = yield select(selectContactPersonsTable);
    yield call(ServicesApi.addContact, payload.service, payload.contact);
    yield put(
      ServicesActions.contactsList.fetch({
        sorting: table!.sorting,
        filters: { id: `${payload.service.id}` },
      }),
    );
  } finally {
    yield put(ServicesActions.saving(false));
  }
}

function* onDeleteContact({ payload }: ReturnType<typeof ServicesActions.deleteContact>): SagaIterator {
  try {
    yield put(ServicesActions.saving(true));
    const table: ISelect<typeof selectContactPersonsTable> = yield select(selectContactPersonsTable);
    yield call(ServicesApi.deleteContact, payload.service, payload.contact);
    yield put(
      ServicesActions.contactsList.fetch({
        sorting: table!.sorting,
        filters: { id: `${payload.service.id}` },
      }),
    );
  } finally {
    yield put(ServicesActions.saving(false));
  }
}

function* onFetchContactList({ payload }: ReturnType<typeof ServicesActions.contactsList.fetch>): SagaIterator {
  if (payload?.filters?.id) {
    const params: ISelect<typeof getContactPersonsPagedApiParams> = yield select(getContactPersonsPagedApiParams)!;
    const response: ICall<typeof ServicesApi.fetchContactList> = yield call(
      ServicesApi.fetchContactList,
      payload.filters.id,
      params!,
    );
    yield put(ServicesActions.setContactList(response!.data.data));
    // @ts-ignore - Not sure how to fix this => TODO after CRA
    yield put(ServicesActions.setContactListPaging(getPagingFromHeaders(response)));
  }
}

function* onSave({ payload }: ReturnType<typeof ServicesActions.save>): SagaIterator {
  try {
    yield put(ServicesActions.saving(true));
    const response: ICall<typeof ServicesApi.save> = yield call(ServicesApi.save, payload);
    yield put(ServicesActions.set(response!.data.data));
  } finally {
    yield put(ServicesActions.saving(false));
  }
}

function* onSavePincode({ payload }: ReturnType<typeof ServicesActions.savePincode>): SagaIterator {
  try {
    yield put(ServicesActions.savingPincode(true));
    const response: ICall<typeof ServicesApi.savePincode> = yield call(
      ServicesApi.savePincode,
      payload.serviceId,
      payload.pinCodeManagement,
    );
    yield put(ServicesActions.set(response!.data.data));
  } finally {
    yield put(ServicesActions.savingPincode(false));
  }
}

function* onResetPincodes({ payload }: ReturnType<typeof ServicesActions.resetPincodes>): SagaIterator {
  try {
    yield put(ServicesActions.savingPincode(true));
    const response: ICall<typeof ServicesApi.resetPincodes> = yield call(ServicesApi.resetPincodes, payload);
    yield put(ServicesActions.set(response!.data.data));
  } finally {
    yield put(ServicesActions.savingPincode(false));
  }
}

function* onUploadWhitelistFile({ payload }: ReturnType<typeof ServicesActions.uploadWhitelistFile>): SagaIterator {
  const formData = new FormData();
  formData.append('file', payload.file, payload.file.name);
  payload.ignoreFileLength && formData.append('ignoreFileLength', 'true');
  try {
    yield put(ServicesActions.saving(true));
    yield put(ServicesActions.setUploadResult(null));
    yield call(ServicesApi.uploadWhitelistFile, formData);
    yield put(ServicesActions.setUploadResult({ type: 'info', mesage: translate('services.whitelists.messages.ok') }));
    yield put(ServicesActions.whitelistsList.fetch({}));
  } catch (e: any) {
    if (e.response?.data?.msgs?.length >= 0) {
      yield put(
        ServicesActions.setUploadResult({
          type: e.response.status === HttpStatusCodes.UNPROCESSABLE_ENTITY ? 'can_ignore_error' : 'error',
          mesage: e.response.data.msgs[0].message,
          data: e.response.data.data,
        }),
      );
    }
  } finally {
    yield put(ServicesActions.saving(false));
  }
}

function* onSearchName({ payload }: ReturnType<typeof ServicesActions.searchName>): SagaIterator {
  const existingNames = yield select(getExistingNames);
  const response: ICall<typeof ServicesApi.fetchByName> = yield call(ServicesApi.fetchByName, payload);
  yield put(
    ServicesActions.setExistingNames([
      ...existingNames!,
      ...response!.data.data.map((service) => service.name.toLowerCase()),
    ]),
  );
}

function* onSyncWithAPTR(): SagaIterator {
  yield put(ServicesActions.aptr.isSyncing(true));
  try {
    yield call(ServicesApi.syncWithAPTR);
    yield put(ServicesActions.list.fetch({ filters: serviceInitialFilter }));
  } catch (e) {
    yield put(
      SnackBarActions.setFeedback({
        duration: 5000,
        feedback: translate('services.list.aptrSyncFailed'),
        variant: SnackBarVariant.error,
      }),
    );
  } finally {
    yield put(ServicesActions.aptr.isSyncing(false));
  }
}

function* onFetchLatestAPTR(): SagaIterator {
  const response: ICall<typeof ServicesApi.fetchLatestAPTRSync> = yield call(ServicesApi.fetchLatestAPTRSync);
  yield put(ServicesActions.aptr.setLatestSync(response!.data.data.aptrSyncedAt));
}

export function* servicesSaga(): SagaIterator {
  yield takeEvery(ServicesActions.fetch.type, genericErrorHandler(onFetch));
  yield takeLatest(ServicesActions.aptr.fetchLatestSync.type, genericErrorHandler(onFetchLatestAPTR));
  yield takeLatest(ServicesActions.list.fetch.type, genericErrorHandler(onFetchList));
  yield takeLatest(ServicesActions.contactsList.fetch.type, genericErrorHandler(onFetchContactList));
  yield takeEvery(ServicesActions.whitelistsList.fetch.type, genericErrorHandler(onFetchWhitelistsList));
  yield takeLatest(ServicesActions.create.type, genericErrorHandler(onCreate));
  yield takeLatest(ServicesActions.save.type, genericErrorHandler(onSave));
  yield takeLatest(ServicesActions.savePincode.type, genericErrorHandler(onSavePincode));
  yield takeLatest(ServicesActions.aptr.sync.type, genericErrorHandler(onSyncWithAPTR));
  yield takeLatest(ServicesActions.resetPincodes.type, genericErrorHandler(onResetPincodes));
  yield takeLatest(ServicesActions.addContact.type, genericErrorHandler(onAddContact));
  yield takeLatest(ServicesActions.deleteContact.type, genericErrorHandler(onDeleteContact));
  yield takeEvery(ServicesActions.uploadWhitelistFile.type, onUploadWhitelistFile);
  yield throttle(
    THROTTLE.keyboard,
    ServicesActions.whitelistEntriesList.fetch.type,
    genericErrorHandler(onFetchWhitelistEntryList),
  );
  yield throttle(THROTTLE.keyboard, ServicesActions.searchName.type, genericErrorHandler(onSearchName));
}
