import { Grid, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from '../../../../common/translations/translate';
import { default as React, FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ISorting } from '../../../../common';
import { Unavailable } from '../../../../common/components/formatters/unavailable';
import { CollapsablePanel } from '../../../../components';
import moment from 'moment';
import { DateFormat } from '../../../../types/date.types';
import { getCanShowMoreHistory, getRequestHistory, getTotalIsShown } from '../../../../store/selectors';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import AddIcon from '@material-ui/icons/Add';

interface IProps {
  changeSortingOrder: (key: string) => () => void;
  onClickShowAll: () => void;
  sorting: ISorting;
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableRow: {
    '& TableCell': {
      borderBottom: 'none',
    },
  },
});

export const RequestHistoryComponent: FunctionComponent<IProps> = ({ changeSortingOrder, onClickShowAll, sorting }) => {
  const C = useStyles();
  const requestHistory = useSelector(getRequestHistory);
  const canShowMoreHistory = useSelector(getCanShowMoreHistory);
  const totalIsShown = useSelector(getTotalIsShown);

  const _onClickShowAll = useCallback(() => {
    onClickShowAll();
  }, [onClickShowAll]);

  return (
    <CollapsablePanel title={translate('Requests.Detail.History.Title')}>
      <Grid container>
        <Grid item xs={12}>
          {requestHistory.length ? (
            <Table size={'small'} className={C.table}>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={sorting.direction}>
                    <TableSortLabel
                      direction={sorting.direction}
                      active={sorting.key === 'timestamp'}
                      onClick={changeSortingOrder('timestamp')}
                    >
                      {translate('Requests.Detail.History.Timestamp')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={sorting.direction}>
                    <TableSortLabel
                      direction={sorting.direction}
                      active={sorting.key === 'data'}
                      onClick={changeSortingOrder('data')}
                    >
                      {translate('Requests.Detail.History.Status')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={sorting.direction}>
                    <TableSortLabel
                      direction={sorting.direction}
                      active={sorting.key === 'userName'}
                      onClick={changeSortingOrder('userName')}
                    >
                      {translate('Requests.Detail.History.User')}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requestHistory.map((historyRecord) => (
                  <TableRow className={C.tableRow} key={Math.random()}>
                    <TableCell>{moment(historyRecord.timestamp).format(DateFormat.dateTime)}</TableCell>
                    <TableCell>{historyRecord.data}</TableCell>
                    <TableCell>
                      {historyRecord.userName || translate('Requests.Detail.History.AutomaticTransition')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Unavailable text={translate('Requests.Detail.History.Unavailable')} />
          )}
        </Grid>
        {canShowMoreHistory && !totalIsShown && (
          <Grid item xs={12} className={C.buttonContainer}>
            <Button.Green onClick={_onClickShowAll} startIcon={<AddIcon fontSize={'small'} />}>
              {translate('Requests.Detail.History.ShowMore')}
            </Button.Green>
          </Grid>
        )}
      </Grid>
    </CollapsablePanel>
  );
};
