import { translate } from '../../../../common/translations/translate';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { Icon } from '../../../../common/components/layout/icon.component';
import { Visible } from '../../../../common/components/layout/Visible.component';
import ModalDialog from '../../../../common/components/modal-dialog/modal-dialog.component';
import { Auth } from '../../../../components';
import { selectRequestDetailById } from '../../../../store/selectors';
import { IRequestAclActions, IRequestResetPincodeButtonComponent } from '../../../../types';

export const ResetPincodeButton: FC<IRequestResetPincodeButtonComponent> = ({ onResetPincode, requestId }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { acl: requestAcl } = useSelector(selectRequestDetailById)[requestId];

  const hideModal = () => setModalVisible(false);
  const showModal = () => setModalVisible(true);
  const onConfirm = () => {
    hideModal();
    onResetPincode();
  };

  return (
    <Auth acl={IRequestAclActions.editAssignedRequest}>
      <Visible visible={!!requestAcl.can_reset_pincode}>
        <div data-testid="resetPincodeButton">
          <Button.White
            iconButton
            onClick={showModal}
            style={{ marginLeft: '10px' }}
            tooltip={translate('Requests.Header.ResetPincode.ConfirmTitle')}
          >
            <Icon.ResetPincode />
          </Button.White>
        </div>
        <ModalDialog
          okButtonText={translate('yes')}
          onClose={hideModal}
          onConfirm={onConfirm}
          title={translate('Requests.Header.ResetPincode.ConfirmTitle')}
          visible={modalVisible}
        >
          <span>{translate('Requests.Header.ResetPincode.ConfirmDescription')}</span>
        </ModalDialog>
      </Visible>
    </Auth>
  );
};
