import { translate } from '../../common/translations/translate';
import * as React from 'react';
import { GenericTablePanel } from '../../common/components/table/GenericTablePanel.component';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useCallback, useEffect } from 'react';
import { CarFreeZonesActions } from '../../common/store/car-free-zones/carFreeZones.actions';
import {
  getGateList,
  getGatesSyncedAt,
  selectGatesTable,
} from '../../common/store/car-free-zones/carFreeZones.selectors';
import { IPaging, ISorting } from '../../common';
import { IGate } from '../../types';
import { useGeneralStyles } from '../../style/generalStyles';

interface IProps {
  id: string;
}

export const GateList: FC<IProps> = ({ id }) => {
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const gateList = useSelector(getGateList);
  const { sorting, paging } = useSelector(selectGatesTable);
  const syncedAt = useSelector(getGatesSyncedAt(id));

  useEffect(() => {
    dispatch(
      CarFreeZonesActions.gates.list.fetch({
        filters: { carFreeZoneId: id },
      }),
    );
  }, [dispatch, id]);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(CarFreeZonesActions.gates.list.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(CarFreeZonesActions.gates.list.fetch({ paging }));
    },
    [dispatch],
  );

  return (
    <GenericTablePanel<IGate>
      columnKey="id"
      className={G.marginTop20}
      title={
        <div className={G.flexRowSpaceBetween}>
          {translate('carFreeZones.krautli.details.gates.list.title')}
          <div className={G.infoText}>
            {translate('carFreeZones.krautli.details.gates.list.latestSync', { timestamp: syncedAt })}
          </div>
        </div>
      }
      columns={[
        { label: translate('carFreeZones.krautli.details.gates.list.id'), name: 'id', sortable: true },
        { label: translate('carFreeZones.krautli.details.gates.list.name'), name: 'name', sortable: true },
        { label: translate('carFreeZones.krautli.details.gates.list.gisId'), name: 'gisId', sortable: true },
        { label: translate('carFreeZones.krautli.details.gates.list.address'), name: 'address', sortable: true },
        { label: translate('carFreeZones.krautli.details.gates.list.sas'), name: 'sas', sortable: true },
      ]}
      labelUnavailable={translate('carFreeZones.krautli.details.gates.list.unavailable')}
      onChangeSorting={onChangeSorting}
      onChangePaging={onChangePaging}
      paging={paging}
      sorting={sorting}
      records={gateList}
    />
  );
};
