import { IMoovTemplate } from '../../../../types';
import { IById } from '../../../utils/normalized.util';
import { createReducer } from '@reduxjs/toolkit';
import { CarFreeZonesActions } from '../carFreeZones.actions';

type TState = IById<IMoovTemplate[]>;

export default createReducer<TState>({}, (builder) => {
  builder.addCase(
    CarFreeZonesActions.setTemplates.type,
    (state, { payload }: ReturnType<typeof CarFreeZonesActions.setTemplates>) => ({
      ...state,
      ...payload,
    }),
  );
});
