import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from '../../common/translations/translate';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Container } from '../../common';
import { Header } from '../../common/components/layout/Header.component';
import { Loader } from '../../common/components/layout/loader.component';
import { appUrls } from '../../common/config/url.constants';
import { CarFreeZonesActions } from '../../common/store/car-free-zones/carFreeZones.actions';
import {
  getCarFreeZone,
  getCarFreeZoneLinkedTemplates,
  getCarFreeZoneUnlinkedTemplates,
} from '../../common/store/car-free-zones/carFreeZones.selectors';
import { MoovActions } from '../../common/store/moov/moov.actions';
import { CarFreeZoneRequestListComponent } from './CarFreeZoneRequestList.component';
import { Details } from './Details.component';
import { GateList } from './GateList.component';
import { LinkTemplate } from './LinkTemplate.component';
import { CarFreeZoneMap } from './CarFreeZoneMap.component';

const useStyles = makeStyles({
  container: { marginTop: 20 },
  column: { margin: 10 },
});

interface IParams {
  id: string;
}

export const CarFreeZonePage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const C = useStyles();
  const { id }: IParams = useParams();
  const carFreeZone = useSelector(getCarFreeZone(id));
  const linkedTemplates = useSelector(getCarFreeZoneLinkedTemplates(id));
  const unlinkedTemplates = useSelector(getCarFreeZoneUnlinkedTemplates(id));

  useEffect(() => {
    dispatch(CarFreeZonesActions.fetch(id));
    dispatch(MoovActions.fetchAll());
  }, [dispatch, id]);

  const onLinkTemplate = useCallback(
    (templateId?: string) => {
      templateId && dispatch(CarFreeZonesActions.linkTemplate({ id, templateId }));
    },
    [dispatch, id],
  );

  const onUnlinkTemplate = useCallback(
    (templateId: string) => {
      dispatch(CarFreeZonesActions.unlinkTemplate({ id, templateId }));
    },
    [dispatch, id],
  );

  return (
    <>
      <Loader loading={!carFreeZone}>
        <Header backButtonUrl={appUrls.carFreeZones.krautliZones.base}>
          {translate('carFreeZones.krautli.details.title', { name: carFreeZone?.name })}
        </Header>
        <Container className={C.container}>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={6} data-testid="leftColumn">
              <Details {...carFreeZone} />
            </Grid>
            <Grid item xs={6} data-testid="rightColumn">
              <CarFreeZoneMap />
              <LinkTemplate
                linkedTemplates={linkedTemplates}
                unlinkedTemplates={unlinkedTemplates}
                onLinkTemplate={onLinkTemplate}
                onUnlinkTemplate={onUnlinkTemplate}
              />
              <GateList id={id} />
            </Grid>
          </Grid>
        </Container>
        <Container className={C.container}>
          <CarFreeZoneRequestListComponent id={id} />
        </Container>
      </Loader>
    </>
  );
};
