import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { appUrls } from '../../common/config/url.constants';
import { isBackofficeUser, isOnlyRoleUtilities, isUtilitiesUser } from '../../common/types/user';
import LocalStorage from '../../common/utils/local-storage';
import { extendPublicDomainIntake } from '../../common/utils/location.util';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { formatCurrency } from '../../common/utils/string.util';
import { IRootState } from '../../root.state';
import {
  CarFreeZoneType,
  CostState,
  DateFormat,
  ICluster,
  ICostOverview,
  IntakeTypes,
  IPermittedPlate,
  IRequestFormData,
  IRequestLocationForm,
  IRequestsApi,
  ISortedPermittedPlate,
  RequestReason,
  RequestStates,
} from '../../types';

export const selectUser = (store: IRootState) => store.user.user;
export const getIsOnlyRoleUtilities = createSelector([selectUser], isOnlyRoleUtilities);
export const getIsBackOfficeUser = createSelector([selectUser], isBackofficeUser);

export const selectRequestAllIds = (store: IRootState) => store.requests.list.allIds;
export const selectRequestById = (store: IRootState) => store.requests.list.byId;
export const selectRequestConflictsById = (store: IRootState) => store.requests.conflicts.byId;
export const selectCopyRequest = (store: IRootState) => store.requests.copyRequest;
export const selectDraftIds = (store: IRootState) => store.requests.draftIds;
export const selectCurrentConflictKey = (store: IRootState) => store.requests.currentConflictKey;
export const selectRequestDetailById = (store: IRootState) => store.requests.detail.byId;
export const selectEditableFields = (store: IRootState) => store.requests.detail.editableFields;
export const selectRequestExporting = (store: IRootState) => store.requests.exporting;
export const selectRequestLoading = (store: IRootState) => store.requests.loading;
export const selectRequestSaving = (store: IRootState) => store.requests.saving;
export const selectRequestLocationDetail = (store: IRootState) => store.requests.locationDetail;
export const selectRequestMessages = (store: IRootState) => store.requests.requestMessagesList;
export const selectRequestsTable = (store: IRootState) => store.requests.list.table;
export const selectRequestsLoading = (store: IRootState) => store.requests.list.loading;
export const selectRequestMetadata = (store: IRootState) => store.requests.metadata;
export const selectRequestExtensions = (store: IRootState) => store.requests.requestExtensions;
export const selectLocationDetail = (store: IRootState) => store.requests.locationDetail;
export const selectRequestHistory = (store: IRootState) => store.requests.history.requestHistory.history;
export const selectRequestPermitHistory = (store: IRootState) => store.requests.history.permitHistory;
export const selectRequestHistoryTotal = (store: IRootState) => store.requests.history.requestHistory.total;
export const selectRequestSuggestions = (store: IRootState) => store.requests.suggestions;
export const selectRequestGeoCodesById = (store: IRootState) => store.requests.geocodesById;
export const selectReverseGeocodesById = (store: IRootState) => store.requests.reverseGeocodesById;
export const selectRequestMessageAttachments = (store: IRootState) => store.requests.requestMessageAttachments;
export const selectAlreadyAssigned = (store: IRootState) => store.requests.alreadyAssigned;
export const selectRequestReasonFilters = (store: IRootState) => store.requests.requestReasonFilters;
export const selectGeoCodeCorrect = (store: IRootState) => store.requests.isGeoCodeCorrect;
export const selectWithUtilityCompanyLimitations = (store: IRootState) =>
  store.requests.request?.withUtilityCompanyLimitations;

export const getRequests = createAllEntitiesSelector(selectRequestAllIds, selectRequestById);
export const getDrafts = createAllEntitiesSelector(selectDraftIds, selectRequestById);

export const getUserResponsibleId = createSelector([selectRequestsTable, selectUser], ({ filters }, currentUser) => {
  const { toBePickedUp, mine, userResponsible } = filters;
  const storedUserString = LocalStorage.get('userId') || undefined;
  const storedUserId = storedUserString && parseInt(storedUserString);

  if (toBePickedUp) {
    return '';
  }
  if (mine) {
    return currentUser ? currentUser.id : storedUserId;
  }
  if (userResponsible) {
    return userResponsible;
  }
});

export const getRequestApiParams = createSelector(
  [selectRequestsTable, getUserResponsibleId, getIsOnlyRoleUtilities],
  ({ filters, paging, sorting }, userResponsible, isOnlyRoleUtilities) => {
    const {
      fromDate,
      untilDate,
      urgent,
      messageStates,
      requestStates,
      requestTypes,
      requestReasons,
      priorities,
      paymentStates,
      search,
      parkingBanStates,
      requestedFrom,
      requestedTo,
      streetName,
      extensionStates,
      carFreeZone,
      carFreeZoneState,
    } = filters;

    let params: IRequestsApi = {
      page: paging.page,
      page_size: paging.pageSize,
      sort: sorting.key,
      order: sorting.direction,
    };

    if (!(search?.bypassOtherFilters && search?.value)) {
      if (fromDate) params.from_date = fromDate;
      if (untilDate) params.until_date = untilDate;
      if (messageStates) params.message_states = messageStates;
      if (urgent) params.emergency_procedure = true;
      if (userResponsible !== undefined) params.user_responsible = userResponsible; // check for undefined explicitly because it can be empty string for unassigned requests
      if (requestStates) params.states = requestStates;
      if (requestTypes) params.publicdomainintake_types = requestTypes;
      if (isOnlyRoleUtilities) {
        params.reasons = [RequestReason.utility_request];
      } else if (requestReasons) {
        params.reasons = requestReasons;
      }
      if (priorities) params.priorities = priorities;
      if (paymentStates) params.payment_states = paymentStates;
      if (parkingBanStates) params.publicdomainintake_states = parkingBanStates;
      if (requestedFrom) params.created_from_date = requestedFrom;
      if (requestedTo) params.created_until_date = requestedTo;
      if (streetName) params.street_name = streetName;
      if (extensionStates) params.extension_states = extensionStates;
      if (carFreeZone) params.car_free_zone = carFreeZone;
      if (!!carFreeZoneState?.length) {
        params.car_free_zone_state = carFreeZoneState;
      }
    }

    if (search?.value) params[search.field] = search.value;

    return params;
  },
);

export const getAllRequestMessageStates = createSelector([selectRequestMetadata], (metadata) =>
  metadata && metadata.messageStates
    ? metadata.messageStates.map(({ messageState, name }) => ({ value: messageState, label: name }))
    : [],
);

export const getAllRequestStates = createSelector([selectRequestMetadata], (metadata) =>
  metadata && metadata.states ? metadata.states.map(({ state, name }) => ({ value: state, label: name })) : [],
);

export const getAllRequestTypes = createSelector([selectRequestMetadata], (metadata) =>
  metadata && metadata.types ? metadata.types.map(({ type, name }) => ({ value: type, label: name })) : [],
);

export const getAllRequestReasons = createSelector([selectRequestMetadata], (metadata) =>
  metadata && metadata.reasons ? metadata.reasons.map(({ reason, name }) => ({ value: reason, label: name })) : [],
);

export const getRequestReason = (reasonId?: string) =>
  createSelector([selectRequestMetadata], (metadata) =>
    metadata && metadata.reasons ? metadata.reasons.find((reason) => reason.reason === reasonId) : null,
  );

export const getAllRequestCountries = createSelector([selectRequestMetadata], (metadata) =>
  metadata && metadata.countries
    ? metadata.countries.map(({ name, country }) => ({ value: country, label: name }))
    : [],
);

export const getRequestDetailsForId = (requestId: string) =>
  createSelector([selectRequestDetailById], (requests) => requests[requestId]);

export const getRequestsDetails = (requestId: string) =>
  createSelector(
    [getRequestDetailsForId(requestId), getRequestAssignedUser(requestId)],
    (request, { assignedToMe }) => {
      if (!request) return null;

      const {
        acl,
        billingMailedAt,
        cluster,
        comment,
        cost,
        createdBy,
        dateUntil,
        externalReference,
        emergencyProcedure,
        eventName,
        id,
        manualPlacementUrl,
        needsPayment,
        needsPlacement,
        numberOfPlacedSigns,
        numberOfRequestedSigns,
        originalDateUntil,
        payments,
        pincode,
        publicDomainIntakes,
        priority,
        reason,
        reasonForApproval,
        reasonForEdit,
        reasonForRejection,
        referenceId,
        state,
        requestAddress,
        permit,
        utilityCompanyRequested,
        entireDay,
        sgwRequestId,
        preferredMoment,
        withUtilityCompanyLimitations,
        invoice,
      } = request;

      return {
        acl,
        billingMailedAt,
        cluster: Array.isArray(cluster) ? null : (cluster as ICluster),
        clusterLabelVisible:
          (reason.reason === 'construction_zone' && (!state.transitions.includes('approved') || !assignedToMe)) ||
          (!!request.sgwRequestId && !!request.cluster),
        clusterSelectVisible: !!(
          reason.reason === 'construction_zone' &&
          state.transitions.includes('approved') &&
          assignedToMe
        ),
        comment,
        cost,
        createdByUrl: createdBy ? appUrls.users.detail(`${createdBy.id}`, true) : null,
        createdByName: createdBy ? createdBy.fullName : null,
        dateUntil,
        emergencyProcedure,
        eventName,
        externalReference,
        id,
        isUtilityRequest: !!utilityCompanyRequested,
        isConstructionZone: reason.reason === 'construction_zone',
        manualPlacementUrl,
        needsPayment,
        needsPlacement,
        numberOfParkingSpots:
          cost && cost.costComponents
            ? cost.costComponents.reduce((acc, curr) => acc + (curr.numberOfParkingSpots || 0), 0)
            : 0,
        numberOfPlacedSigns,
        numberOfRequestedSigns,
        onlyWeekdays: request.onlyOnWeekdays || false,
        originalDateUntil,
        payments,
        period: `${moment(request.dateFrom).format(DateFormat.date)} - ${moment(request.dateUntil).format(
          DateFormat.date,
        )}`,
        permit,
        pincode: pincode?.value,
        preferredMoment,
        priority: priority.priority,
        publicDomainIntakes,
        reason: reason.name,
        reasonForApproval,
        reasonForEdit,
        reasonForRejection: state.state === 'rejected' ? reasonForRejection : null,
        referenceId,
        state,
        requestAddress,
        showEmailRetribution:
          publicDomainIntakes.map((intake) => intake.type.type).includes(IntakeTypes.minorconstructionsiteintake) &&
          state.state === RequestStates.approved,
        timeBetween: `${request.timeFrom} - ${request.timeUntil}`,
        totalToBePaid: (payments || []).reduce((sum, payment) => sum + payment.amountToBePaid, 0),
        totalPaid: (payments || []).reduce((sum, payment) => sum + payment.amountPaid, 0),
        canSendEmail: [RequestStates.approved, RequestStates.in_effect].includes(state.state),
        entireDay,
        sgwRequestId,
        withUtilityCompanyLimitations,
        invoice,
      };
    },
  );

export const getRequestExtensions = createSelector([selectRequestExtensions], (extensions) =>
  extensions
    ? extensions.map((extension) => ({
        ...extension,
        totalPaid: (extension.payments || []).reduce((sum, payment) => sum + payment.amountPaid, 0),
      }))
    : null,
);

export const getShowNewPaymentWarning = (requestId: string) =>
  createSelector(
    [getRequestsDetails(requestId)],
    (details) =>
      details !== null &&
      details.needsPayment &&
      details.cost !== undefined &&
      details.totalToBePaid !== undefined &&
      details.cost?.state.state === CostState.informative &&
      details.cost.totalCost >
        (details?.payments || []).reduce((sum: number, payment) => sum + payment.amountToBePaid, 0),
  );

export const getRequestLocationsForId = (requestId: string) =>
  createSelector([getRequestDetailsForId(requestId)], (request) => {
    if (!request) return [];

    const publicDomainIntakes = request.publicDomainIntakes || [];
    return publicDomainIntakes.map((intake) => extendPublicDomainIntake(intake));
  });

export const getRequestLocationsForm = (requestId: string) =>
  createSelector([getRequestsDetails(requestId)], (request): IRequestLocationForm[] => {
    if (!request) return [];

    const { publicDomainIntakes = [] } = request;
    return publicDomainIntakes.map(({ type, ...intake }) => {
      return {
        ...intake,
        carFreeZone: intake.carFreeZone?.id,
        carFreeZoneGateEntrance: intake.carFreeZoneGateEntrance?.id || null,
        carFreeZoneGateExit: intake.carFreeZoneGateExit?.id || null,
        type: type.type,
      };
    });
  });

export const getRequestCostOverview = (requestId: string) =>
  createSelector([getRequestsDetails(requestId)], (request): ICostOverview | undefined => {
    if (!!request?.cost && request.needsPayment) {
      const { costComponents, fixedAdministrationCost, fixedEmergencyCost, numberOfDays, totalCost, variableCost } =
        request.cost;
      const numberOfParkingSpots = costComponents
        .filter(({ numberOfParkingSpots }) => !!numberOfParkingSpots)
        .reduce((prev, { numberOfParkingSpots }) => prev + numberOfParkingSpots, 0);
      return {
        fixedAdministrationCost: formatCurrency(fixedAdministrationCost),
        fixedEmergencyCost: formatCurrency(fixedEmergencyCost),
        numberOfDays,
        numberOfParkingSpots,
        totalCost: formatCurrency(totalCost),
        variableCost: formatCurrency(variableCost),
      };
    }
  });

export const getLicensePlatesByDateForLocation = (requestId: string, locationId: number) =>
  createSelector([getRequestsDetails(requestId)], (request) => {
    const location = request?.publicDomainIntakes.find(({ id }) => id === locationId);

    const permittedPlates = location?.permittedPlates || [];

    return permittedPlates.reduce<ISortedPermittedPlate[]>(
      (accumulator: ISortedPermittedPlate[], currentValue: IPermittedPlate) => {
        const { startDate } = currentValue;
        const foundValue = accumulator.find(({ startDate }) => startDate === currentValue.startDate);

        if (foundValue) {
          foundValue.plates.push(currentValue);
        } else {
          accumulator.push({
            startDate,
            plates: [currentValue],
          });
        }
        return accumulator;
      },
      [],
    );
  });

// this logic is taken from the old angular code
export const getIsAddingLicensePlatesAllowed = (requestId: string, locationId: number) =>
  createSelector([getRequestsDetails(requestId), getIsBackOfficeUser], (request, isBackofficeUser) => {
    const location = request?.publicDomainIntakes.find(({ id }) => id === locationId);

    if (!location || !location.carFreeZone || !request) {
      return false;
    }

    if ([RequestStates.submitted, RequestStates.draft].includes(request.state.state)) {
      return true;
    }
    if (isBackofficeUser && location.carFreeZone.type === CarFreeZoneType.anpr) {
      const dateUntilAllowedForBackoffice = moment(request.dateUntil).add(9, 'days').format('YYYY-MM-DD');
      return moment().isSameOrBefore(dateUntilAllowedForBackoffice, 'day');
    }
    if (isBackofficeUser && location.carFreeZone.type !== CarFreeZoneType.anpr) {
      return true;
    }
    if (!isBackofficeUser && location.carFreeZone.type === CarFreeZoneType.anpr) {
      const dateUntilAllowedForUsers = moment(request.dateUntil).add(2, 'days').format('YYYY-MM-DD');
      return moment().isSameOrBefore(dateUntilAllowedForUsers, 'day');
    }
    return false;
  });

export const getIsRequestEditable = (requestId: string) =>
  createSelector(
    [getRequestsDetails(requestId), getRequestAssignedUser(requestId)],
    (request, { assignedToMe }) =>
      !!request &&
      assignedToMe &&
      ![RequestStates.canceled, RequestStates.rejected, RequestStates.closed].includes(request.state.state),
  );

export const getRequestAssignedUser = (requestId: string) =>
  createSelector([selectRequestDetailById, selectUser], (requests, user) => {
    const { userResponsible } = requests[requestId] || {};
    return {
      assignedUser: userResponsible,
      assignedToMe: !!userResponsible && !!user && userResponsible.id === user.id,
    };
  });

export const getCarFreeZoneIntakes = (requestId: string) =>
  createSelector([selectRequestDetailById], (requests) => {
    const { publicDomainIntakes = [] } = requests[requestId];
    return {
      allCarFreeZonesProvided: !publicDomainIntakes.find(
        (intake) => intake.type.type === IntakeTypes.carfreezoneintake && !intake.carFreeZone,
      ),
    };
  });

export const getRequestContactInfo = (requestId: string) =>
  createSelector([selectRequestDetailById], (requests) => {
    const {
      birthday,
      bus,
      city,
      companyId,
      companyName,
      country,
      emailAddress,
      firstName,
      ibanNumber,
      lastName,
      phoneNumber,
      ssn,
      street,
      streetNumber,
      userRequested,
      utilityCompanyRequested,
      zipCode,
    } = requests[requestId];

    return {
      address: `${street} ${streetNumber}${bus ? `/${bus}` : ''}`,
      birthday: moment(birthday, 'YYYY-MM-DD').format(DateFormat.date),
      city: `${zipCode} ${city}`,
      companyId,
      companyName,
      country: country.country !== 'BE' ? country.name : undefined,
      emailAddress,
      ibanNumber,
      isBelgian: country.country === 'BE',
      name: `${firstName} ${lastName}`,
      phoneNumber,
      ssn,
      url: userRequested?.email === emailAddress ? appUrls.users.detail(`${userRequested.id}`, true) : undefined,
      utilityCompany: {
        hasUtilityRole: isUtilitiesUser(userRequested),
        name: utilityCompanyRequested ? utilityCompanyRequested.name : '',
      },
    };
  });

export const getEditedRequest = (requestId: string, formData: IRequestFormData) =>
  createSelector([selectRequestDetailById], (requests) => {
    if (!requests[requestId]) return undefined;

    const { publicDomainIntakes, userRequested, userResponsible, state, priority, cluster, ...requestFields } =
      requests[requestId];
    return {
      ...requestFields,
      publicDomainIntakes: publicDomainIntakes.map((intake) => {
        const {
          type,
          carFreeZone,
          carFreeZoneGateEntrance,
          carFreeZoneGateExit,
          state,
          reviewingState,
          ...intakeFields
        } = intake;
        return {
          ...intakeFields,
          type: type.type,
          carFreeZone: carFreeZone?.id,
          carFreeZoneGateEntrance: carFreeZoneGateEntrance?.id || null,
          carFreeZoneGateExit: carFreeZoneGateExit?.id || null,
        };
      }),
      userRequested: userRequested?.id,
      userResponsible: userResponsible?.id,
      state: state.state,
      ...formData,
    };
  });

export const getRequestNotes = (requestId: string) =>
  createSelector([selectRequestDetailById], (requests) =>
    requests[requestId] && requests[requestId].backofficeNotes ? requests[requestId].backofficeNotes : '',
  );
export const getRequestReviewInfo = (requestId: string) =>
  createSelector(
    [getRequestAssignedUser(requestId), getCarFreeZoneIntakes(requestId), getRequestsDetails(requestId)],
    ({ assignedToMe }, { allCarFreeZonesProvided }, request) => {
      const isButtonVisible = (state: RequestStates): boolean =>
        assignedToMe && !!request && !!request.state && request.state.transitions.includes(state);

      const inState = (state: RequestStates) => !!request && !!request.state && request.state.state === state;

      const canApprove = () =>
        allCarFreeZonesProvided && !!request && (!request.isConstructionZone || !!request.cluster);

      const getTooltip = (): string | undefined => {
        if (!canApprove()) {
          return allCarFreeZonesProvided ? 'ClusterSelect' : 'CarFreeZone';
        }
      };

      return {
        canApprove: canApprove(),
        showApprove: isButtonVisible(RequestStates.approved),
        showReject: isButtonVisible(RequestStates.rejected),
        showReview: isButtonVisible(RequestStates.reviewing) && inState(RequestStates.rejected),
        tooltip: getTooltip(),
      };
    },
  );

export const getRequestAttachments = (requestId: string) =>
  createSelector([getRequestDetailsForId(requestId)], (request) => (request ? request.attachments : []));

export const getRequestAttachmentsForPatch = (requestId: string) =>
  createSelector([getRequestAttachments(requestId)], (attachments) =>
    attachments.map(({ file, name }) => ({ file, name })),
  );

export const getRequestHistory = createSelector([selectRequestHistory], (history) =>
  history ? history.filter(({ type }) => type === 'Status') : [],
);

export const getRequestPermitHistory = createSelector([selectRequestPermitHistory], (history) =>
  history.map((historyRecord) => ({
    ...historyRecord,
    disabled: ['_failed', '_pending'].some((substring) => historyRecord.filename.includes(substring)),
  })),
);

export const getCanShowMoreHistory = createSelector([selectRequestHistoryTotal], (total) => total > 8);

export const getTotalIsShown = createSelector(
  [selectRequestHistoryTotal, selectRequestHistory],
  (total, history) => total === history?.length,
);

export const getRequestSuggestions = createSelector([selectRequestSuggestions], (suggestions): string[] =>
  suggestions.map(({ label }) => label),
);

export const getIsGeoCodeFetched = (id: string) =>
  createSelector([selectRequestGeoCodesById], (geoCodes): boolean => !!geoCodes[id]);

export const getEditableFieldsIncludes = (field: string) =>
  createSelector([selectEditableFields], (editableFields) => editableFields.includes(field));

export const getRequestAcl = (requestId: string) =>
  createSelector([getRequestDetailsForId(requestId)], (request) => request?.acl);
