import { Button, MenuItem, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Form, Formik, FormikProps } from 'formik';
import { translate } from '../../common/translations/translate';
import moment from 'moment';
import * as React from 'react';
import { FormattedDateTime, Panel, PanelAction } from '../../common';
import { IUser, userHasAtLeastOneOfAcls } from '../../common/types/user';
import { IModulesAclActions } from '../../types';
import { MODULE_STATES } from '../signs.constants';
import { IModule } from '../types/module';
import { IModuleState } from '../types/module-state';
import { IModuleUpdate } from '../types/module-update';
import { DeleteModuleDialog } from './dialog/delete-module-dialog.component';

interface IProps {
  module: IModule;
  updateModule?: (module: IModuleUpdate) => void;
  states?: IModuleState[];
  readonly?: boolean;
  deleteModule?: () => void;
  user: IUser | null;
}

interface IState {
  inEditMode: boolean;
  isDeleteModulePopup: boolean;
}

export class ModuleGeneral extends React.Component<IProps, IState> {
  public state: IState = {
    inEditMode: false,
    isDeleteModulePopup: false,
  };

  public render(): React.ReactNode {
    return (
      <Panel title={translate('DetailModule')}>
        {!this.props.readonly && <PanelAction icon={<Edit />} onClick={this.onClickEditButton} />}
        <Formik
          initialValues={
            {
              id: this.props.module.id,
              state: this.props.module.state.id,
            } as IModuleUpdate
          }
          render={this.renderForm}
          onSubmit={this.onSubmit}
        />
        {this.state.isDeleteModulePopup &&
          userHasAtLeastOneOfAcls(this.props.user, [IModulesAclActions.editModule]) && (
            <DeleteModuleDialog onClose={this.onDeleteDialogClose} onConfirm={this.onDeleteDialogConfirm} />
          )}
      </Panel>
    );
  }

  private renderForm = (props: FormikProps<IModuleUpdate>) => {
    const { module } = this.props;
    return (
      <Form noValidate aria-label="module-form">
        <dl>
          <dt>{translate('ID')}</dt>
          <dd>{module.id}</dd>
          <dt>{translate('Profile')}</dt>
          <dd>
            {this.state.inEditMode ? (
              <TextField
                value={props.values.state}
                fullWidth={true}
                select={true}
                onChange={props.handleChange}
                id="state"
                name="state"
              >
                {this.props.states!.map((state: IModuleState) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              module.state.name
            )}
          </dd>
          <dt>{translate('ApplicationVersion')}</dt>
          <dd>{module.applicationVersion}</dd>
          <dt>{translate('LastHeartBeat')}</dt>
          <dd
            data-testid={this.lastHeartBeatCritical() && 'danger'}
            className={this.lastHeartBeatCritical() ? 'danger' : ''}
          >
            <FormattedDateTime dateTime={module.lastHeartBeat} />
            <span style={{ paddingLeft: 10 }} id="gps-signal">
              ({translate('GPSSignal')}: {module.noGps ? translate('NoConnection') : translate('ok')})
            </span>
          </dd>
        </dl>
        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
          {this.state.inEditMode && module.acl.canBeDeleted && (
            <Button variant="contained" color="primary" onClick={this.onDeleteModuleClick}>
              {translate('DeleteModule')}
            </Button>
          )}
          {this.state.inEditMode && (
            <Button variant="contained" color="primary" type="submit">
              {translate('SaveChanges')}
            </Button>
          )}
        </div>
      </Form>
    );
  };

  private onDeleteModuleClick = () => {
    this.setState({
      isDeleteModulePopup: true,
    });
  };

  private onDeleteDialogConfirm = () => {
    this.props.deleteModule!();
  };

  private onDeleteDialogClose = () => {
    this.setState({
      isDeleteModulePopup: false,
    });
  };

  private onClickEditButton = () => {
    this.setState({
      inEditMode: !this.state.inEditMode && !this.props.readonly,
    });
  };

  private onSubmit = (module: IModuleUpdate) => {
    this.setState({
      inEditMode: false,
    });
    this.props.updateModule!(module);
  };

  private lastHeartBeatCritical(): boolean {
    if (this.props.module.state.id === MODULE_STATES.STORAGE) {
      return moment(this.props.module.lastHeartBeat).isBefore(moment().subtract(28, 'hours'));
    } else {
      return moment(this.props.module.lastHeartBeat).isBefore(moment().subtract(8, 'hours'));
    }
  }
}
