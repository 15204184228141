import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkOrderItemsActions } from '../../../../common';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { Auth, CollapsablePanel } from '../../../../components';
import { useAuthorization } from '../../../../hooks';
import { RequestLocationsActions } from '../../../../store/actions';
import { selectLocationDetail } from '../../../../store/selectors';
import { IPublicDomainIntakeExtended, IWorkOrderItemAclActions, IWorkOrderItemPost } from '../../../../types';
import { PlacementComponent } from './placement.component';
import { translate } from '../../../../common/translations/translate';

interface IProps {
  requestId: string;
  location?: IPublicDomainIntakeExtended;
}

export const PlacementContainer: FunctionComponent<IProps> = ({ location, requestId }) => {
  const dispatch = useDispatch();
  const detail = useSelector(selectLocationDetail);
  const { isAclUser, isAuthorized: canViewWorkOrder } = useAuthorization(IWorkOrderItemAclActions.viewWorkOrderItem);
  const hasAccess = isAclUser && canViewWorkOrder;

  const onPostWorkOrder = (payload: IWorkOrderItemPost) => {
    dispatch(WorkOrderItemsActions.post(payload));
  };

  const onDeleteWorkOrder = (id: number) => {
    dispatch(WorkOrderItemsActions.delete(id));
  };

  useEffect(() => {
    if (hasAccess && location) {
      dispatch(RequestLocationsActions.fetch({ id: requestId, locationId: `${location.id}` }));
    }
  }, [location, requestId, dispatch, hasAccess]);

  useEffect(() => {
    if (hasAccess) {
      dispatch(WorkOrderItemsActions.fetchCreatableTypes());
    }
  }, [hasAccess, dispatch]);

  return (
    <Auth acl={IWorkOrderItemAclActions.viewWorkOrderItem}>
      <Visible visible={hasAccess && !!location}>
        <CollapsablePanel title={translate('Requests.Detail.Placement.Title')}>
          <PlacementComponent
            detail={detail}
            parkingBanIntakeId={location!.id}
            onDeleteWorkOrder={onDeleteWorkOrder}
            onPostWorkOrder={onPostWorkOrder}
          />
        </CollapsablePanel>
      </Visible>
    </Auth>
  );
};
