import { createReducer, Draft, PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { IPaging, IPartialPagedTable, ISorting, payloadReducer } from '../../common';
import { IFilter } from '../../common/types/filter';
import { extractIdsFromArray, IById, IWithId, normalizeArray } from '../../common/utils/normalized.util';
import { ICreatePagedTableReducerProps } from '../../types';
import { initialPaging } from '../../common/config/paging.constants';

const createPagingReducer = (pagingTypes: string[], resetTypes: string[] = []) =>
  createReducer<IPaging>(initialPaging, (builder) => {
    pagingTypes.forEach((type) => {
      builder.addCase(type, payloadReducer<IPaging>(initialPaging));
    });
    resetTypes.forEach((type) => {
      builder.addCase(type, () => initialPaging);
    });
  });

const createSortingReducer = (type: string, initialSorting: ISorting) =>
  createReducer<ISorting>(initialSorting, (builder) => {
    builder.addCase(type, payloadReducer<ISorting>(initialSorting));
  });

export const createTableReducers = (
  pagingType: string[],
  sortingType: string,
  initialSorting: ISorting,
  filteringType: string = 'filter',
  initialFiltering: IFilter = {},
) =>
  combineReducers({
    paging: createPagingReducer(pagingType, [sortingType, filteringType]),
    sorting: createSortingReducer(sortingType, initialSorting),
    filters: createFilteringReducer(filteringType, initialFiltering),
  });

const createFilteringReducer = (type: string, initialSorting: IFilter) =>
  createReducer<IFilter>(initialSorting, (builder) => {
    builder.addCase(type, payloadReducer<IFilter>(initialSorting));
  });

export const createTableParamsReducer = <F = {}>(initialState: IPartialPagedTable<F>, actions: string[]) =>
  createReducer<IPartialPagedTable<F>>(initialState, (builder) => {
    actions.forEach((type) => {
      builder.addCase(type, (state, { payload }: PayloadAction<IPartialPagedTable<Draft<F>>>) => {
        return {
          ...state,
          ...payload,
          paging: payload.filters || payload.sorting ? initialState.paging : payload.paging || state.paging,
        };
      });
    });
  });

const createAllIdsReducer = <Entity>(setEntitiesAction: string, removeEntityActions: string[] = []) =>
  createReducer<string[]>([], (builder) => {
    builder.addCase(setEntitiesAction, (_, { payload }: PayloadAction<Entity[]>) => extractIdsFromArray(payload));
    removeEntityActions.forEach((removeAction) => {
      builder.addCase(removeAction, (state, { payload }: PayloadAction<string>) =>
        state.filter((id) => id !== payload),
      );
    });
  });

export const createByIdReducer = <Entity>(
  addEntitiesActions: string[],
  addEntityActions?: string[],
  removeEntityActions?: string[],
) => {
  return createReducer<IById<Entity>>({}, (builder) => {
    addEntitiesActions.forEach((action) =>
      // @ts-ignore
      builder.addCase(action, (state, { payload }: PayloadAction<Entity[]>) => ({
        ...state,
        ...normalizeArray<Entity>(payload as IWithId<Entity>[]),
      })),
    );

    addEntityActions?.forEach((action) =>
      // @ts-ignore
      builder.addCase(action, (state, { payload }: PayloadAction<IWithId<Entity>>) => ({
        ...state,
        [payload.id]: payload,
      })),
    );

    removeEntityActions?.forEach((removeAction) => {
      builder.addCase(removeAction, (state, { payload }: PayloadAction<string>) => {
        const { [payload]: removedId, ...newState } = state;
        return newState;
      });
    });
  });
};

const createSetBooleanReducer = (setTrueTypes: string[], setFalseTypes: string[] = [], payloadType?: string) =>
  createReducer<boolean>(false, (builder) => {
    setTrueTypes.forEach((type) => {
      builder.addCase(type, () => true);
    });

    setFalseTypes.forEach((type) => {
      builder.addCase(type, () => false);
    });
    if (payloadType) {
      builder.addCase(payloadType, (_, { payload }: PayloadAction<boolean>) => payload);
    }
  });

export const createPagedTableReducer = <Entity, Filter = {}>(
  props: ICreatePagedTableReducerProps<Filter>,
  storeEntities = true,
) =>
  combineReducers({
    allIds: createAllIdsReducer<Entity>(props.setAllIdsAction, props.removeEntityActions),
    ...(storeEntities && {
      byId: createByIdReducer<Entity>(
        props.addEntitiesActions || [],
        props.addEntityActions,
        props.removeEntityActions,
      ),
    }),
    table: createTableParamsReducer<Filter>(props.initialParamsState, props.setParamsActions),
    loading: createSetBooleanReducer(
      [props.fetchAction],
      [props.setAllIdsAction, ...(props.addEntitiesActions || []).filter((action) => action !== props.setAllIdsAction)],
      props.setLoadingAction,
    ),
  });
