import { Typography } from '@material-ui/core';
import { translate } from '../../translations/translate';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import ModalDialog from './modal-dialog.component';
import { Visible } from '../layout/Visible.component';

interface IProps {
  onClose: () => void;
  onConfirm: () => void;
  okButtonText?: string;
  title: string;
  refuseButtonText?: string;
  visible: boolean;
}

export const ConfirmDialog: FunctionComponent<PropsWithChildren<IProps>> = ({ children, visible, ...props }) => (
  <Visible visible={visible}>
    <ModalDialog {...props} okButtonText={props.okButtonText || translate('yes')}>
      <Typography>{children}</Typography>
    </ModalDialog>
  </Visible>
);
