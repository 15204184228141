import { SaveOutlined } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { Popup } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import {
  changeOrderReorderingWorkOrder,
  setReorderingMarkerPopup,
  toggleCheckReorderingWorkOrder,
} from '../store/reordering.actions';
import { IReorderingWorkOrderItem } from '../types/reordering-work-order-item';
import { translate } from '../../common/translations/translate';

interface IProps {
  workOrderItem: IReorderingWorkOrderItem;
}

const PopupItem: React.FunctionComponent<{ label: string; text: any }> = (props) => {
  return (
    <>
      <strong>{props.label}:</strong>&nbsp;{props.text}
      <br />
    </>
  );
};

export const ReorderingMapPopup: React.FunctionComponent<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const woi = props.workOrderItem;
  const [sequence, setSequence] = useState<number>(woi.sequence);

  const getMarkerMessageHeaderInfo = (item: IReorderingWorkOrderItem): React.ReactNode => {
    if (item.address) {
      return <>{item.address}</>;
    }
  };

  const onSequenceChange = (e: React.ChangeEvent<any>) => {
    const val = e.target.value;
    if ((/^\d+$/.test(val) && val >= 1 && val <= 999) || val === '') {
      setSequence(val);
    }
  };

  const onSequenceKeyDown = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      onSaveClick();
    }
  };

  const onSaveClick = () => {
    dispatch(changeOrderReorderingWorkOrder({ order: sequence - 1, workOrderItem: woi }));
    if (!woi.checked) {
      dispatch(toggleCheckReorderingWorkOrder(woi));
    }
    dispatch(setReorderingMarkerPopup(null));
  };

  return (
    <>
      <Popup>
        <div className={'text-center'}>
          <h3 className={'text-center'}>{getMarkerMessageHeaderInfo(woi)}</h3>

          {woi.numberOfSigns && <PopupItem label={translate('NumberOfSigns')} text={woi.numberOfSigns} />}
          {woi.hasSmartSigns && (
            <PopupItem label={translate('DigitalSign')} text={woi.hasSmartSigns ? translate('yes') : translate('no')} />
          )}
          <div style={{ paddingTop: 20, display: 'flex' }}>
            <div style={{ flex: 1 }}>&nbsp;</div>

            <input
              className="reordering-popup-sequence"
              type="text"
              value={sequence}
              onChange={onSequenceChange}
              onKeyDown={onSequenceKeyDown}
            />

            <div className="reordering-popup-save" onClick={onSaveClick}>
              <SaveOutlined fontSize={'small'} style={{ display: 'inline-block', position: 'relative', top: 4 }} />
              &nbsp;&nbsp;&nbsp;{translate('Save')}
            </div>
            <div style={{ flex: 1 }}>&nbsp;</div>
          </div>
        </div>
      </Popup>
    </>
  );
};
