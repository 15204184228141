import { Button, MenuItem, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Form, Formik, FormikProps } from 'formik';
import { translate } from '../../common/translations/translate';
import moment from 'moment';
import * as React from 'react';
import { FormattedDate, FormattedDateTime, Panel, PanelAction } from '../../common';
import { Auth } from '../../components';
import { IModulesAclActions } from '../../types';
import { IModule } from '../types/module';
import { IModuleBatch } from '../types/module-batch';
import { IModulePlace } from '../types/module-place';
import { IModuleUpdate } from '../types/module-update';

interface IProps {
  module: IModule;
  places: IModulePlace[];
  updateModule: (module: IModuleUpdate) => void;
  batches: IModuleBatch[];
}

interface IState {
  inEditMode: boolean;
}

export class ModuleMaintenance extends React.Component<IProps, IState> {
  public state: IState = { inEditMode: false };

  public render(): React.ReactNode {
    return (
      <Panel title={translate('maintenance')}>
        <Auth acl={IModulesAclActions.editModule}>
          <PanelAction icon={<Edit />} onClick={this.onClickEditButton} />
        </Auth>
        <Formik
          initialValues={
            {
              batch: this.props.module.batch?.id,
              id: this.props.module.id,
              place: this.props.module.place.id,
            } as IModuleUpdate
          }
          render={this.renderForm}
          onSubmit={this.onSubmit}
        />
      </Panel>
    );
  }

  private renderForm = (props: FormikProps<IModuleUpdate>) => {
    const firstValidMonth = moment().startOf('month').format('YYYY-MM-DD');
    const filteredBatches = this.props.batches!.filter((b) => b.dateFrom >= firstValidMonth);

    return (
      <Form noValidate>
        <dl>
          <dt>{translate('LastMaintenance')}</dt>
          <dd>
            <FormattedDate date={this.props.module.lastMaintenance} />
          </dd>
          <dt>{translate('Batch')}</dt>
          <dd>
            {this.state.inEditMode ? (
              <TextField
                value={props.values.batch}
                fullWidth={true}
                select={true}
                onChange={props.handleChange}
                id="batch"
                name="batch"
              >
                {filteredBatches.map((batch: IModuleBatch) => (
                  <MenuItem key={batch.id} value={batch.id}>
                    {batch.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              this.props.module.batch?.name
            )}
          </dd>
          <dt>{translate('Location')}</dt>
          <dd>
            {this.state.inEditMode ? (
              <TextField
                value={props.values.place}
                fullWidth={true}
                select={true}
                onChange={props.handleChange}
                id="place"
                name="place"
              >
                {this.props.places.map((place: IModulePlace) => (
                  <MenuItem key={place.id} value={place.id}>
                    {place.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              this.props.module.place.name
            )}
          </dd>
          <dt>{translate('LastErrorMessage')}</dt>
          <dd>
            <FormattedDateTime dateTime={this.props.module.lastErrorMessage} />
          </dd>
        </dl>
        <div style={{ textAlign: 'right', marginTop: 10 }}>
          {this.state.inEditMode && (
            <Button variant="contained" color="primary" type="submit">
              {translate('SaveChanges')}
            </Button>
          )}
        </div>
      </Form>
    );
  };

  private onClickEditButton = () => {
    this.setState({
      inEditMode: !this.state.inEditMode,
    });
  };

  private onSubmit = (module: IModuleUpdate) => {
    this.setState({
      inEditMode: false,
    });
    this.props.updateModule(module);
  };
}
